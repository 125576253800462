<template>
    <div class="bottom">
        <div class="button-list">
            <div class="button-item" :class="{active: index === active}" v-for="(item,index) in linkList" :key="item.value" @click="itemclick(index)">
                {{ item.name }}
            </div>
        </div>
    </div>
</template>

<script>
import router from '@/router';
export default {
    props: {
        active: {
            type: Number,
            default:0
        },
        linkList: {
            type: Array,
            default: [
                {name: '报修数据', url: '0'},
                {name: '综合数据', url:'0'},
            ]
        },
    },
    data() {
        return {

        }
    },
    mounted() {
        var projectId=this.$route.query.projectId;
        for(var i=0;i<this.linkList.length;i++){
            if(parseInt(projectId)==parseInt(this.linkList[i].url)){
                this.active=i;
            }
        }
    },	    
    methods: {      
        itemclick(index) {
            this.$router.push({path:'/view',query:{projectId:this.linkList[index].url}});
            window.location.reload();
        }
    }
}
</script>

<style scoped lang="scss">
.bottom {
	position: absolute;
	bottom: 0;
	height: 60px;
	width: 100%;
	left: 0;
	z-index: 1;
	background: url(@/assets/template_1/bottom-bg.png) no-repeat;
	background-size: 100%;
    z-index: 402;
	pointer-events: auto;
}
.button-list {
    width: 100%;
    padding-top: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    .button-item {
        width: 126px;
        height: 38px;
        text-align: center;
        font-family: 'pmzd';
        font-weight: 400;
        font-size: 19px;
        color: #FEFEFE;
        line-height: 34px;
        cursor: pointer;
        pointer-events: auto;
        &.active {
            background: url(@/assets/template_1/btn-active.png) no-repeat;
            background-size: 100% 100%;
        }
    }
}
</style>
