<template>
	<div style="width: 100%;">
		<img :src="backImg" style="width: 100%;">
	</div>
</template>

<script>
export default {
  props: {
    backImg: String
  }
}
</script>

<style>
</style>