<template>
    <div class="head2" style="pointer-events: none;">
        <div class="search-list">
            <el-select @change="SelectData('year')" v-model="years" placeholder="选择年" class="month-select sb-select" v-if="searchConfig.year.isShow">
                <el-option
                    v-for="item in yearList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
            </el-select>
            <el-select @change="SelectData('month')" v-model="months" placeholder="选择月" class="month-select sb-select" v-if="searchConfig.month.isShow">
                <el-option
                    v-for="item in monthList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
            </el-select>
            <el-select @change="SelectData('day')" v-model="days" placeholder="选择日" class="day-select sb-select" v-if="searchConfig.day.isShow">
                <el-option
                    v-for="item in dayList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
            </el-select>
            <div v-if="searchConfig.today.isShow" :class="searchConfig.selectedDate=='today'?'select-item selectitem active':'select-item selectitem'">
                今日
            </div>  
            <div v-if="searchConfig.thisMonth.isShow" :class="searchConfig.selectedDate=='thisMonth'?'select-item selectitem active':'select-item selectitem'">
                本月
            </div> 
            <div v-if="searchConfig.thisYear.isShow" :class="searchConfig.selectedDate=='thisYear'?'select-item selectitem active':'select-item selectitem'">
                本年
            </div>
            <div v-if="searchConfig.thirtyDays.isShow" :class="searchConfig.selectedDate=='thirtyDays'?'select-item selectitem active':'select-item selectitem'">
                30天
            </div> 
            <div v-if="searchConfig.sixtyDays.isShow" :class="searchConfig.selectedDate=='sixtyDays'?'select-item selectitem active':'select-item selectitem'">
                60天
            </div> 
            <div v-if="searchConfig.ninetyDays.isShow" :class="searchConfig.selectedDate=='ninetyDays'?'select-item selectitem active':'select-item selectitem'">
                90天
            </div>                            
            <div @click="SelectDataMore" class="more" v-if="searchConfig.titleMore.isShow">查看更多 ></div>                      
        </div>
    </div>
</template>


<script>
    import { Message } from 'element-ui';
	export default {
    props: {  
        searchConfig:{
            type:Object,
            default:{},
        },  
        effectedComponent:{
            type:String,
            default:"",
        },            
    },	  	
	data() {
		return {
			CmyChart1: "",
			Coption1: "",
			content: "本月全部单位总得分",
            yearList: [
                { label: '2024年', value: 2024 },
                { label: '2023年', value: 2023 },
                { label: '2022年', value: 2022 },
                { label: '2021年', value: 2021 },
            ],
            monthList: [
                { label: '一月份', value: 1 },
                { label: '二月', value: 2 },
                { label: '三月', value: 3 },
                { label: '四月', value: 4 },
                { label: '五月', value: 5 },
                { label: '六月', value: 6 },
                { label: '七月', value: 7 },
                { label: '八月', value: 8 },
                { label: '九月', value: 9 },
                { label: '十月', value: 10 },
                { label: '十一月', value: 11 },
                { label: '十二月', value: 12 },
            ],
            dayList: [
                { label: '1日', value: 1 },
                { label: '2日', value: 2 },
                { label: '3日', value: 3 },
                { label: '4日', value: 4 },
                { label: '31日', value: 31 },
            ],
            years: "",
            months: "",
            days: "",
		}
	},
	methods: {
        getDaysInCurrentMonth(year,month) {
            month=month+1;
            if(month==13){
                month=1;
                year=year+1;
            }
            const now = new Date(year+"-"+month+"-11");
            // 将日设置为0，得到上个月的最后一天
            now.setDate(0);
            // 获取上个月的最后一天的日期，即当前月份的天数
            var day_count=now.getDate();
            this.dayList=[];
            for(var i=1;i<day_count+1;i++){
                this.dayList.push({label:i+"日",value:i});
            }
            this.$forceUpdate();
            this.days="";
            //return now.getDate();
        },        
        SelectDataMore(){
            var selectedData=[this.effectedComponent,'more',true,this.years,this.months,this.days];
            this.$emit('SearchDatas', selectedData)
        },
        SelectData(type){
            if(type=="day"){
                if(!this.years){
                    Message.warning("请先选择年份!");
                    return;
                }
                if(!this.months){
                    Message.warning("请先选择月份!");
                    return;
                }
            }else if(type=="month"){
                if(!this.years){
                    Message.warning("请先选择年份!");
                    return;
                }
                this.getDaysInCurrentMonth(this.years,this.months);
            }else if(type=="year"){
                if(this.months){
                   this.getDaysInCurrentMonth(this.years,this.months);
                } 
            }
            var selectedData=[this.effectedComponent,type,false,this.years,this.months,this.days];
            this.$emit('SearchDatas', selectedData)
        },
	},
	mounted() {
        //console.log("searchConfig===="+JSON.stringify(this.searchConfig));
	},
	}
</script>

<style scoped lang="scss">
.head2 {
    width: 100%;
    height: 46px;
    position: relative;
    display: flex;
    align-items: center;
}
.head2 .title-img {
    width: 100%;
    height: 46px;
    left: 0;
    top: 3px;
    position: absolute;
}
.head2 .layerName {
    z-index: 1;
    font-family: 'pmzd';
    font-weight: 400;
    font-size: 18px;
    color: #FFFFFF;
    line-height: 15px;
    text-shadow: 0px 0px 10px rgba(41, 81, 202, 0.8), 0px 0px 10px rgba(41, 81, 202, 0.8), 0px 0px 10px rgba(41, 81, 202, 0.8);
}
.head2 .layerEng {
    margin-left: 12px;
    z-index: 1;
    font-family: Source Han Sans CN;
    font-weight: 400;
    font-size: 12px;
    color: #8798AC;
    line-height: 15px;
}
.head2 .more {
    z-index: 1;
    font-family: Source Han Sans CN;
    font-weight: 400;
    font-size: 14px;
    color: #51A0F4;
    line-height: 15px;
    cursor: pointer;
    pointer-events: auto;
    float:right;
    display:inline-block;
}
.head2 .search-list {
    margin: 0 16px 0 auto;
    display: flex;
    align-items: center;
    z-index: 1;
}
.head2 .selectitem {
    width: 70px;
    height: 24px;
    background: url(@/assets/template_1/select-bg1.png) no-repeat;
    background-size: 100% 100%;
    text-align: center;
    font-family: Source Han Sans CN;
    font-weight: 400;
    font-size: 12px;
    color: #FFFFFF;
    line-height: 24px;
    cursor: pointer;
    pointer-events: auto;
    &.active {
        background: url(@/assets/template_1/select-bg.png) no-repeat;
        background-size: 100% 100%;
    }
    &.select-item {
        width: 59px;
        margin-left: 3px;
    }
}
</style>