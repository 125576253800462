<template>
    <div id="r1_2" class="r1">
        <div class="l2-content">
            <div class="list-head">
                <div v-for="item in columnList" class="text">{{item.name}}</div>
            </div>
            <div class="list-content">
                <div v-if="i<5" class="list-item" v-for="(item, i) in dataList" :key="i">
                    <div :title="item.value0" class="text">{{ item.value0 }}</div>
                    <div :title="item.value1" v-if="columnList.length>1" class="text">{{ checkWordLen(item.value1) }}</div>
                    <div :title="item.value2" v-if="columnList.length>2" class="text">{{ checkWordLen(item.value2) }}</div>
                    <div :title="item.value3" v-if="columnList.length>3" class="text">{{ checkWordLen(item.value3) }}</div>
                    <div :title="item.value4" v-if="columnList.length>4" class="text">{{ checkWordLen(item.value4) }}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import rank1 from '@/assets/template_1/rank1.png'
import rank2 from '@/assets/template_1/rank2.png'
import rank3 from '@/assets/template_1/rank3.png'

export default {
    props: {
        dataList: {
            type: Array,
            default() {
				return [];
			},
        },
        names: {
            type: String,
            default() {
				return "";
			},
        },        
    },     
    data() {
        return {
            rank1,
            rank2,
            rank3,
            rankMap: {
                0: rank1,
                1: rank2,
                2: rank3,
            },
            defaultValue: [
                { value0: 'GR123', value1: '009', value2: '000' },
                { value0: 'HG234', value1: '008', value2: '000' },
                { value0: 'GR123', value1: '009', value2: '000' },
                { value0: 'HG234', value1: '008', value2: '000' },
                { value0: 'GR123', value1: '009', value2: '000' },
            ],
            defaultValue2: [
                { name:"机器编号" },
                { name:"机器型号" },
                { name:"用量数值" },
            ],
            columnList:[],
            totalWidth:411,//总宽度
            singleWidth:137,//单个宽度
        }
    },
    watch: {
        dataList(newValue, oldValue) {
            if(newValue.length==0&&!this.names){
                this.dataList=this.defaultValue; 
            }     
            if(this.dataList!=this.defaultValue){
  
            }
        },
        names(newValue, oldValue) {
            if(!newValue){
                this.columnList=this.defaultValue2; 
            }     
            if(newValue!=oldValue){
                //alert(this.names);
                this.getColumnList(newValue);
            }
        }        
    }, 
    mounted() {        
        if(this.dataList.length==0&&!this.names){
            this.dataList=this.defaultValue;
        } 
        if(!this.names){
            this.columnList=this.defaultValue2;
        }      
        else{
            this.getColumnList(this.names);
        } 
    },  
    methods: {
        checkWordLen(str){
            if(!str){
                return str;
            }
            var len=8;
            if(str.length>len){
                //str=str.substr(0,len)+"...";
            }
            return str;
        },
        getColumnList(values){
            var tempList=values.split(',');
            if(tempList.length>0){
                this.columnList=[];
            }
            for(var i=0;i<tempList.length;i++){
                if(tempList[i]){
                    this.columnList.push({name:tempList[i]});
                }
            }
            this.singleWidth=this.totalWidth/this.columnList.length;
        },
    },  
}
</script>

<style scoped lang="scss">
.r1 {
    padding-top:5px;
    margin-top:-2px; 
    min-height:257px;
    .text {
        font-family: Source Han Sans CN;
        font-weight: 400;
        font-size: 14px;
        line-height: 15px;
        width: 137px;
        text-align: center;
        img {
            width: 23px;
            height: 19px;
        }
    }
    .list-head {
        display: flex;
        align-items: center;
        margin: 16px 0;
        .text {
            color: #51A0F4;
        }
    }
    .list-item {
        display: flex;
        align-items: center;
        padding: 12px 0;
        background: rgba(59, 120, 177, .05);
        .text {
            color: #C6D2D8;
        }
        &:nth-child(2n + 1) {
            background: rgba(59, 120, 177, .16);
        }
    }
    .list-icon {
        width: 32px;
        height: 13px;
        position: relative;
        img {
            width: 17px;
            height: 13px;
            position: absolute;
            right: 0;
        }
    }
}
.tomore {
    width: 100%;
    text-align: center;
    margin: 16px 0;
    font-family: Source Han Sans CN;
    font-weight: 400;
    font-size: 14px;
    color: #51A0F4;
    line-height: 15px;
    cursor: pointer;
}
.list-content {
    pointer-events: auto;
    margin-bottom: 18px;
}
</style>
<style>
#r1_2 .list-head .text:nth-child(1),#r1_2 .list-item .text:nth-child(1){
    width:120px !important;
    text-align:center !important;
}
#r1_2 .list-head .text:nth-child(2),#r1_2 .list-item .text:nth-child(2){
    width:120px !important;
    text-align:center !important;
}
#r1_2 .list-head .text:nth-child(3),#r1_2 .list-item .text:nth-child(3){
    width:230px !important;
    text-align:center !important;
}
#r1_2 .list-head .text:nth-child(4),#r1_2 .list-item .text:nth-child(4){
    width:100px !important;
    text-align:center !important;
}
</style>