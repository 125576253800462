<template>
    <div class="l1">
        <div ref="lchart" class="lchart"></div>
    </div>
</template>

<script>
import * as echarts from 'echarts'

export default {
    props: {
        dataList: {
            type: Array,
            default() {
				return defaultValue;
			},
        },
        names: {
            type: String,
            default() {
				return "意向客户,成交客户,回访客户";
			},
        },
        yAxisNames: {
            type: String,
            default() {
				return "单位：人";
			},
        }, 
        config: {
            type: Object,
            default: () => {}
        }
    },
    watch: {
        dataList(newValue, oldValue) {
            if(newValue.length==0){
                if(this.isViewPage==false){
                    this.dataList=this.defaultValue; 
                } 
            }     
            if(this.dataList!=this.defaultValue){
                if(!this.names){
                    this.getYColumnList(this.names);
                }
                var nameList=[];
                var valueList1=[];
                var valueList2=[];
                var valueList3=[];
                for(var i=0;i<this.dataList.length;i++){
                    nameList.push(this.dataList[i].name);
                    valueList1.push(this.dataList[i].value);
                    valueList2.push(this.dataList[i].value2);
                    valueList3.push(this.dataList[i].value3);
                }   
                this.options= {
                    color: ['#4E82EA', '#37E5FF', '#a57a2b'],
                    tooltip: {
                        show: true
                    },
                    legend: {
                        show: true,
                        icon: 'rect',
                        itemWidth: 9,
                        itemHeight: 9,
                        right: 40,
                        top: 20,
                        textStyle: {
                            fontSize: 12,
                            color: '#C8CBD2',
                        },
                    },
                    grid: {
                        left: '20',
                        right: '55',
                        bottom: '15',
                        top: '65',
                        containLabel: true
                    },
                    xAxis: {
                        type: 'category',
                        boundaryGap: true,
                        data: nameList,
                        axisLabel: {
                            interval: 0,
                            margin: 18,
                            rotate: 0,
                            textStyle: {
                                color: '#babdc3'
                            },
                            fontSize: 14
                        },
                        axisLine: {
                            lineStyle: {
                                color: '#4792e9'
                            }
                        },
                        axisTick: {
                            show: false
                        },
                        splitLine: {
                            show: false
                        }
                    },
                    yAxis: {
                        type: 'value',
                        name: this.yAxisNames,
                        nameTextStyle: {
                            fontSize: 14,
                            color: "#aaa",
                            nameLocation: "start",
                            padding: [0, 15,13, 0]
                        },
                        splitNumber: 4,
                        axisLabel: {
                            textStyle: {
                                color: '#babdc3' // 这里将 x 轴文本颜色设为红色
                            },
                            fontSize: 14
                        },
                        axisLine: {
                            show: false
                        },
                        splitLine: {
                            show: true,
                            lineStyle: {
                                type: 'dashed',
                                color: "rgba(231,241,251,.2)"
                            }
                        }
                    },
                    series: [
                        {
                            name: this.yColumnList.length>0?this.yColumnList[0]:'月保修次数',
                            type: 'bar',
                            itemStyle: {
                                normal: {
                                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                                        offset: 0,
                                        color: "#4E82EA" // 0% 处的颜色
                                    },{
                                        offset: 1,
                                        color: "rgba(43,138,220,.4)" // 100% 处的颜色
                                    }], false)
                                }
                            },
                            data: valueList1,
                            barWidth: '16',
                            barGap: '5%', //柱子之间间距
                            barCategoryGap: '60%',
                            showBackground: false,
                        },
                        {
                            name:  this.yColumnList.length>1?this.yColumnList[1]:'同比月保修次数',
                            type: 'bar',
                            itemStyle: {
                                normal: {
                                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                                        offset: 0,
                                        color: "#37E5FF" // 0% 处的颜色
                                    },{
                                        offset: 1,
                                        color: "rgba(43,138,220,.4)" // 100% 处的颜色
                                    }], false)
                                }
                            },
                            data: valueList2,
                            barWidth: '16',
                            barGap: '5%', //柱子之间间距
                            barCategoryGap: '60%',
                            showBackground: false,
                        }
                    ]
                }      
                this.CdrawLine1();
            }
            console.log("this.dataList==="+JSON.stringify(this.dataList));
            // if(newValue!=oldValue){
            //      this.drawPie();
            // }
        },
    },    
    data() {
        return {
            defaultValue:[
                { value: 80,value2:20,value3:60, name: '1月' },
                { value: 70,value2:50,value3:10, name: '2月' },
                { value: 20,value2:80,value3:40, name: '3月' },
                { value: 30,value2:50,value3:60, name: '4月' },
                { value: 70,value2:20,value3:80, name: '5月' },
            ],
            defaultValue2:"单位：人",
            defaultValue3:"意向客户,成交客户,回访客户",
            yColumnList:['意向客户','成交客户','回访客户'],
            chart1: null,
            options: {
                color: ['#4E82EA', '#37E5FF', '#a57a2b'],
                tooltip: {
                    show: true
                },
                legend: {
                    show: true,
                    icon: 'rect',
                    itemWidth: 9,
                    itemHeight: 9,
                    right: 40,
                    top: 20,
                    textStyle: {
                        fontSize: 12,
                        color: '#C8CBD2',
                    },
                },
                grid: {
                    left: '10',
                    right: '55',
                    bottom: '15',
                    top: '65',
                    containLabel: true
                },
                xAxis: {
                    type: 'category',
                    boundaryGap: true,
                    data: ['1月', '2月', '3月', '4月', '5月', '6月'],
                    axisLabel: {
                        interval: 0,
                        margin: 18,
                        rotate: 0,
                        textStyle: {
                            color: '#babdc3'
                        },
                        fontSize: 14
                    },
                    axisLine: {
                        lineStyle: {
                            color: '#4792e9'
                        }
                    },
                    axisTick: {
                        show: false
                    },
                    splitLine: {
                        show: false
                    }
                },
                yAxis: {
                    type: 'value',
                    name: '单位：次',
                    nameTextStyle: {
                        fontSize: 14,
                        color: "#aaa",
                        nameLocation: "start",
                        padding: [0, 15,13, 0]
                    },
                    splitNumber: 4,
                    axisLabel: {
                        textStyle: {
                            color: '#babdc3' // 这里将 x 轴文本颜色设为红色
                        },
                        fontSize: 14
                    },
                    axisLine: {
                        show: false
                    },
                    splitLine: {
                        show: true,
                        lineStyle: {
                            type: 'dashed',
                            color: "rgba(231,241,251,.2)"
                        }
                    }
                },
                series: [
                    {
                        name: '今年',
                        type: 'bar',
                        itemStyle: {
                            normal: {
                                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                                    offset: 0,
                                    color: "#4E82EA" // 0% 处的颜色
                                },{
                                    offset: 1,
                                    color: "rgba(43,138,220,.4)" // 100% 处的颜色
                                }], false)
                            }
                        },
                        data: [4, 9, 12, 10, 7.5, 11],
                        barWidth: '16',
                        barGap: '5%', //柱子之间间距
                        barCategoryGap: '60%',
                        showBackground: false,
                    },
                    {
                        name: '去年同期',
                        type: 'bar',
                        itemStyle: {
                            normal: {
                                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                                    offset: 0,
                                    color: "#37E5FF" // 0% 处的颜色
                                },{
                                    offset: 1,
                                    color: "rgba(43,138,220,.4)" // 100% 处的颜色
                                }], false)
                            }
                        },
                        data: [16, 10, 8, 11, 16, 8],
                        barWidth: '16',
                        barGap: '5%', //柱子之间间距
                        barCategoryGap: '60%',
                        showBackground: false,
                    }
                ]
            },
            isViewPage:false,//是否浏览页面
        }
    },
    mounted() {
        this.checkIsViewPage();
        if(this.dataList.length==0){
            if(this.isViewPage==false){
                this.dataList=this.defaultValue; 
            }
        }
        if(!this.names){
            this.names=this.defaultValue3;
            this.getYColumnList(this.names);
        }else{
            this.getYColumnList(this.names);
        }
        var nameList=[];
        var valueList1=[];
        var valueList2=[];
        var valueList3=[];
        for(var i=0;i<this.dataList.length;i++){
            nameList.push(this.dataList[i].name);
            valueList1.push(this.dataList[i].value);
            valueList2.push(this.dataList[i].value2);
            valueList3.push(this.dataList[i].value3);
        }   
        this.options= {
            color: ['#4E82EA', '#37E5FF','a57a2b'],
            tooltip: {
                show: true
            },
            legend: {
                show: true,
                icon: 'rect',
                itemWidth: 9,
                itemHeight: 9,
                right: 40,
                top: 20,
                textStyle: {
                    fontSize: 12,
                    color: '#C8CBD2',
                },
            },
            grid: {
                left: '20',
                right: '55',
                bottom: '15',
                top: '65',
                containLabel: true
            },
            xAxis: {
                type: 'category',
                boundaryGap: true,
                data: nameList,
                axisLabel: {
                    interval: 0,
                    margin: 18,
                    rotate: 0,
                    textStyle: {
                        color: '#babdc3'
                    },
                    fontSize: 14
                },
                axisLine: {
                    lineStyle: {
                        color: '#4792e9'
                    }
                },
                axisTick: {
                    show: false
                },
                splitLine: {
                    show: false
                }
            },
            yAxis: {
                type: 'value',
                name: this.yAxisNames,
                nameTextStyle: {
                    fontSize: 14,
                    color: "#aaa",
                    nameLocation: "start",
                    padding: [0, 15,13, 0]
                },
                splitNumber: 4,
                axisLabel: {
                    textStyle: {
                        color: '#babdc3' // 这里将 x 轴文本颜色设为红色
                    },
                    fontSize: 14
                },
                axisLine: {
                    show: false
                },
                splitLine: {
                    show: true,
                    lineStyle: {
                        type: 'dashed',
                        color: "rgba(231,241,251,.2)"
                    }
                }
            },
            series: [
                {
                    name: this.yColumnList[0],
                    type: 'bar',
                    itemStyle: {
                        normal: {
                            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                                offset: 0,
                                color: "#4E82EA" // 0% 处的颜色
                            },{
                                offset: 1,
                                color: "rgba(43,138,220,.4)" // 100% 处的颜色
                            }], false)
                        }
                    },
                    data: valueList1,
                    barWidth: '16',
                    barGap: '5%', //柱子之间间距
                    barCategoryGap: '60%',
                    showBackground: false,
                },
                {
                    name:this.yColumnList[1],
                    type: 'bar',
                    itemStyle: {
                        normal: {
                            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                                offset: 0,
                                color: "#37E5FF" // 0% 处的颜色
                            },{
                                offset: 1,
                                color: "rgba(43,138,220,.4)" // 100% 处的颜色
                            }], false)
                        }
                    },
                    data: valueList2,
                    barWidth: '16',
                    barGap: '5%', //柱子之间间距
                    barCategoryGap: '60%',
                    showBackground: false,
                },
                {
                    name:this.yColumnList[2],
                    type: 'bar',
                    itemStyle: {
                        normal: {
                            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                                offset: 0,
                                color: "#a57a2b" // 0% 处的颜色
                            },{
                                offset: 1,
                                color: "rgba(165,122,43,.4)" // 100% 处的颜色
                            }], false)
                        }
                    },
                    data: valueList3,
                    barWidth: '16',
                    barGap: '5%', //柱子之间间距
                    barCategoryGap: '60%',
                    showBackground: false,
                }
            ]
        }       
        this.CdrawLine1()
    },
    methods: {
        checkIsViewPage(){
            const url = window.location.href;
            if(url.indexOf("/view")>=0){
                this.isViewPage=true;
            }
        },         
        getYColumnList(name){
            if(name){
                this.yColumnList=name.split(',');
            }
        },
        CdrawLine1() {
            var echarts = require('echarts');
            var CmyChart1 = echarts.init(this.$refs.lchart)
            if (this.options) {
                var Coption1 = this.options
                Coption1 && CmyChart1.setOption(Coption1);
                // this.chart1 = setInterval(function() {
                //     CmyChart1.clear();
                //     Coption1 && CmyChart1.setOption(Coption1);
                // }, 10000)
            } else {
                const list = this.config.list || []
                var Coption1 = {
                    tooltip: {
                        show: true
                    },
                    legend: {
                        show: false,
                    },
                    grid: {
                        left: '10',
                        right: '55',
                        bottom: '15',
                        top: '65',
                        containLabel: true
                    },
                    xAxis: {
                        type: 'category',
                        boundaryGap: true,
                        data: list.map((item) => {
                            return item.name
                        }),
                        axisLabel: {
                            interval: 0,
                            margin: 18,
                            rotate: 0,
                            textStyle: {
                                color: '#babdc3'
                            },
                            fontSize: 14
                        },
                        axisLine: {
                            lineStyle: {
                                color: '#4792e9'
                            }
                        },
                        axisTick: {
                            show: false
                        },
                        splitLine: {
                            show: false
                        }
                    },
                    yAxis: {
                        type: 'value',
                        name: this.yAxisNames,
                        nameTextStyle: {
                            fontSize: 14,
                            color: "#aaa",
                            nameLocation: "start",
                            padding: [0, 15,13, 0]
                        },
                        splitNumber: 4,
                        axisLabel: {
                            textStyle: {
                                color: '#babdc3' // 这里将 x 轴文本颜色设为红色
                            },
                            fontSize: 14
                        },
                        axisLine: {
                            show: false
                        },
                        splitLine: {
                            show: true,
                            lineStyle: {
                                type: 'dashed',
                                color: "rgba(231,241,251,.2)"
                            }
                        }
                    },
                    series: {
                        name: this.yAxisNames,
                        type: 'bar',
                        itemStyle: {
                            normal: {
                                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                                    offset: 0,
                                    color: "#2b8adc" // 0% 处的颜色
                                },{
                                    offset: 1,
                                    color: "rgba(43,138,220,.4)" // 100% 处的颜色
                                }], false)
                            }
                        },
                        data: list,
                        barWidth: '16',
                        barGap: '5%', //柱子之间间距
                        barCategoryGap: '60%',
                        showBackground: true,
                        backgroundStyle: {
                            color: 'rgba(180, 180, 180, 0.2)'
                        }
                    }
                }
                Coption1 && CmyChart1.setOption(Coption1);
                // this.chart1 = setInterval(function() {
                //     CmyChart1.clear();
                //     Coption1 && CmyChart1.setOption(Coption1);
                // }, 10000)
            }
            
        },
    }
}
</script>

<style scoped lang="scss">
.l1 {
    min-height:230px;
    .head {
        width: 100%;
        height: 46px;
        position: relative;
        display: flex;
        align-items: center;
    }
    .title-img {
        width: 436px;
        height: 46px;
        left: 0;
        top: 3px;
        position: absolute;
    }
    .layerName {
        margin-left: 42px;
        z-index: 1;
        font-family: 'pmzd';
        font-weight: 400;
        font-size: 18px;
        color: #FFFFFF;
        line-height: 15px;
        text-shadow: 0px 0px 10px rgba(41, 81, 202, 0.8), 0px 0px 10px rgba(41, 81, 202, 0.8), 0px 0px 10px rgba(41, 81, 202, 0.8);
    }
    .layerEng {
        margin-left: 12px;
        z-index: 1;
        font-family: Source Han Sans CN;
        font-weight: 400;
        font-size: 12px;
        color: #8798AC;
        line-height: 15px;
    }
    .more {
        margin: 0 24px 0 auto;
        z-index: 1;
        font-family: Source Han Sans CN;
        font-weight: 400;
        font-size: 14px;
        color: #51A0F4;
        line-height: 15px;
        cursor: pointer;
        pointer-events: auto;
    }
    .lchart {
        width: 460px;
        height: 260px;
        margin-left: 12px;
        pointer-events: auto;
    }
}
</style>