import { render, staticRenderFns } from "./textlist_1_1.vue?vue&type=template&id=60ad11b0&scoped=true"
import script from "./textlist_1_1.vue?vue&type=script&lang=js"
export * from "./textlist_1_1.vue?vue&type=script&lang=js"
import style0 from "./textlist_1_1.vue?vue&type=style&index=0&id=60ad11b0&prod&scoped=true&lang=scss"
import style1 from "./textlist_1_1.vue?vue&type=style&index=1&id=60ad11b0&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "60ad11b0",
  null
  
)

export default component.exports