<template>
    <div class="headFrame">
        <div class="header">
            <div class="left">
                <img src="../../assets/template_1/logo.png" alt="" class="head-logo">
                <div class="left-text">
                    云管印（上海）技术有限公司
                </div>
            </div>
            <div class="center">
                {{title}}
            </div>
            <div class="right">
                <div class="time">
                    {{ FormateTime(nowtime) }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            default: '设备数据信息化平台'
        },
    },
    data() {
        return {
            nowtime: ''
        }
    },
    mounted() {
        // 显示时间1s1s的显示
        let that = this;
        this.timer = setInterval(function() {
            that.nowtime = new Date().toLocaleString();
        })
    },
    methods: {
        FormateTime() {
            var date = new Date();
            var year = this.formateTime(date.getFullYear());
            var month = this.formateTime(date.getMonth() + 1);
            var day = this.formateTime(date.getDate());
            var hour = this.formateTime(date.getHours());
            var minute = this.formateTime(date.getMinutes());
            var second = this.formateTime(date.getSeconds());
            var weekday = date.getDay();
            var weeks = new Array(
                "星期日",
                "星期一",
                "星期二",
                "星期三",
                "星期四",
                "星期五",
                "星期六"
            );
            var week = weeks[weekday];
            return (
                year + "-" + month + "-" + day + "  " + week + "  " + hour + ":" + minute + ":" + second
            );
        },
        formateTime(n) {
            if (n < 10) {
                return "0" + n;
            } else {
                return n;
            }
        },
    }
}
</script>

<style scoped lang="scss">
.headFrame {
	width: 100%;
	height: 103px;
    position: relative;
    z-index: 402;
	.header {
		width: 100%;
		height: calc(100% - 25px);
		background: url(../../assets/template_1/header.png) no-repeat;
		background-size: 100% 100%;
		display: flex;
		align-items: center;
		padding-bottom: 25px;
	}
	.left {
		width: 478px;
		display: flex;
		align-items: center;
	}
	.center {
		width: 964px;
		font-family: 'pmzd';
		font-weight: 400;
		font-size: 50px;
		color: #FFFFFF;
		line-height: 37px;
		font-style: italic;
		background: linear-gradient(0deg, #B9DFFC 0%, #FEFFFF 99.5849609375%);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.right {
		width: 478px;
		display: flex;
		justify-content: flex-end;
		.time {
			margin-right: 47px;
			font-family: Source Han Sans CN;
			font-weight: 400;
			font-size: 20px;
			color: #EBF5FC;
			line-height: 15px;
			font-style: italic;
			text-shadow: 0px 0px 10px rgba(41, 81, 202, 0.8), 0px 0px 10px rgba(41, 81, 202, 0.8), 0px 0px 10px rgba(41, 81, 202, 0.8);
		}
	}
	.head-logo {
		width: 76px;
		height: 55.5px;
		margin-left: 40px;
	}
	.left-text {
		margin-left: 10px;
		font-family: Source Han Sans CN;
		font-weight: 400;
		font-size: 20px;
		color: #E8F5FD;
		line-height: 20px;
		font-style: italic;
		text-shadow: 0px 0px 10px rgba(41, 81, 202, 0.8), 0px 0px 10px rgba(41, 81, 202, 0.8), 0px 0px 10px rgba(41, 81, 202, 0.8);
	}
}
</style>