<template>
<div style="display:block;with:100%;position:relative;height:257px;overflow:hidden;">
    <div class="re-c2">
        <div class="c2-content">
            <div class="list-head">
                <div class="list-icon"></div>
                <div v-if="" :style="index==2?'width:220px;':''" v-for="(item,index) in columnList" class="text">{{item.name}}</div>
            </div>
            <div class="text-area" ref="content" @mouseenter="mouseenterEvent()" @mouseleave="mouseleaveEvent()" >
                <div class="list-content" ref="scroll1">
                    <div class="list-item" v-for="(item, i) in dataList" :key="i">
                        <div class="list-icon">
                            <img src="@/assets/template_1/list-icon.png" alt="">
                        </div>
                        <div class="text">{{ item.value0 }}</div>
                        <div class="text">{{ item.value1 }}</div>
                        <div style="width:220px;" class="text">{{ item.value2 }}</div>
                        <div class="text">{{ item.value3 }}</div>
                        <div class="text">{{ item.value4 }}</div>
                        <div v-if="columnList.length>5" class="text">{{ item.value5 }}</div>
                        <div v-if="columnList.length>6" class="text">{{ item.value6 }}</div>
                        <div v-if="columnList.length>7" class="text">{{ item.value7 }}</div>
                        <div v-if="columnList.length>8" class="text">{{ item.value8 }}</div>
                        <div v-if="columnList.length>9" class="text">{{ item.value9 }}</div>
                        <!-- <div class="`text state1">
                            <div class="point"></div>
                            {{ item.value0 }}
                        </div> -->
                    </div>
                </div>
                <div class="list-content" ref="scroll2"></div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    props: {
        dataList: {
            type: Array,
            default() {
				return defaultValue;
			},
        },
        names: {
            type: String,
            default() {
				return "";
			},
        },
    },    
    data() {
        return {
            title: '报修事件列表',
            defaultValue: [
                { value0: 'GR123', value1: '009', value2: '000',value3: '1小时15分钟' },
                { value0: 'HG234', value1: '008', value2: '000', value3: '2小时15分钟' },
                { value0: 'GR123', value1: '009', value2: '000', value3: '3小时15分钟' },
                { value0: 'HG234', value1: '008', value2: '000', value3: '1小时15分钟' },
                { value0: 'GR123', value1: '009', value2: '000',value3: '2小时15分钟' },
                { value0: 'HG234', value1: '008', value2: '000',  value3: '3小时15分钟' },
                { value0: 'GR123', value1: '009', value2: '000',  value3: '1小时15分钟' },
                { value0: 'HG234', value1: '008', value2: '000', value3: '2小时15分钟' },
            ],
            defaultValue2: [
                { name:"机器编号" },
                { name:"使用部门" },
                { name:"报修事件" },
                { name:"报修时间" },
                { name:"维修用时" },
            ],
            columnList:[],
            timer: null,
            totalWidth:860,//总宽度
            singleWidth:172,//单个宽度
        }
    },
    watch: {
        dataList(newValue, oldValue) {
            if(newValue.length==0&&!this.names){
                this.dataList=this.defaultValue; 
            }     
            if(this.dataList!=this.defaultValue){
                this.rollUp(150)
            }
        },
        names(newValue, oldValue) {
            if(!newValue){
                this.columnList=this.defaultValue2; 
            }     
            if(newValue!=oldValue){
                //alert(this.names);
                this.getColumnList(newValue);
            }
        }
    },    
    mounted() {      
        if(this.dataList.length==0&&!this.names){
            this.dataList=this.defaultValue;
        }     
        if(!this.names){
            this.columnList=this.defaultValue2;
        }      
        else{
            this.getColumnList(this.names);
        } 
        this.$nextTick(() => {
            this.rollUp(150)
        })
    },
    methods: {
        getColumnList(values){
            var tempList=values.split(',');
            if(tempList.length>0){
                this.columnList=[];
            }
            for(var i=0;i<tempList.length;i++){
                if(tempList[i]){
                    this.columnList.push({name:tempList[i]});
                }
            }
            this.singleWidth=this.totalWidth/this.columnList.length;
        },
        rollUp(t) {
            const ulf1 = this.$refs.scroll1
            const ulf2 = this.$refs.scroll2
            const box = this.$refs.content
            if (ulf1.offsetHeight > 234) {
                ulf2.innerHTML = ulf1.innerHTML
                box.scrollTop = 0
                this.timer = window.setInterval(this.rollStart, t)
            }
        },
        rollStart() {
            const ulf1 = this.$refs.scroll1
            const box = this.$refs.content
            if (box.scrollTop >= ulf1.scrollHeight) {
                box.scrollTop = 0;
            } else {
                box.scrollTop+= 2;
            }
        },
        mouseenterEvent() {
            if (this.timer) {
                clearInterval(this.timer);
                this.timer = null;
            }
        },
        mouseleaveEvent(t) {
            if (!this.timer) {
                this.timer = setInterval(this.rollStart, 150);
            }
        },
    },
    beforeDestroy() {
        clearInterval(this.timer)
        this.timer = null
    }
}
</script>

<style scoped lang="scss">
.re-c2 {
    width: 100%;
    margin: 0 0;
    margin-bottom:0px;
    position: absolute;
    bottom: 0px;
    height: 257px;
    min-height:257px;
    overflow: hidden;
    z-index:-1;
    .head {
        width: 100%;
        height: 46px;
        position: relative;
        display: flex;
        align-items: center;
    }
    .title-img {
        width: 100%;
        height: 46px;
        left: 0;
        top: 3px;
        position: absolute;
    }
    .layerName {
        margin-left: 42px;
        z-index: 1;
        font-family: 'pmzd';
        font-weight: 400;
        font-size: 18px;
        color: #FFFFFF;
        line-height: 15px;
        text-shadow: 0px 0px 10px rgba(41, 81, 202, 0.8), 0px 0px 10px rgba(41, 81, 202, 0.8), 0px 0px 10px rgba(41, 81, 202, 0.8);
    }
    .layerEng {
        margin-left: 12px;
        z-index: 1;
        font-family: Source Han Sans CN;
        font-weight: 400;
        font-size: 12px;
        color: #8798AC;
        line-height: 15px;
    }
    .more {
        margin: 0 24px 0 auto;
        z-index: 1;
        font-family: Source Han Sans CN;
        font-weight: 400;
        font-size: 14px;
        color: #51A0F4;
        line-height: 15px;
        cursor: pointer;
        pointer-events: auto;
    }
    .text {
        font-family: Source Han Sans CN;
        font-weight: 400;
        font-size: 14px;
        line-height: 15px;
        width: 122px;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
            width: 23px;
            height: 19px;
        }
        &:last-child {
            justify-content: flex-start;
            width: 140px;
            padding-left: 32px;
        }
    }
    .list-head {
        display: flex;
        align-items: center;
        margin: 16px 0;
        .text {
            color: #51A0F4;
        }
    }
    .list-item {
        display: flex;
        align-items: center;
        padding: 12px 0;
        background: rgba(59, 120, 177, .05);
        .text {
            color: #C6D2D8;
            &.state1 {
                color: #00FD4A;
                .point {
                    background: #00FD4A;
                }
            }
            &.state2 {
                color: #00CBFC;
                .point {
                    background: #00CBFC;
                }
            }
            &.state3 {
                color: #FFE54A;
                .point {
                    background: #FFE54A;
                }
            }
            &.state4 {
                color: #FF6C65;
                .point {
                    background: #FF6C65;
                }
            }
        }
        &:nth-child(2n + 1) {
            background: rgba(59, 120, 177, .16);
        }
    }
    .point {
        width: 5px;
        height: 5px;
        border-radius: 50%;
        margin-right: 7px;
    }
    .list-icon {
        width: 32px;
        height: 13px;
        position: relative;
        img {
            width: 17px;
            height: 13px;
            position: absolute;
            right: 0;
        }
    }
    .text-area {
        pointer-events: auto;
        max-height: 234px;
        overflow: hidden;
    }
}
</style>