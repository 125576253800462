import request from '@/utils/request'

//用户登录
export function userLogin(data) {
  return request({
    url: '/usercenter/v1/user/login',
    method: 'post',
    data
  })
}
//获取模板列表
export function getTemplateListData(data) {
  return request({
    url: '/bi/v1/template/index',
    method: 'post',
    data
  })
}
//获取模板详情
export function getTemplateDetailData(data) {
  return request({
    url: '/bi/v1/template/detail',
    method: 'post',
    data
  })
}
//组件列表
export function getComponentListData(data) {
  return request({
    url: '/bi/v1/component/index',
    method: 'post',
    data
  })
}
//组件详情
export function getComponentDetailData(data) {
  return request({
    url: '/bi/v1/component/detail',
    method: 'post',
    data
  })
}
//添加项目数据
export function addProjectData(data) {
  return request({
    url: '/bi/v1/project/add',
    method: 'post',
    data
  })
}
//项目列表
export function getProjectListData(data) {
  return request({
    url: '/bi/v1/project/index',
    method: 'post',
    data
  })
}
//项目详情
export function getProjectDetailData(data) {
  return request({
    url: '/bi/v1/project/detail',
    method: 'post',
    data
  })
}
//项目删除
export function deleteProjectData(data) {
  return request({
    url: '/bi/v1/project/del',
    method: 'post',
    data
  })
}
//编辑项目数据
export function editProjectData(data) {
  return request({
    url: '/bi/v1/projectConfig/edit',
    method: 'post',
    data
  })
}
//获取组件数据
export function getComponentDatas(data) {
  return request({
    url: '/bi/v1/export',
    method: 'post',
    data
  })
}
//获取接口列表
export function getApiListData(data) {
  return request({
    url: '/bi/v1/dataset/select',
    method: 'post',
    data
  })
}




