<template>
    <div class="img-list">
        <div v-for="item in dataList" class="img-item">
            <img :src="del" alt="" v-for="(del, i) in iconlist" :key="i" :class="`iconimg icon${i}`">
            <div class="rank">
                TOP{{ i+i }}
                <img :src="rankbg" alt="" class="rank-img">
            </div>
            <div class="name">
                {{ item.name }}
            </div>
            <img :src="item.value0" alt="" class="item-img">
        </div>
    </div>
</template>

<script>
import icon1 from '@/assets/template_1/corner1.png'
import icon2 from '@/assets/template_1/corner2.png'
import icon3 from '@/assets/template_1/corner3.png'
import icon4 from '@/assets/template_1/corner4.png'
import demo1 from '@/assets/template_1/demo1.jpg'
import rankbg from '@/assets/template_1/rank-bg.png'

export default {
    props: {
        dataList: {
            type: Array,
            default() {
				return [];
			},
        },
    },    
    components: {
    },
    data() {
        return {
            iconlist: [
                icon1, icon2, icon3, icon4
            ],
            defaultValue:[
                {value0:demo1,name:'张三'},
                {value0:demo1,name:'李四'},
                {value0:demo1,name:'王五'},
            ],
        }
    },
    watch: {
        dataList(newValue, oldValue) {
            if(newValue.length==0){
                this.dataList=this.defaultValue; 
            }         
        }
    },
    mounted() {
        if(this.dataList.length==0){
            this.dataList=this.defaultValue; 
        }
    },	    
}
</script>

<style scoped lang="scss">
.img-list {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 16px;
    margin: 24px 0 36px;
    .img-item {
        width: 125px;
        height: 198px;
        background: #000000;
        position: relative;
        border: 2px solid rgba(43,70,102,0.22);
        .item-img {
            width: 115px;
            height: 150px;
            position: absolute;
            left: 5px;
            top: 5px;
        }
        .iconimg {
            width: 22px;
            height: 22px;
        }
        .icon0 {
            position: absolute;
            left: -7px;
            top: -7px;
            animation: flicker 1s infinite;
            animation-direction: alternate;
        }
        .icon1 {
            position: absolute;
            right: -7px;
            top: -7px;
            animation: flicker 1s infinite;
            animation-direction: alternate;
        }
        .icon2 {
            position: absolute;
            left: -7px;
            bottom: -7px;
            animation: flicker 1s infinite;
            animation-direction: alternate;
        }
        .icon3 {
            position: absolute;
            right: -7px;
            bottom: -7px;
            animation: flicker 1s infinite;
            animation-direction: alternate;
        }
        @keyframes flicker {
            from {
                opacity: 0;
            }
            to {
                opacity: 1;
            }
        }
    }
    .rank {
        width: 43px;
        height: 16px;
        position: absolute;
        z-index: 1;
        font-family: Source Han Sans CN;
        font-weight: 500;
        font-size: 10px;
        color: #E8FBFF;
        line-height: 16px;
        text-align: center;
        font-style: italic;
        left: 41px;
        top: 148px;
        .rank-img { 
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            z-index: -1;
        }
    }
    .name {
        width: 100%;
        font-family: Source Han Sans CN;
        font-weight: 400;
        font-size: 14px;
        color: #C9EFFF;
        line-height: 15px;
        text-align: center;
        position: absolute;
        bottom: 12px;
        z-index: 1;
    }
}
</style>