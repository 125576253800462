<template>
	<div class="c1">
		<div ref="cmain1" :style="{width: '100%', height: height,margin:'0 0 0px 0px'}"></div>
	</div>
</template>

<script>
	import * as echarts from 'echarts'
	export default {
    props: {
        height: {
            type: String,
            default: '240px'
        },
    },		
		data() {
			return {
				CmyChart1: "",
				Coption1: "",
				content: "本月全部单位总得分"
			}
		},
		methods: {
			CdrawLine1() {
				var echarts = require('echarts');
				var CmyChart1 = echarts.init(this.$refs.cmain1);
				var Coption1 = {
					
					tooltip: {
						trigger: 'axis',
						padding: 0,
						backgroundColor: "transparent",
						border: 0,
						formatter: function(params) {
							return '<div style="width:250px;height:200px;background:rgba(5,10,23,.5);border-left:5px solid #0876cd">' +
								'<div style="padding:20px 0 0 20px">' + '5月份全部单位平均得分，90分' + '</div>' +
								'<div style="padding:5px 0 0 20px">' + '平均扣分，10分:' + '</div>' +
								'<div style="padding:10px 0 0 20px">' + '1.平均报账时长，平均扣2分' + '</div>' +
								'<div style="padding:5px 0 0 20px">' + '2.退单率，平均扣2分' + '</div>' +
								'<div style="padding:5px 0 0 20px">' + '3.资金认领准确率，平均扣2分' + '</div>' +
								'<div style="padding:5px 0 0 20px">' + '4.验收合格率，平均扣1分' + '</div>' +
								'</div>'
						}
					},
					legend: {
						show: true,
						x: 'right',
						itemWidth: 13,
						itemHeight: 13,
						padding: [13, 100, 0, 0],
						textStyle: {
							fontSize: 14,
							color: '#ffffff',
						},
						type: 'scroll',
						icon: 'rect'
					},
					grid: {
						left: '0',
						right: '6%',
						bottom: '0',
						containLabel: true
					},
					xAxis: {
						type: 'category',
						boundaryGap: false,
						data: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
						axisLabel: {
							interval: 0,
							textStyle: {
								color: '#bdc2cb' // 这里将 x 轴文本颜色设为红色
							}
						},
						axisLine: {
							lineStyle: { // X 轴颜色配置
								color: '#4792e9'
							}
						},
						axisTick: {
							show: false
						},
						splitLine: {
							show: true,
							lineStyle: {
								type: 'dashed',
								color: "rgba(231,241,251,.2)"
							}
						}
					},
					yAxis: {
						type: 'value',
						name: "百分比",
						nameTextStyle: {
							fontSize: 14,
							color: "#aaa",
							nameLocation: "start",
							padding: [0, 10, 13, 0]
						},
						splitNumber: 4,
						axisLabel: {
							textStyle: {
								color: '#bdc2cb' // 这里将 x 轴文本颜色设为红色
							}
						},
						axisLine: {
							show: false
						},
						splitLine: {
							show: true,
							lineStyle: {
								type: 'dashed',
								color: "rgba(231,241,251,.2)"
							}
						}
					},
					dataZoom: [{
							type: 'slider',
							backgroundColor: "transparent",
							fillerColor:"rgba(0,0,0,.2)",
							borderColor:"transparent",
							start: 0,
							end: 100
						},
						{
							backgroundColor: "transparent",
							fillerColor:"transparent",
							borderColor:"transparent",
							start: 0,
							end: 20
						}
					],
					series: [{
							name: '  总分数',
							type: 'line',
							smooth: true,
							stack: 'Total',
							showSymbol: true, // 是否显示点
							symbol: 'circle', // 点类型
							symbolSize: 8, // 折线点的大小
							color: 'blue', // 折线点的颜色
							
							itemStyle: {
								normal: {
									color: '#2881ff',
									borderColor: '#ffffff', // 拐点边框颜色
									lineStyle: { // 优先级高
										type: 'solid', // 线型 solid-实线 dotted-点型 dashed-虚线
										width: 2, // 线的粗细
										color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
											offset: 0,
											color: "#2881ff" // 0% 处的颜色
										}, {
											offset: 0.5,
											color: "#3a9ffc" // 0% 处的颜色
										}, {
											offset: 1,
											color: "#5ad5f7" // 100% 处的颜色
										}], false), // 折线的颜色
									}
								}
							},
							data: [90, 48, 50, 65, 40, 72, 48, 50, 65, 40, 70, 20],
							// barWidth: '15',
							barGap: '5%', //柱子之间间距
							barCategoryGap: '60%',
							areaStyle: {
								color: {
									type: 'linear',
									x: 0,
									y: 0,
									x2: 0,
									y2: 1,
									colorStops: [ // 渐变颜色
										{
											offset: 0,
											color: '#0693ff',
										},
										{
											offset: 1,
											color: 'rgba(6,147,255,0.00)',
										},
									],
									global: false,
								},
							},
						},

					]
				};

				Coption1 && CmyChart1.setOption(Coption1);
				
				setInterval(function() {
					CmyChart1.clear();
					Coption1 && CmyChart1.setOption(Coption1);
				}, 10000)
			},
		},
		mounted() {
			this.$nextTick(() => {
				this.CdrawLine1(); //需要刷新的数据
			});
		},
	}
</script>

<style scoped>
	.c1{
		animation: c1 2s linear 1;
	}
	@keyframes c1 {
		from{
			opacity: 0;
		}
		to{
			opacity: 1;
		}
	}
</style>