import { render, staticRenderFns } from "./maptips_2.vue?vue&type=template&id=2bd98f1c&scoped=true"
import script from "./maptips_2.vue?vue&type=script&lang=js"
export * from "./maptips_2.vue?vue&type=script&lang=js"
import style0 from "./maptips_2.vue?vue&type=style&index=0&id=2bd98f1c&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2bd98f1c",
  null
  
)

export default component.exports