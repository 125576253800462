<template>
    <div class="r3">
        <div class="name-box" v-if="chartname">
            <div class="icoL"><img src="@/assets/template_1/chart-left.png"></div>
            <div class="icoR"><img src="@/assets/template_1/chart-right.png"></div>
            <div class="yjName">{{ names }}</div>
        </div>
        <div ref="lchart" class="lchart"></div>
    </div>
</template>

<script>
export default {
    props: {      
        dataList: {
            type: Array,
            default() {
				return defaultValue;
			},
        },
        names: {
            type: String,
            default() {
				return defaultValue2;
			},
        },
        chartname: {
            type: String,
            default() {
				return "";
			},
        },
        yAxisNames: {
            type: String,
            default() {
				return "金额(万)";
			},
        },          
    },
    data() {
        return {
            defaultValue:[
                { value: 70, name: '1' },
                { value: 50, name: '5' },
                { value: 10, name: '10' },
                { value: 40, name: '15' },
                { value: 50, name: '20' },
                { value: 30, name: '25' },
                { value: 10, name: '30' },
            ],
            defaultValue2:"金额(万)",
            isViewPage:false,//是否浏览页面
        }
    },
    watch: {
        dataList(newValue, oldValue) {
            if(newValue.length==0){
                if(this.isViewPage==false){
                    this.dataList=this.defaultValue; 
                } 
            }    
            if(this.dataList!=this.defaultValue){
                this.drawLine();
            }
            console.log("this.dataList==="+JSON.stringify(this.dataList));
            // if(newValue!=oldValue){
            //      this.drawPie();
            // }
        },
        // names(newValue, oldValue) {
        //     if(!newValue){
        //         this.names=this.defaultValue2; 
        //     }     
        //     if(newValue!=oldValue){
        //          this.drawPie();
        //     }
        //     //alert(this.names+"2");
        // }
    },
    mounted() {
        if(this.dataList.length==0){
            if(this.isViewPage==false){
                this.dataList=this.defaultValue; 
            }
        }
        if(!this.names){
            this.names=this.defaultValue2;
        }
        this.$nextTick(() => {
            this.drawLine()
        })
    },
    methods: {
        checkIsViewPage(){
            const url = window.location.href;
            if(url.indexOf("/view")>=0){
                this.isViewPage=true;
            }
        },         
        drawLine() {
            var nameList=[];
            var valueList=[];
            for(var i=0;i<this.dataList.length;i++){
                nameList.push(this.dataList[i].name);
                valueList.push(this.dataList[i].value);
            }        
            //alert(JSON.stringify(this.dataList));
            var intervals=parseInt(nameList.length/10);
            var lineOption={
                color: ['#4E82EA', '#37E5FF'],
                tooltip: {
                    trigger: 'item',
                    axisPointer: {
                        type: 'cross'
                    },
                    padding: 16,
                    border: 0
                },
                grid: {
                    right: '5%',
                    left: '10',
                    bottom: '0',
                    containLabel: true
                },
                legend: {
                    show: false,
                },
                xAxis: [{
                    type: 'category',
                    axisTick: {
                        show: false
                    },
                    axisLabel: {
                        interval: intervals,
                        textStyle: {
                            color: '#babdc3' // 这里将 x 轴文本颜色设为红色
                        },
                        fontSize: 12,
                        rotate: 30, // 设置标签倾斜角度，单位为度
                    },
                    axisLine: {
                        lineStyle: { // X 轴颜色配置
                            color: '#4792e9'
                        }
                    },
                    splitLine: {
                        show: true,
                        lineStyle: {
                            type: 'dashed',
                            color: "rgba(231,241,251,.2)"
                        }
                    },
                    data:nameList
                }],
                yAxis: {
                    type: 'value',
                    name: this.yAxisNames,
                    nameTextStyle: {
                        fontSize: 14,
                        color: "#aaa",
                        nameLocation: "start",
                        padding: [0, 15,13, 0]
                    },
                    splitNumber: 4,
                    axisLabel: {
                        textStyle: {
                            color: '#babdc3' // 这里将 x 轴文本颜色设为红色
                        },
                        fontSize: 12,
                    },
                    axisLine: {
                        show: false
                    },
                    splitLine: {
                        show: true,
                        lineStyle: {
                            type: 'dashed',
                            color: "rgba(231,241,251,.2)"
                        }
                    }
                },
                series: {
                    name: this.names,
                    type: 'line',
                    showSymbol: false,
                    smooth: true,
                    data: valueList,
                    itemStyle: {
                        normal: {
                            color: '#37E5FF' // 这里设置为红色
                        }
                    },
                    areaStyle: {
                        color: {
                            type: 'linear',
                            x: 0,
                            y: 0,
                            x2: 0,
                            y2: 1,
                            colorStops: [ // 渐变颜色
                                {
                                    offset: 0,
                                    color: '#37E5FF',
                                },
                                {
                                    offset: 1,
                                    color: 'rgba(35,216,234,0.00)',
                                },
                            ],
                            global: false,
                        },
                    },
                },
//                 dataZoom: [
//                         {
//                             type: 'slider',
//                             backgroundColor: "transparent",
//                             fillerColor:"rgba(0,0,0,.2)",
//                             borderColor:"transparent",
//                             start: 5,
//                             end: 10
//                         }
//                  ],    
            }
            var echarts = require('echarts')
            var mychart = echarts.init(this.$refs.lchart)
            const option = {
                color: ['#4E82EA', '#37E5FF'],
                tooltip: {
                    trigger: 'item',
                    axisPointer: {
                        type: 'cross'
                    },
                    padding: 16,
                    border: 0
                },
                grid: {
                    right: '5%',
                    left: '10',
                    bottom: '0',
                    containLabel: true
                },
                legend: {
                    show: true,
                    icon: 'rect',
                    itemWidth: 9,
                    itemHeight: 9,
                    right: 40,
                    top: 20,
                    textStyle: {
                        fontSize: 12,
                        color: '#C8CBD2',
                    },
                },
                xAxis: [{
                    type: 'category',
                    axisTick: {
                        show: false
                    },
                    axisLabel: {
                        interval: 0,
                        textStyle: {
                            color: '#babdc3', // 这里将 x 轴文本颜色设为红色
                            rotate: 45, // 设置标签倾斜角度，单位为度
                        },
                        fontSize: 14,
                    },
                    axisLine: {
                        lineStyle: { // X 轴颜色配置
                            color: '#4792e9'
                        }
                    },
                    splitLine: {
                        show: true,
                        lineStyle: {
                            type: 'dashed',
                            color: "rgba(231,241,251,.2)"
                        }
                    },
                    data: ['1月', '2月', '3月', '4月', '5月', '6月']
                }],
                yAxis: {
                    type: 'value',
                    name: '单位：次',
                    nameTextStyle: {
                        fontSize: 14,
                        color: "#aaa",
                        nameLocation: "start",
                        padding: [0, 15,13, 0]
                    },
                    splitNumber: 4,
                    axisLabel: {
                        textStyle: {
                            color: '#babdc3' // 这里将 x 轴文本颜色设为红色
                        },
                        fontSize: 14
                    },
                    axisLine: {
                        show: false
                    },
                    splitLine: {
                        show: true,
                        lineStyle: {
                            type: 'dashed',
                            color: "rgba(231,241,251,.2)"
                        }
                    }
                },
                series: [
                    {
                        name: '今年',
                        type: 'line',
                        showSymbol: false,
                        smooth: true,
                        data: [4, 9, 12, 10, 7.5, 11],
                        itemStyle: {
                            normal: {
                                color: '#007EFF' // 这里设置为红色
                            }
                        },
                        areaStyle: {
                            color: {
                                type: 'linear',
                                x: 0,
                                y: 0,
                                x2: 0,
                                y2: 1,
                                colorStops: [ // 渐变颜色
                                    {
                                        offset: 0,
                                        color: '#007EFF',
                                    },
                                    {
                                        offset: 1,
                                        color: 'rgba(35,216,234,0.00)',
                                    },
                                ],
                                global: false,
                            },
                        },
                    },
                    {
                        name: '去年同期',
                        type: 'line',
                        showSymbol: false,
                        smooth: true,
                        data: [16, 10, 8, 11, 16, 8],
                        itemStyle: {
                            normal: {
                                color: '#37E5FF' // 这里设置为红色
                            }
                        },
                        areaStyle: {
                            color: {
                                type: 'linear',
                                x: 0,
                                y: 0,
                                x2: 0,
                                y2: 1,
                                colorStops: [ // 渐变颜色
                                    {
                                        offset: 0,
                                        color: '#37E5FF',
                                    },
                                    {
                                        offset: 1,
                                        color: 'rgba(35,216,234,0.00)',
                                    },
                                ],
                                global: false,
                            },
                        },
                    }
                ],
                dataZoom: [
                    {
                            type: 'inside',
                            backgroundColor: "transparent",
                            fillerColor:"rgba(0,0,0,.2)",
                            borderColor:"transparent",
                            start: 0,
                            end: 20
                     }
                ],
            }
            if (lineOption) {
                mychart.setOption(lineOption)
            } else {
                option && mychart.setOption(option)
            }
        }
    }
}
</script>

<style scoped lang="scss">
.r3 {
    margin-right: 34px;
    min-height:230px;
    position: relative;
    .head {
        width: 100%;
        height: 46px;
        position: relative;
        display: flex;
        align-items: center;
    }
    .title-img {
        width: 436px;
        height: 46px;
        left: 0;
        top: 3px;
        position: absolute;
    }
    .layerName {
        margin-left: 42px;
        z-index: 1;
        font-family: 'pmzd';
        font-weight: 400;
        font-size: 18px;
        color: #FFFFFF;
        line-height: 15px;
        text-shadow: 0px 0px 10px rgba(41, 81, 202, 0.8), 0px 0px 10px rgba(41, 81, 202, 0.8), 0px 0px 10px rgba(41, 81, 202, 0.8);
    }
    .layerEng {
        margin-left: 12px;
        z-index: 1;
        font-family: Source Han Sans CN;
        font-weight: 400;
        font-size: 12px;
        color: #8798AC;
        line-height: 15px;
    }
    .lchart {
        width: 460px;
        height: 240px;
        margin-left: 12px;
        position: relative;
        pointer-events: auto;
    }
}
.icoL {
    position: absolute;
    top: 55px;
    right: 110px;
    animation: icoAni 2s linear 1;
    animation-fill-mode: forwards;
}

.icoR {
    position: absolute;
    top: 55px;
    right: 110px;
    animation: icoAniR 2s linear 1;
    animation-fill-mode: forwards;
}

.icoL img {
    width: 30px
}

.icoR img {
    width: 30px
}

.yjName {
    font-size: 14px;
    color: #BABDC3;
    padding: 3px 10px 0 10px;
    position: absolute;
    top: 55px;
    right: 30px;
    animation: fontAni 2s linear 1s 1;
    opacity: 0;
    animation-fill-mode: forwards;
}

@keyframes icoAni {
    from {
        right: 80px
    }

    to {
        right: 136px
    }
}

@keyframes icoAniR {
    from {
        right: 80px
    }

    to {
        right: -30px
    }
}

@keyframes fontAni {
    from {
        opacity: 0;
    }

    to {
        opacity: 1
    }
}
</style>