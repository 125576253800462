<template>
	<ScaleScreen :width="1920" :height="1080" class="scale-wrap">
		<div class="editFrame" style="position:relative;">
			<mapbg v-if="themeTemplateConfig.mapComponentConfig" :dataList="themeTemplateConfig.mapComponentConfig.data.list"></mapbg>
			<div :style="'background: url('+backImageUrl+') no-repeat;background-size:100% 100%;'" class="mark"></div>
			<!-- <model1 :backImg="backImageUrl" ></model1> -->
			<div v-if="themeTemplateConfig.topComponentConfig" class="tops echart_component" style="position:relative;">
				<top1 :title="themeTemplateConfig.topComponentConfig.text" style="pointer-events: none;"></top1>
				<div v-if="isViewPage==false" class="component" style="z-index:999 !important;height:100%;width:100%;">
					<div class="componentFrame" style="margin-top:20px;">
						<div class="componentMenu" @click="showTopEdit()" style="width:160px;">
							<div class="componentIcon">
								<img src="../../assets/icon18.png" style="width: 16px;">
							</div>
							<div>头部和底部设置</div>
						</div>
						<div class="componentMenu" @click="showMapEdit()" style="width:160px;">
							<div class="componentIcon">
								<img src="../../assets/icon18.png" style="width: 16px;">
							</div>
							<div>地图设置</div>
						</div>
					</div>
				</div>
			</div>		
			<div v-if="themeTemplateConfig.contentComponentConfig" class="content" style="pointer-events: none;">
				<div v-for="(item_v,index_v) in columnList" :class="item_v.value"  :style="isViewPage==true&&item_v.value=='center'?'pointer-events: none;':'pointer-events: auto;'">
					<!-- <maptips1  v-if="item_v.value=='center'" style="pointer-events: none;"></maptips1> -->
					<maptips2  v-if="item_v.value=='center'" style="pointer-events: none;"></maptips2>
					<div v-for="(item,index) in themeTemplateConfig.contentComponentConfig[item_v.value]" class="single_row" :style="checkPosition(item)">
						<div class="echart_component" style="position:relative;">
							<title1 @SearchDatas="SelectComponentData" :selectYear="item.isHaveYearSearch" :selectMonth="item.isHaveMonthSearch" :selectDay="item.isHaveDaySearch" :title="item.text" :effectedComponent="item.effectedComponent" :english="item.subText" :tomore="item.isHaveMore" v-if="item.componentName=='title1'" style="pointer-events: none;"></title1>
							<title2 @SearchDatas="SelectComponentData" :selectYear="item.isHaveYearSearch" :selectMonth="item.isHaveMonthSearch" :selectDay="item.isHaveDaySearch" :title="item.text" :effectedComponent="item.effectedComponent" :english="item.subText" :tomore="item.isHaveMore" v-if="item.componentName=='title2'" style="pointer-events: none;"></title2>
							<echart1 :yAxisNames="item.data.yAxisNames" :names="item.data.names" :dataList="item.data.list" v-if="item.componentName=='echart1'" style="pointer-events: none;"></echart1>
							<echart2 :yAxisNames="item.data.yAxisNames" :names="item.data.names" :dataList="item.data.list"  v-if="item.componentName=='echart2'" style="pointer-events: none;"></echart2>
							<echart2_1 :yAxisNames="item.data.yAxisNames" :names="item.data.names" :dataList="item.data.list"  v-if="item.componentName=='echart2_1'" style="pointer-events: none;"></echart2_1>
							<echart3 :yAxisNames="item.data.yAxisNames" :names="item.data.names" :dataList="item.data.list" v-if="item.componentName=='echart3'" style="pointer-events: none;"></echart3>
							<echart4 :yAxisNames="item.data.yAxisNames" :names="item.data.names" :dataList="item.data.list" v-if="item.componentName=='echart4'" style="pointer-events: none;"></echart4>
							<echart5 :isChangeComponent="isChangeComponent" :yAxisNames="item.data.yAxisNames" :names="item.data.names" :dataList="item.data.list" v-if="item.componentName=='echart5'" style="pointer-events: none;"></echart5>
							<echart6 :yAxisNames="item.data.yAxisNames" :names="item.data.names" :dataList="item.data.list" v-if="item.componentName=='echart6'" style="pointer-events: none;"></echart6>
							<echart7 :isChangeComponent="isChangeComponent" :yAxisNames="item.data.yAxisNames" :names="item.data.names" :dataList="item.data.list" v-if="item.componentName=='echart7'" style="pointer-events: none;"></echart7>
							<echart8 :isChangeComponent="isChangeComponent" :yAxisNames="item.data.yAxisNames" :names="item.data.names" :dataList="item.data.list" v-if="item.componentName=='echart8'" style="pointer-events: none;"></echart8>
							<echart9 :yAxisNames="item.data.yAxisNames" :names="item.data.names" :dataList="item.data.list" v-if="item.componentName=='echart9'" style="pointer-events: none;"></echart9>
							<echart10 :yAxisNames="item.data.yAxisNames" :names="item.data.names" :dataList="item.data.list" v-if="item.componentName=='echart10'" style="pointer-events: none;"></echart10>

							<bar1 v-if="item.componentName=='bar1'" style="pointer-events: none;"></bar1>

							<textlist1 :names="item.data.names" :dataList="item.data.list"  v-if="item.componentName=='textlist1'" style="pointer-events: none;"></textlist1>
							<textlist1_1 :names="item.data.names" :dataList="item.data.list"  v-if="item.componentName=='textlist1_1'" style="pointer-events: none;"></textlist1_1>
							<textlist2 :names="item.data.names" :dataList="item.data.list"  v-if="item.componentName=='textlist2'" style="pointer-events: none;"></textlist2>
							<textlist2_1 :names="item.data.names" :dataList="item.data.list"  v-if="item.componentName=='textlist2_1'" style="pointer-events: none;"></textlist2_1>
							<textlist2_2 :names="item.data.names" :dataList="item.data.list"  v-if="item.componentName=='textlist2_2'" style="pointer-events: none;"></textlist2_2>
							<textlist3 @SearchListDatas="SelectComponentListData" :names="item.data.names" :dataList="item.data.list"  v-if="item.componentName=='textlist3'" :effectedComponent="item.name"></textlist3>
							<textlist4 :names="item.data.names" :dataList="item.data.list"  v-if="item.componentName=='textlist4'" style="pointer-events: none;"></textlist4>
							<textlist5 :names="item.data.names" :dataList="item.data.list"  v-if="item.componentName=='textlist5'" style="pointer-events: none;"></textlist5>
							<textlist5_1 :names="item.data.names" :dataList="item.data.list"  v-if="item.componentName=='textlist5_1'" style="pointer-events: none;"></textlist5_1>

							<piclist1 :dataList="item.data.list"  v-if="item.componentName=='piclist1'" style="pointer-events: none;"></piclist1>
							<piclist2 :dataList="item.data.list"  v-if="item.componentName=='piclist2'" style="pointer-events: none;"></piclist2>

							<maptips1 :dataList="item.data.list" v-if="item.componentName=='maptips1'"  style="pointer-events: none;"></maptips1>

							<div v-if="item.type=='title'&&isViewPage==false" class="component">
								<div class="componentFrame" style="margin-top:0px;">
									<div class="componentMenu" @click="showTitleEdit(item_v.value,index)">
										<div class="componentIcon">
											<img src="../../assets/icon18.png" style="width: 16px;">
										</div>
										<div>标题设置</div>
									</div>
								</div>
							</div>
							<div v-if="item.type=='content'&&isViewPage==false" class="component">
								<div class="componentFrame" style="margin-top:0px;">
									<div class="componentMenu" @click="showComponents(item_v.value,index,item.componentName)">
										<div class="componentIcon">
											<img src="../../assets/icon18.png" style="width: 16px;">
										</div>
										<div>组件更换</div>
									</div>
									<div @click="openComponentDataEdit(item_v.value,index)" class="componentMenu">
										<div class="componentIcon">
											<img src="../../assets/icon19.png" style="width: 16px;">
										</div>
										<div>数据编辑</div>
									</div>
									<div class="componentMenu">
										<div class="componentIcon">
											<img src="../../assets/icon20.png" style="width: 17px;">
										</div>
										<div>数据示例</div>
									</div>
								</div>
							</div>
							<!--日期查询-->
							<div v-if="item.type=='content'" class="component_date" :style="checkTemplate(item)">
								<date_search @SearchDatas="SelectComponentDataNew" :effectedComponent="item.name" :searchConfig="item.search"></date_search>
							</div>
						</div>
					</div>
				</div>
			</div>

			<bottom1 v-if="themeTemplateConfig.bottomComponentConfig" :linkList="themeTemplateConfig.bottomComponentConfig.linkList" style="pointer-events: none;"></bottom1>

			<!-- 组件更换 -->
			<div class="componentS" v-show="componentS">
				<div class="componentTitle">
					<div class="componentTitleName">标准组件选择</div>
					<div class="componentclose" @click="componentClose"><img src="../../assets/close.png" style="width: 100%;">
					</div>
				</div>
				<div class="componentSMenu">
					<div :class="componentclass" @click="model()">全部组件</div>
					<div :class="componentclass1" @click="model1()">饼状图</div>
					<div :class="componentclass2" @click="model2()">环形图</div>
					<div :class="componentclass3" @click="model3()">柱状图</div>
					<div :class="componentclass4" @click="model4()">折线图</div>
					<div :class="componentclass5" @click="model5()">雷达图</div>
					<div :class="componentclass6" @click="model6()">地图套件</div>
					<div :class="componentclass7" @click="model7()">图文套件</div>
					<div class="classLine" :style="{left:leftmove}"></div>
				</div>
				<div class="componentClassAll">
					<div class="componentClassMenuSelect">全部组件</div>
					<div class="componentClassMenu">标准组件</div>
					<div class="componentClassMenu">宽组件</div>
					<div class="componentClassMenu">大组件</div>
				</div>
				<div class="componentListAll">
					<div @click="changeComponent(item)" v-for="(item,index) in componentLists" class="componentList">
						<div class="componentPic">
							<img :src="item.image" :onerror="defaultImg">
						</div>
						<div class="componentPicName">
							【标准组件】{{item.name}}
						</div>
					</div>
					<div class="page_bottom">
						<span>共{{ (componentTotal / componentPageSize).toFixed(0) }}页 {{componentTotal}}条</span>
						<el-pagination
							background
							layout="prev, pager, next"
							:total="componentTotal" 
							:page-size="componentPageSize"
							:pager-count="5"
							@current-change="componentPageChange" 
							:current-page="currentPage"
							:small="true">
						</el-pagination>
					</div>
				</div>
			</div>

			<div v-if="isViewPage==false" :class="menu">
				<div class="return" @click="menulow()" :style="{transform:low}">
					<button><img src="../../assets/return.png" style="width: 100%;"></button>
				</div>
				<div class="menulist" v-show="menulist">

					<div class="menuline1"></div>

					<div class="menulogo">
						<img src="../../assets/logo.png" style="width: 100%;">
					</div>

					<div class="menuname">可视化大屏名称</div>
					<button class="menuediticon">
						<img src="../../assets/edit.png" style="width: 100%;">
					</button>
					<div class="menuversion">试用期，7天可用</div>

					<div @click="goTemplate()" class="modelediticon"><img src="../../assets/icon13.png" style="width: 100%;"></div>
					<button @click="goTemplate()"  class="iconname">模板编辑</button>

					<div class="modelicon2"><img src="../../assets/icon11.png" style="width: 100%;"></div>
					<button class="iconname">模板还原</button>

					<div class="menuline2"></div>

					<div @click="goView()" class="modelicon2"><img src="../../assets/icon12.png" style="width: 100%;"></div>
					<button @click="goView()" class="iconname">预览</button>

					<div class="modelicon3"><img src="../../assets/icon14.png" style="width: 100%;"></div>
					<button class="iconname">分享</button>

					<div class="modelicon3"><img src="../../assets/icon15.png" style="width: 100%;"></div>
					<button class="iconname">生成可视化源码</button>

					<div class="menuline2"></div>

					<div class="modelicon2"><img src="../../assets/screen.png" style="width: 100%;"></div>
					<button class="iconname" @click="screen()">全屏</button>

					<div class="modelicon2"><img src="../../assets/icon16.png" style="width: 100%;"></div>
					<button @click="saveProject()" class="iconname">保存</button>

					<div class="modelicon2"><img src="../../assets/icon17.png" style="width: 100%;"></div>
					<button class="iconname" @click="editout()">退出</button>

				</div>
			</div>

			<!-- 数据编辑 -->
			<div class="componentS" v-show="dataEditS">
				<div class="componentTitle">
					<div class="componentTitleName">数据编辑</div>
					<div class="componentclose" @click="dataEditS=false"><img src="../../assets/close.png" style="width: 100%;">
					</div>
				</div>
				<div v-if="dataEditS" class="componentContents" style="height:550px;overflow-y:scroll;">
					<div class="single">
						<div class="l_txt">组件名:</div>
						<div class="r_input" style="line-height:40px;">{{themeTemplateConfig.contentComponentConfig[updatedComponentPosition][updatedComponentIndex].name}}</div>
					</div>
					<div class="single">
						<div class="l_txt">调用组件名:</div>
						<div class="r_input" style="line-height:40px;">{{themeTemplateConfig.contentComponentConfig[updatedComponentPosition][updatedComponentIndex].componentName}}</div>
					</div>
					<div v-if="selectedComponentType=='echart'" class="single">
						<div class="l_txt">Echart的y轴提示文字:</div>
						<div class="r_input"><el-input placeholder="y轴提示文字" v-model="yAxisNames"></el-input></div>
					</div>
					<div v-if="selectedComponentType=='echart'" class="single">
						<div class="l_txt">Echart的多轴文字列表:</div>
						<div class="r_input"><el-input placeholder="多轴文字列表" v-model="field_names"></el-input></div>
					</div>
					<div v-if="selectedComponentType=='textlist'" class="single">
						<div class="l_txt">栏目列表:</div>
						<div class="r_input"><el-input placeholder="栏目列表" v-model="field_names"></el-input></div>
					</div>
					<div class="single">
						<div class="l_txt">接口选择:</div>
						<div class="r_input">
							<el-select filterable style="width:500px;" v-model="params_uuid" placeholder="请选择">
								<el-option v-for="item_api in apiList" :label="item_api.remark"
									:value="item_api.uuid">
								</el-option>
							</el-select>
							<!-- <el-input v-model="params_uuid" type="text" placeholder="uuid参数值"></el-input> -->
						</div>
					</div>
					<div class="single">
						<div class="l_txt">查看更多的UUID:</div>
						<div class="r_input">
							<el-select style="width:500px;" filterable v-model="themeTemplateConfig.contentComponentConfig[updatedComponentPosition][updatedComponentIndex].moreUUID" placeholder="请选择">
								<el-option v-for="item_api in apiList" :label="item_api.remark"
									:value="item_api.uuid">
								</el-option>
							</el-select>
						</div>
					</div>
					<div class="single_tip">
						<div class="l_txt">日期搜索选择:</div>
						<div class="r_input">
							<el-checkbox @change="showDateSearch()" style="font-size:14px;" v-model="themeTemplateConfig.contentComponentConfig[updatedComponentPosition][updatedComponentIndex].search.titleMore.isShow">更多显示</el-checkbox>
							<el-checkbox @change="showDateSearch()" style="font-size:14px;" v-model="themeTemplateConfig.contentComponentConfig[updatedComponentPosition][updatedComponentIndex].search.year.isShow">年查询</el-checkbox>
							<el-checkbox @change="showDateSearch()" style="font-size:14px;" v-model="themeTemplateConfig.contentComponentConfig[updatedComponentPosition][updatedComponentIndex].search.month.isShow">月查询</el-checkbox>
							<el-checkbox @change="showDateSearch()" style="font-size:14px;" v-model="themeTemplateConfig.contentComponentConfig[updatedComponentPosition][updatedComponentIndex].search.day.isShow">日查询</el-checkbox>
							<el-checkbox @change="showDateSearch()" style="font-size:14px;" v-model="themeTemplateConfig.contentComponentConfig[updatedComponentPosition][updatedComponentIndex].search.today.isShow">今日查询</el-checkbox>
							<el-checkbox @change="showDateSearch()" style="font-size:14px;" v-model="themeTemplateConfig.contentComponentConfig[updatedComponentPosition][updatedComponentIndex].search.thisMonth.isShow">本月查询</el-checkbox>
							<el-checkbox @change="showDateSearch()" style="font-size:14px;" v-model="themeTemplateConfig.contentComponentConfig[updatedComponentPosition][updatedComponentIndex].search.thisYear.isShow">本年查询</el-checkbox>
							<el-checkbox @change="showDateSearch()" style="font-size:14px;" v-model="themeTemplateConfig.contentComponentConfig[updatedComponentPosition][updatedComponentIndex].search.thirtyDays.isShow">30天查询</el-checkbox>
							<el-checkbox @change="showDateSearch()" style="font-size:14px;" v-model="themeTemplateConfig.contentComponentConfig[updatedComponentPosition][updatedComponentIndex].search.sixtyDays.isShow">60天查询</el-checkbox>
							<el-checkbox @change="showDateSearch()" style="font-size:14px;" v-model="themeTemplateConfig.contentComponentConfig[updatedComponentPosition][updatedComponentIndex].search.ninetyDays.isShow">90天查询</el-checkbox>
						</div>
					</div>
					<div class="single">
						<div class="l_txt">默认选中的搜索:</div>
						<div class="r_input">
							<el-select style="width:400px;" filterable v-model="themeTemplateConfig.contentComponentConfig[updatedComponentPosition][updatedComponentIndex].search.selectedDate" placeholder="请选择">
								<el-option v-for="item_search in timeSearchList" :label="item_search.name"
									:value="item_search.value">
								</el-option>
							</el-select>
						</div>
					</div>					
					<!--标题更多查询-->
					<div v-if="themeTemplateConfig.contentComponentConfig[updatedComponentPosition][updatedComponentIndex].search.titleMore.isShow==true" class="single">
						<div class="s_single">
							<div class="l_txt2">标题上更多查询uuid:</div>
							<div @change="showDateSearch()"  style="width:60%" class="r_input">
								<el-select @change="showDateSearWch()" style="width:98%;" clearable filterable v-model="themeTemplateConfig.contentComponentConfig[updatedComponentPosition][updatedComponentIndex].search.titleMore.uuid" placeholder="请选择">
									<el-option v-for="item_api in apiList" :label="item_api.remark"
										:value="item_api.uuid">
									</el-option>
								</el-select>
							</div>
						</div>
					</div>
					<!--年查询-->
					<div v-if="themeTemplateConfig.contentComponentConfig[updatedComponentPosition][updatedComponentIndex].search.year.isShow==true" class="single">
						<div class="s_single">
							<div class="l_txt2">年查询uuid:</div>
							<div style="width:60%" class="r_input">
								<el-select @change="showDateSearch()" style="width:98%;" clearable filterable v-model="themeTemplateConfig.contentComponentConfig[updatedComponentPosition][updatedComponentIndex].search.year.uuid" placeholder="请选择">
									<el-option v-for="item_api in apiList" :label="item_api.remark"
										:value="item_api.uuid">
									</el-option>
								</el-select>
							</div>
						</div>
						<div class="s_single">
							<div class="l_txt2">年查询更多uuid:</div>
							<div @change="showDateSearch()"  style="width:60%" class="r_input">
								<el-select style="width:98%;" clearable filterable v-model="themeTemplateConfig.contentComponentConfig[updatedComponentPosition][updatedComponentIndex].search.year.moreUUID" placeholder="请选择">
									<el-option v-for="item_api in apiList" :label="item_api.remark"
										:value="item_api.uuid">
									</el-option>
								</el-select>
							</div>
						</div>
					</div>
					<!--月查询-->
					<div v-if="themeTemplateConfig.contentComponentConfig[updatedComponentPosition][updatedComponentIndex].search.month.isShow==true" class="single">
						<div class="s_single">
							<div class="l_txt2">月查询uuid:</div>
							<div @change="showDateSearch()"  style="width:60%" class="r_input">
								<el-select style="width:98%;" clearable filterable v-model="themeTemplateConfig.contentComponentConfig[updatedComponentPosition][updatedComponentIndex].search.month.uuid" placeholder="请选择">
									<el-option v-for="item_api in apiList" :label="item_api.remark"
										:value="item_api.uuid">
									</el-option>
								</el-select>
							</div>
						</div>
						<div class="s_single">
							<div class="l_txt2">月查询更多uuid:</div>
							<div @change="showDateSearch()"  style="width:60%" class="r_input">
								<el-select style="width:98%;" clearable filterable v-model="themeTemplateConfig.contentComponentConfig[updatedComponentPosition][updatedComponentIndex].search.month.moreUUID" placeholder="请选择">
									<el-option v-for="item_api in apiList" :label="item_api.remark"
										:value="item_api.uuid">
									</el-option>
								</el-select>
							</div>
						</div>
					</div>	
					<!--日查询-->
					<div v-if="themeTemplateConfig.contentComponentConfig[updatedComponentPosition][updatedComponentIndex].search.day.isShow==true" class="single">
						<div class="s_single">
							<div class="l_txt2">日查询uuid:</div>
							<div @change="showDateSearch()"  style="width:60%" class="r_input">
								<el-select style="width:98%;" clearable filterable v-model="themeTemplateConfig.contentComponentConfig[updatedComponentPosition][updatedComponentIndex].search.day.uuid" placeholder="请选择">
									<el-option v-for="item_api in apiList" :label="item_api.remark"
										:value="item_api.uuid">
									</el-option>
								</el-select>
							</div>
						</div>
						<div class="s_single">
							<div class="l_txt2">日查询更多uuid:</div>
							<div @change="showDateSearch()"  style="width:60%" class="r_input">
								<el-select style="width:98%;" clearable filterable v-model="themeTemplateConfig.contentComponentConfig[updatedComponentPosition][updatedComponentIndex].search.day.moreUUID" placeholder="请选择">
									<el-option v-for="item_api in apiList" :label="item_api.remark"
										:value="item_api.uuid">
									</el-option>
								</el-select>
							</div>
						</div>
					</div>	
					<!--本日查询-->
					<div v-if="themeTemplateConfig.contentComponentConfig[updatedComponentPosition][updatedComponentIndex].search.today.isShow==true" class="single">
						<div class="s_single">
							<div class="l_txt2">本日查询uuid:</div>
							<div @change="showDateSearch()"  style="width:60%" class="r_input">
								<el-select style="width:98%;" clearable filterable v-model="themeTemplateConfig.contentComponentConfig[updatedComponentPosition][updatedComponentIndex].search.today.uuid" placeholder="请选择">
									<el-option v-for="item_api in apiList" :label="item_api.remark"
										:value="item_api.uuid">
									</el-option>
								</el-select>
							</div>
						</div>
						<div class="s_single">
							<div class="l_txt2">本日查询更多uuid:</div>
							<div @change="showDateSearch()"  style="width:60%" class="r_input">
								<el-select style="width:98%;" clearable filterable v-model="themeTemplateConfig.contentComponentConfig[updatedComponentPosition][updatedComponentIndex].search.today.moreUUID" placeholder="请选择">
									<el-option v-for="item_api in apiList" :label="item_api.remark"
										:value="item_api.uuid">
									</el-option>
								</el-select>
							</div>
						</div>
					</div>
					<!--本月查询-->
					<div v-if="themeTemplateConfig.contentComponentConfig[updatedComponentPosition][updatedComponentIndex].search.thisMonth.isShow==true" class="single">
						<div class="s_single">
							<div class="l_txt2">本月查询uuid:</div>
							<div @change="showDateSearch()"  style="width:60%" class="r_input">
								<el-select style="width:98%;" clearable filterable v-model="themeTemplateConfig.contentComponentConfig[updatedComponentPosition][updatedComponentIndex].search.thisMonth.uuid" placeholder="请选择">
									<el-option v-for="item_api in apiList" :label="item_api.remark"
										:value="item_api.uuid">
									</el-option>
								</el-select>
							</div>
						</div>
						<div class="s_single">
							<div class="l_txt2">本月查询更多uuid:</div>
							<div @change="showDateSearch()"  style="width:60%" class="r_input">
								<el-select style="width:98%;" clearable filterable v-model="themeTemplateConfig.contentComponentConfig[updatedComponentPosition][updatedComponentIndex].search.thisMonth.moreUUID" placeholder="请选择">
									<el-option v-for="item_api in apiList" :label="item_api.remark"
										:value="item_api.uuid">
									</el-option>
								</el-select>
							</div>
						</div>
					</div>
					<!--本年查询-->
					<div v-if="themeTemplateConfig.contentComponentConfig[updatedComponentPosition][updatedComponentIndex].search.thisYear.isShow==true" class="single">
						<div class="s_single">
							<div class="l_txt2">本年查询uuid:</div>
							<div @change="showDateSearch()"  style="width:60%" class="r_input">
								<el-select style="width:98%;" clearable filterable v-model="themeTemplateConfig.contentComponentConfig[updatedComponentPosition][updatedComponentIndex].search.thisYear.uuid" placeholder="请选择">
									<el-option v-for="item_api in apiList" :label="item_api.remark"
										:value="item_api.uuid">
									</el-option>
								</el-select>
							</div>
						</div>
						<div class="s_single">
							<div class="l_txt2">本年查询更多uuid:</div>
							<div @change="showDateSearch()"  style="width:60%" class="r_input">
								<el-select style="width:98%;" clearable filterable v-model="themeTemplateConfig.contentComponentConfig[updatedComponentPosition][updatedComponentIndex].search.thisYear.moreUUID" placeholder="请选择">
									<el-option v-for="item_api in apiList" :label="item_api.remark"
										:value="item_api.uuid">
									</el-option>
								</el-select>
							</div>
						</div>
					</div>	
					<!--30天查询-->
					<div v-if="themeTemplateConfig.contentComponentConfig[updatedComponentPosition][updatedComponentIndex].search.thirtyDays.isShow==true" class="single">
						<div class="s_single">
							<div class="l_txt2">30天查询uuid:</div>
							<div @change="showDateSearch()"  style="width:60%" class="r_input">
								<el-select style="width:98%;" clearable filterable v-model="themeTemplateConfig.contentComponentConfig[updatedComponentPosition][updatedComponentIndex].search.thirtyDays.uuid" placeholder="请选择">
									<el-option v-for="item_api in apiList" :label="item_api.remark"
										:value="item_api.uuid">
									</el-option>
								</el-select>
							</div>
						</div>
						<div class="s_single">
							<div class="l_txt2">30天查询更多uuid:</div>
							<div @change="showDateSearch()"  style="width:60%" class="r_input">
								<el-select style="width:98%;" clearable filterable v-model="themeTemplateConfig.contentComponentConfig[updatedComponentPosition][updatedComponentIndex].search.thirtyDays.moreUUID" placeholder="请选择">
									<el-option v-for="item_api in apiList" :label="item_api.remark"
										:value="item_api.uuid">
									</el-option>
								</el-select>
							</div>
						</div>
					</div>
					<!--60天查询-->
					<div v-if="themeTemplateConfig.contentComponentConfig[updatedComponentPosition][updatedComponentIndex].search.sixtyDays.isShow==true" class="single">
						<div class="s_single">
							<div class="l_txt2">60天查询uuid:</div>
							<div @change="showDateSearch()"  style="width:60%" class="r_input">
								<el-select style="width:98%;" clearable filterable v-model="themeTemplateConfig.contentComponentConfig[updatedComponentPosition][updatedComponentIndex].search.sixtyDays.uuid" placeholder="请选择">
									<el-option v-for="item_api in apiList" :label="item_api.remark"
										:value="item_api.uuid">
									</el-option>
								</el-select>
							</div>
						</div>
						<div class="s_single">
							<div class="l_txt2">60天查询更多uuid:</div>
							<div @change="showDateSearch()"  style="width:60%" class="r_input">
								<el-select style="width:98%;" clearable filterable v-model="themeTemplateConfig.contentComponentConfig[updatedComponentPosition][updatedComponentIndex].search.sixtyDays.moreUUID" placeholder="请选择">
									<el-option v-for="item_api in apiList" :label="item_api.remark"
										:value="item_api.uuid">
									</el-option>
								</el-select>
							</div>
						</div>
					</div>
					<!--90天查询-->
					<div v-if="themeTemplateConfig.contentComponentConfig[updatedComponentPosition][updatedComponentIndex].search.ninetyDays.isShow==true" class="single">
						<div class="s_single">
							<div class="l_txt2">90天查询uuid:</div>
							<div @change="showDateSearch()"  style="width:60%" class="r_input">
								<el-select style="width:98%;" clearable filterable v-model="themeTemplateConfig.contentComponentConfig[updatedComponentPosition][updatedComponentIndex].search.ninetyDays.uuid" placeholder="请选择">
									<el-option v-for="item_api in apiList" :label="item_api.remark"
										:value="item_api.uuid">
									</el-option>
								</el-select>
							</div>
						</div>
						<div class="s_single">
							<div class="l_txt2">90天查询更多uuid:</div>
							<div @change="showDateSearch()"  style="width:60%" class="r_input">
								<el-select style="width:98%;" clearable filterable v-model="themeTemplateConfig.contentComponentConfig[updatedComponentPosition][updatedComponentIndex].search.ninetyDays.moreUUID" placeholder="请选择">
									<el-option v-for="item_api in apiList" :label="item_api.remark"
										:value="item_api.uuid">
									</el-option>
								</el-select>
							</div>
						</div>
					</div>									
					<div class="single_btn">
						<el-button @click="changeComponentData">提交</el-button>
					</div>
				</div>
			</div>
			<!-- 标题编辑 -->
			<div class="componentS" v-show="titleEditS">
				<div class="componentTitle">
					<div class="componentTitleName">标题编辑</div>
					<div class="componentclose" @click="titleEditS=false"><img src="../../assets/close.png" style="width: 100%;">
					</div>
				</div>
				<div v-if="titleEditS" class="componentContents">
					<div class="single">
						<div class="l_txt">显示文字:</div>
						<div class="r_input"><el-input style="width:400px;" v-model="themeTemplateConfig.contentComponentConfig[updatedComponentPosition][updatedComponentIndex].text" type="text" placeholder="url"></el-input></div>
					</div>
					<div class="single">
					<div class="l_txt">副文字:</div>
					<div class="r_input"><el-input placeholder="显示的英文" v-model="themeTemplateConfig.contentComponentConfig[updatedComponentPosition][updatedComponentIndex].subText"></el-input></div>
					</div>                
					<div class="single">
						<div class="l_txt">查看更多:</div>
						<div class="r_input">
							<el-select v-model="themeTemplateConfig.contentComponentConfig[updatedComponentPosition][updatedComponentIndex].isHaveMore" placeholder="是否有查看更多">
							<el-option v-for="item_s in is_trues" :key="item_s.value" :label="item_s.name"
								:value="item_s.value">
							</el-option>
							</el-select>
						</div>
					</div>  
					<div v-if="themeTemplateConfig.contentComponentConfig[updatedComponentPosition][updatedComponentIndex].isHaveMore" class="single">
						<div class="l_txt">查看更多的UUID:</div>
						<div class="r_input">
							<el-select style="width:500px;" filterable v-model="themeTemplateConfig.contentComponentConfig[updatedComponentPosition][updatedComponentIndex].moreUUID" placeholder="请选择">
								<el-option v-for="item_api in apiList" :label="item_api.remark"
									:value="item_api.uuid">
								</el-option>
							</el-select>
						</div>
					</div> 
					<div class="single">
						<div class="l_txt">是否有年查询:</div>
						<div class="r_input">
							<el-select v-model="themeTemplateConfig.contentComponentConfig[updatedComponentPosition][updatedComponentIndex].isHaveYearSearch" placeholder="是否有年查询">
							<el-option v-for="item_s in is_trues" :key="item_s.value" :label="item_s.name"
								:value="item_s.value">
							</el-option>
							</el-select>
						</div>
					</div> 					
					<div class="single">
						<div class="l_txt">是否有月查询:</div>
						<div class="r_input">
							<el-select v-model="themeTemplateConfig.contentComponentConfig[updatedComponentPosition][updatedComponentIndex].isHaveMonthSearch" placeholder="是否有月查询">
							<el-option v-for="item_s in is_trues" :key="item_s.value" :label="item_s.name"
								:value="item_s.value">
							</el-option>
							</el-select>
						</div>
					</div>     
					<div class="single">
						<div class="l_txt">是否有日查询:</div>
						<div class="r_input">
							<el-select v-model="themeTemplateConfig.contentComponentConfig[updatedComponentPosition][updatedComponentIndex].isHaveDaySearch" placeholder="是否有日查询">
							<el-option v-for="item_s in is_trues" :key="item_s.value" :label="item_s.name"
								:value="item_s.value">
							</el-option>
							</el-select>
						</div>
					</div> 	
					<div class="single">
						<div class="l_txt">查询的组件:</div>
						<div class="r_input">
							<!-- <el-input style="width:400px;" v-model="themeTemplateConfig.contentComponentConfig[updatedComponentPosition][updatedComponentIndex].effectedComponent" type="text" placeholder="查询的组件名"></el-input> -->
							<el-select v-model="themeTemplateConfig.contentComponentConfig[updatedComponentPosition][updatedComponentIndex].effectedComponent" placeholder="查询的组件名">
							<el-option v-for="item_s in templateContentComponentList" :key="item_s.value" :label="item_s.name"
								:value="item_s.value">
							</el-option>
							</el-select>
						</div>
					</div> 									
					<div class="single_btn">
						<el-button @click="titleEditS=false">提交</el-button>
					</div>
				</div>
			</div>

			<!-- 地图编辑 -->
			<div class="componentS" v-show="mapEditS">
				<div class="componentTitle">
					<div class="componentTitleName">地图编辑</div>
					<div class="componentclose" @click="mapEditS=false"><img src="../../assets/close.png" style="width: 100%;">
					</div>
				</div>
				<div v-if="mapEditS" class="componentContents">
					<div class="single">
						<div class="l_txt">uuid:</div>
						<div class="r_input">
							<el-select style="width:500px;" filterable v-model="themeTemplateConfig.mapComponentConfig.data.params.uuid" placeholder="请选择">
								<el-option v-for="item_api in apiList" :label="item_api.remark"
									:value="item_api.uuid">
								</el-option>
							</el-select>
						</div>
					</div>  			                				
					<div class="single_btn">
						<el-button @click="changeMapData">提交</el-button>
					</div>
				</div>
			</div>

			<!-- 头部和底部编辑 -->
			<div class="componentS" v-show="topEditS">
				<div class="componentTitle">
					<div class="componentTitleName">标题编辑</div>
					<div class="componentclose" @click="topEditS=false"><img src="../../assets/close.png" style="width: 100%;">
					</div>
				</div>
				<div v-if="topEditS" class="componentContents">
					<div class="single">
						<div class="l_txt">头部显示文字:</div>
						<div class="r_input"><el-input style="width:400px;" v-model="themeTemplateConfig.topComponentConfig.text" type="text" placeholder="显示文字"></el-input></div>
					</div>  
					<div class="single">
						<div class="l_txt">底部链接设置:</div>
						<div class="r_input"><a @click="addBottomLink">添加链接</a></div>
					</div> 
					<div v-for="(item,index) in themeTemplateConfig.bottomComponentConfig.linkList" class="single">
						<div class="l_txt">名称:</div>
						<div class="r_input">
							<el-input style="width:400px;" v-model="item.name" type="text" placeholder="显示文字"></el-input>
						</div>
						<div class="l_txt">项目ID:</div>
						<div class="r_input">
							<el-input style="width:400px;width:95%;float:left;" v-model="item.url" type="text" placeholder="项目ID"></el-input> <label @click="delBottomLink(index)" style="color:#fff;float:left;margin-left:5px;">删除</label>
						</div>
					</div> 					                				
					<div class="single_btn">
						<el-button @click="topEditS=false">提交</el-button>
					</div>
				</div>
			</div>

			<!-- 登录弹窗 -->
			<el-dialog :visible.sync="logVisible"
						:modal-append-to-body="false"
						custom-class="log-dialog"
						top="0"
						width="100%">
				<div class="log-content">
					<div class="log-title">登录框</div>
					<div class="log-rectangle">
						<div class="log-name">用户登录</div>
						<div class="flex-cc">
							<el-input v-model="loginForm.userName" placeholder="请输入用户名">
								<div slot="prefix">
									<img src="../../assets/lock1.png" class="lock-img" />
								</div>
							</el-input>
						</div>
						<div class="flex-cc">
							<el-input v-model="loginForm.userPwd" placeholder="请输入您的密码" show-password>
								<div slot="prefix">
									<img src="../../assets/password.png" class="lock-img" />
								</div>
							</el-input>
						</div>
						<div class="flex-cc">
							<el-button type="primary" class="log-button" @click="userLogin">
								登录
							</el-button>
						</div>
					</div>
				</div>
			</el-dialog>
			<!-- 更多数据的弹窗 -->
			<el-dialog :visible.sync="moreVisible"
						:modal-append-to-body="false"
						width="60%">
			<!-- <table class="more_table">
				<tr>
					<th v-for="(item,index) in moreColumnList">{{item}}</th>
				</tr>
				<tr v-for="(item,index) in moreDataList">
					<td v-if="moreColumnList.length>0&&item.value0">{{item.value0}}</td>
					<td v-if="moreColumnList.length>1&&item.value1">{{item.value1}}</td>
					<td v-if="moreColumnList.length>2">{{item.value2}}</td>
					<td v-if="moreColumnList.length>3">{{item.value3}}</td>
					<td v-if="moreColumnList.length>4">{{item.value4}}</td>
					<td v-if="moreColumnList.length>5">{{item.value5}}</td>
					<td v-if="moreColumnList.length>6">{{item.value6}}</td>
					<td v-if="moreColumnList.length>7">{{item.value7}}</td>
					<td v-if="moreColumnList.length>8">{{item.value8}}</td>
					<td v-if="moreColumnList.length>9">{{item.value9}}</td>
					<td v-if="moreColumnList.length>10">{{item.value10}}</td>
					<td v-if="moreColumnList.length>11">{{item.value11}}</td>
				</tr>
			</table> -->
			</el-dialog>
			<largedialog :dialogVisible="moreVisible" @largeclose="moreVisible=false" :vdata="moreDialogData"></largedialog>
		</div>
	</ScaleScreen>
</template>

<script>
	import { 
		getApiListData,//接口列表
		getComponentDatas,
		editProjectData,
		getProjectDetailData,
		addProjectData,
		getComponentDetailData,
		getTemplateDetailData,
		getComponentListData,
	} from "@/api/index";
	import screenfull from 'screenfull';
	import { Message } from 'element-ui';
	import router from '@/router';
	import ScaleScreen from '../../components/scale-screen/scale-screen.vue'
	import model1 from '../../components/model/model1.vue'
	import bar1 from '../../components/bar1.vue';

	import title1 from '../../components/title/title_1.vue';
	import title2 from '../../components/title/title_2.vue';

	import top1 from '../../components/top/header_1.vue';

	import echart1 from '../../components/echarts/echart_1.vue';
	import echart2 from '../../components/echarts/echart_2.vue';
	import echart2_1 from '../../components/echarts/echart_2_1.vue';
	import echart3 from '../../components/echarts/echart_3.vue';
	import echart4 from '../../components/echarts/echart_4.vue';
	import echart5 from '../../components/echarts/echart_5.vue';
	import echart6 from '../../components/echarts/echart_6.vue';
	import echart7 from '../../components/echarts/echart_7.vue';
	import echart8 from '../../components/echarts/echart_8.vue';
	import echart9 from '../../components/echarts/echart_9.vue';
	import echart10 from '../../components/echarts/echart_10.vue';

	import textlist1 from '../../components/textlist/textlist_1.vue';
	import textlist1_1 from '../../components/textlist/textlist_1_1.vue';
	import textlist2 from '../../components/textlist/textlist_2.vue';
	import textlist2_1 from '../../components/textlist/textlist_2_1.vue';
	import textlist2_2 from '../../components/textlist/textlist_2_2.vue';
	import textlist3 from '../../components/textlist/textlist_3.vue';
	import textlist4 from '../../components/textlist/textlist_4.vue';
	import textlist5 from '../../components/textlist/textlist_5.vue';
	import textlist5_1 from '../../components/textlist/textlist_5_1.vue';

	import piclist1 from '../../components/piclist/piclist_1.vue';
	import piclist2 from '../../components/piclist/piclist_2.vue';

	import bottom1 from '../../components/bottom/bottom_1.vue';

	import mapbg from '../../components/map/mapbg.vue';

	import maptips1 from '../../components/maptips/maptips_1.vue';
	import maptips2 from '../../components/maptips/maptips_2.vue';

	import largedialog from '../../components/dialog/largedialog.vue';

	import date_search from '../../components/date_search.vue';

	export default {
		components: {
			ScaleScreen,
			title1,
			title2,
			top1,
			model1,
			echart1,
			echart2,
			echart2_1,
			echart3,
			echart4,
			echart5,
			echart6,
			echart7,
			echart8,
			echart9,
			echart10,

			bar1,
			textlist1,
			textlist1_1,
			textlist2,
			textlist2_1,
			textlist2_2,
			textlist3,
			textlist4,
			textlist5,
			textlist5_1,
			piclist1,
			piclist2,
			bottom1,

			mapbg,

			maptips1,
			maptips2,

			largedialog,
			date_search,
		},
		data() {
			return {
				defaultImg: 'this.src = "' + require('../../assets/imgerror.png') + '"', //默认图片的地址
				fullscreen: false,
				menu: 'editMenu',
				menulist: true,
				low: 'rotateY(0deg)',
				componentclass: 'componentclass componentclassSelected',
				componentclass1: 'componentclass1 componentclassSelect',
				componentclass2: 'componentclass1 componentclassSelect',
				componentclass3: 'componentclass1 componentclassSelect',
				componentclass4: 'componentclass1 componentclassSelect',
				componentclass5: 'componentclass1 componentclassSelect',
				componentclass6: 'componentclass1 componentclassSelect',
				componentclass7: 'componentclass1 componentclassSelect',
				leftmove: '7%',
				componentS:false,//组件更换弹窗显示
				dataEditS:false,//数据编辑弹窗显示
				titleEditS:false,//标题编辑弹窗显示
				isShow1:false,
				themeTemplateConfig:{//主题模板配置
				},				
				themeTemplateConfig_bak:{//主题模板配置
					id:1,//id
					backImageUrl:"",//背景图地址
					widthConfig:{//页面左中右宽度设置
						left:476,//左边宽度
						center:880,//中间宽度,为0说明为左右模式
						right:476,//右边宽度
						widthUnit:"px",//宽度单位px或%
					},
					topComponentConfig:{//头部组件配置
						isShow:true,//是否显示
						backImageUrl:"",//背景图地址
						width:"100%",//宽度
						text:"标题",//文字
						textColor:"#fff",//颜色
						componentName:"top1",
					},
					mapComponentConfig:{//头部组件配置
						isShow:true,//是否显示
						backImageUrl:"",//背景图地址
						width:"100%",//宽度
						text:"标题",//文字
						componentName:"mapbg",
						data:{//数据
							apiUrl:"",//数据接口地址
							list:[],//数据列表
							params:{
								uuid:"",
								month:"",
								day:"",
							}
						},
					},
					bottomComponentConfig:{//底部组件配置
						isShow:true,//是否显示
						backImageUrl:"",//背景图地址
						width:"100%",//宽度
						text:"标题",//文字
						textColor:"#fff",//文字颜色
						componentName:"bottom1",
						textList:"",
						linkList:[],
					},
					contentComponentConfig:{//内容组件配置
						left:[
							{
								name:"left1",//组件名(用于影响参数组件数据查询)
								backImageUrl:"",//背景图地址
								width:"100%",//宽度
								text:"显示文字",
								componentName:"title1",//组件名
								data:{//数据
									apiUrl:"",//数据接口地址
									list:[],//数据列表
									params:{
										uuid:"",
										month:"",
										day:"",
									}
								},
								type:"title",//组件类型 title:标题,content:内容
								isHaveMore:true,//是否有查看更多
								subText:"DATA DISPLAY",//副标题内容
								isHaveYearSearch:false,//是否有年查询
								isHaveMonthSearch:false,//是否有月查询
								isHaveDaySearch:false,//是否有日查询
								top:0,
								bottom:0,
								disUnit:"px",//距离单位
							},
							{
								name:"left2",//组件名(用于影响参数组件数据查询)
								backImageUrl:"",//背景图地址
								width:"100%",//宽度
								text:"显示文字",
								componentName:"bar1",//组件名
								data:{//数据
									apiUrl:"",//数据接口地址
									list:[],//数据列表
								},
								type:"content",//组件类型 title:标题,content:内容
								isHaveMore:false,//是否有查看更多
								subText:"DATA DISPLAY",//副标题内容
								isHaveYearSearch:false,//是否有年查询
								isHaveMonthSearch:false,//是否有月查询
								isHaveDaySearch:false,//是否有日查询
								top:0,
								bottom:0,
								disUnit:"px",//距离单位
							},	
						],	
						center:[],
						right:[],															
					}
				},
				componentLists:[//组件列表
					{
						title:"组件名称1",
						name:"bar1",
						imageUrl:'',
					},
				],
				updatedComponentIndex:-1,//修改的组件列表的索引
				updatedComponentPosition:"left",//组件位置是在left,center,right
				updatedComponentName:"bar1",//更新的组件名
				templateId:0,//模板ID
				backImageUrl:"",
				l1config: {
					yAxisName: '金额：万', // y轴名称
					list: [ // 数据列表
						{ name: '一期', value: 21 }, // name 横坐标,value: 纵坐标
						{ name: '二期', value: 14 },
						{ name: '三期', value: 12 },
						{ name: '四期', value: 9 },
						{ name: '五期', value: 9.5 },
						{ name: '六期', value: 7 },
					]
				},
				projectId:'',	
				projectName:"",		
				componentApiUrl:"",//组件apiurl	
				columnList:[//栏目列表
					{name:"左栏目",value:"left"},
					{name:"中间栏目",value:"center"},
					{name:"右栏目",value:"right"},
				],
				is_trues:[
					{name:"是",value:true},
					{name:"否",value:false},
				],
				params_uuid:"",//参数uuid
				field_names:"",//echart的多轴列表
				yAxisNames:"",//echart的Ｙ轴名称
				apiList:[],//接口列表
				timeSearchList:[
					{name:"今日",value:"today"},
					{name:"本月",value:"thisMonth"},
					{name:"本年",value:"thisYear"},
					{name:"30天",value:"thirtyDays"},
					{name:"60天",value:"sixtyDays"},
					{name:"90天",value:"ninetyDays"},
				],//日期搜索条件列表
				logVisible:false,//登录框显示
				moreVisible:false,//更多信息弹窗
				loginForm:{
					userName:"10624",
					userPwd:"10624",
				},
				user_token:"",//用户模板登录token
				selectedComponentType:"echart",
				topEditS:false,//头部编辑显示
				mapEditS:false,//地图编辑显示
				templateContentComponentList:[],//模板内容组件列表
				moreColumnList:[],//更多数据的栏目表
				moreDataList:[],//更多数据的数据列表
				moreDialogData:{
					head: [],
					datalist: [],
					headtitle: '机器状态列表',
					type: 'list'
				},//更多数据
				isViewPage:false,//是否viewPage,默认不是
				componentPageSize:8,//组件列表每页数
				componentPageIndex:1,//组件列表当前页
				componentTotal:8,//组件列表总数
				isChangeComponent:false,
			}
		},
		mounted() {
			this.checkIsViewPage();
			this.user_token=localStorage.getItem("token_user");	
			if(!this.user_token){
				this.logVisible=true;
				return;
			}
			this.templateId=this.$route.query.templateId;
			this.projectId=this.$route.query.projectId;
			if (this.templateId) {
				this.templateId=parseInt(this.templateId);
				this.getTemplateDetail();
				
			}else{
				this.getProjectDetail();
				// Message.warning("参数错误");
				// this.$router.push({path:'/'});
			}
			this.getComponentList();
			this.getApiList();
		},
		computed: {
			// 当前页数
			currentPage: {
				get() {
					return this.componentPageIndex;
				},
				set(val) {
					this.$emit('update:page', val);
				}
			},
		},		
		methods: {
			showDateSearch(){
				//console.log("search===="+JSON.stringify(this.themeTemplateConfig.contentComponentConfig[this.updatedComponentPosition][this.updatedComponentIndex].search));
				this.$forceUpdate();
			},
			checkTemplate(item){
				console.log("template=====");
				var str="";
				const url = window.location.href;
				if(url.indexOf("/template")<0){
					if(parseInt(item.bottom)>0){
						str="padding-top:60px;top:-120px;";
					}else{
						str="padding-top:20px;top:-68px;"
					}
					
				}else{
					str="padding-top:0px;top:0px;"
				}
				return str
			},
			//组件列表分页点击事件
			componentPageChange(currentPage){
				this.componentPageIndex=currentPage;
				this.getComponentList();
			},
			//验证是否view用户浏览页面.
			checkIsViewPage(){
				const url = window.location.href;
				if(url.indexOf("/view")>=0){
					this.isViewPage=true;
				}
			},
			//获取当前日期
			getCurrentDay() {
				var date = new Date();
				var year = this.formateTime(date.getFullYear());
				var month = this.formateTime(date.getMonth() + 1);
				var day = this.formateTime(date.getDate());
				var hour = this.formateTime(date.getHours());
				var minute = this.formateTime(date.getMinutes());
				var second = this.formateTime(date.getSeconds());
				var weekday = date.getDay();
				var weeks = new Array(
					"星期日",
					"星期一",
					"星期二",
					"星期三",
					"星期四",
					"星期五",
					"星期六"
				);
				var week = weeks[weekday];
				return (
					year + "-" + month + "-" + day
				);
				// return (
				// 	year + "-" + month + "-" + day + "  " + week + "  " + hour + ":" + minute + ":" + second
				// );
			},
			formateTime(n) {
				if (n < 10) {
					return "0" + n;
				} else {
					return n;
				}
			},
			//加载时获取地图
			getMapDataByLoading(uuid){
				if(!uuid){
					return;
				}
				var data={uuid:uuid,params:{token:this.user_token,page:1,pageSize:10,topN:10,date:this.getCurrentDay(),}};
				getComponentDatas(data).then(res => {
					if(res.code!=200){
						Message.warning(res.msg);
						if(res.code==100001&&res.msg.indexOf("用户登录会话过期")>=0){
							this.logVisible=true;
							localStorage.setItem("token_user","");
							this.user_token="";
						}
						return;
					}
					var tempData=[];
					tempData=res.data.data;
					this.themeTemplateConfig.mapComponentConfig.data.list=tempData;
					this.$forceUpdate();
				});
			},			
			//加载时获取接口地址的数据
			getComponentDataByLoading(uuid,position,index){
				console.log("uuid:"+uuid+"==position:"+position+"==index:"+index)
				if(!uuid){
					return;
				}
				var data={uuid:uuid,params:{token:this.user_token,page:1,pageSize:10,topN:10,date:this.getCurrentDay(),}};
				getComponentDatas(data).then(res => {
					if(res.code!=200){
						Message.warning(res.msg);
						if(res.code==100001&&res.msg.indexOf("用户登录会话过期")>=0){
							this.logVisible=true;
							localStorage.setItem("token_user","");
							this.user_token="";
						}
						return;
					}
					var tempData=[];
					if(this.themeTemplateConfig.contentComponentConfig[position][index].componentName.indexOf("echart")>=0){
						tempData=this.chageDataFormat2(uuid,1,res.data.data);
					}else{
						tempData=res.data.data;
						if(this.themeTemplateConfig.contentComponentConfig[position][index].componentName.indexOf("textlist")>=0){
							this.getDataColumList(res.data.meta.field,position,index);
						}
					}
					
					this.themeTemplateConfig.contentComponentConfig[position][index].data.list=tempData;
					this.isChangeComponent=false;
					console.log("uuid:"+uuid+"==position:"+position+"==index:"+index+"==componentData1111==="+JSON.stringify(this.themeTemplateConfig.contentComponentConfig[position][index].data.list));
					this.$forceUpdate();
				});
			},
			changeMapData(){
				if(!this.themeTemplateConfig.mapComponentConfig.data.params.uuid){
					Message.warning("请选择数据源!");
					return;
				}
				var data={uuid:this.themeTemplateConfig.mapComponentConfig.data.params.uuid,params:{token:this.user_token,page:1,pageSize:10,topN:10,date:this.getCurrentDay(),}}
				getComponentDatas(data).then(res => {
					if(res.code!=200){
						Message.warning(res.msg);
						return;
					}
					//alert(JSON.stringify(res.data.data));
					this.themeTemplateConfig.mapComponentConfig.data.list=res.data.data;
					this.mapEditS=false;
					this.$forceUpdate();
				});
			},
			getTemplateContentComponentList(){//获取模板内容组件列表
				if(this.themeTemplateConfig.mapComponentConfig){
					this.themeTemplateConfig.mapComponentConfig.data.list=[];
					this.getMapDataByLoading(this.themeTemplateConfig.mapComponentConfig.data.params.uuid);
				}
				for(var i=0;i<this.columnList.length;i++){
					for(var j=0;j<this.themeTemplateConfig.contentComponentConfig[this.columnList[i].value].length;j++){
						if(this.themeTemplateConfig.contentComponentConfig[this.columnList[i].value][j].type=="content"){
							this.themeTemplateConfig.contentComponentConfig[this.columnList[i].value][j].data.list=[];
							this.getComponentDataByLoading(this.themeTemplateConfig.contentComponentConfig[this.columnList[i].value][j].data.params.uuid,this.columnList[i].value,j);
							//获取下拉可选择的内容组件列表
							this.templateContentComponentList.push({name:this.themeTemplateConfig.contentComponentConfig[this.columnList[i].value][j].name,value:this.themeTemplateConfig.contentComponentConfig[this.columnList[i].value][j].name});
							//判断是否有日期配置
							if(!this.themeTemplateConfig.contentComponentConfig[this.columnList[i].value][j].search){
								var temp={
									titleMore:{isShow:false,uuid:"",moreUUID:"",params:{}},//标题上的更多
									year:{isShow:false,uuid:"",moreUUID:"",params:{}},
									month:{isShow:false,uuid:"",moreUUID:"",params:{}},
									day:{isShow:false,uuid:"",moreUUID:"",params:{}},
									today:{isShow:false,uuid:"",moreUUID:"",params:{}},
									thisMonth:{isShow:false,uuid:"",moreUUID:"",params:{}},
									thisYear:{isShow:false,uuid:"",moreUUID:"",params:{}},
									thirtyDays:{isShow:false,uuid:"",moreUUID:"",params:{}},
									sixtyDays:{isShow:false,uuid:"",moreUUID:"",params:{}},
									ninetyDays:{isShow:false,uuid:"",moreUUID:"",params:{}},
									contentMore:{isShow:false,uuid:"",moreUUID:"",params:{}},//内容下的更多
									selectedDate:"",//选中的是哪个日期搜索
								}
								this.themeTemplateConfig.contentComponentConfig[this.columnList[i].value][j].search=temp;
							}else{
								if(!this.themeTemplateConfig.contentComponentConfig[this.columnList[i].value][j].search.selectedDate){
									this.themeTemplateConfig.contentComponentConfig[this.columnList[i].value][j].search.selectedDate="";
								}
							}
						}
					}
				}
			},
			SelectComponentData(data){
				var selectedData=[true,"","",""];//索引0为是否查看全部,1为月查询,2为日查询,3为查询的组件.
				selectedData=data;
				if(!selectedData[3]){
					if(selectedData[0]==false){
						Message.warning("要查询的组件还未设置!");
						return;
					}
				}
				//alert(alert(JSON.stringify(selectedData));
				if(selectedData[0]==true){//查看全部时
					var position="";
					var updateIndex=0;
					for(var i=0;i<this.columnList.length;i++){
						for(var j=0;j<this.themeTemplateConfig.contentComponentConfig[this.columnList[i].value].length;j++){
							if(this.themeTemplateConfig.contentComponentConfig[this.columnList[i].value][j].effectedComponent==selectedData[3]){
								//alert("position:"+this.columnList[i].value+"==index:"+j);
								position=this.columnList[i].value;
								updateIndex= j;
								break;
							}
						}
					}
					var uuid=this.themeTemplateConfig.contentComponentConfig[position][updateIndex].moreUUID;
					if(!uuid){
						Message.warning("更多接口参数还未设置!");
						return;
					}
					//var data={uuid:uuid,params:{curDate:selectedData[1],topNums:10}}
					var strDate=!selectedData[1];
					if(!selectedData[1]){
						strDate=this.getCurrentDay();
					}
					var data={uuid:uuid,params:{token:this.user_token,page:1,pageSize:10,topN:10,date:strDate,}};
					getComponentDatas(data).then(res => {
						if(res.code!=200){
							Message.warning(res.msg);
							return;
						}
						var tempData=[];
						tempData=res.data.data;
						this.getMoreDataColumList(res.data.meta.field);
						this.moreVisible=true;
						this.moreDataList=tempData;
						this.moreDialogData.head=this.moreColumnList;
						this.moreDialogData.headtitle=res.data.remark;
						this.moreDialogData.datalist=this.moreDataList;
						//this.themeTemplateConfig.contentComponentConfig[position][updateIndex].data.list=tempData;
					});
				}else{
					var position="";
					var updateIndex=0;
					for(var i=0;i<this.columnList.length;i++){
						for(var j=0;j<this.themeTemplateConfig.contentComponentConfig[this.columnList[i].value].length;j++){
							if(this.themeTemplateConfig.contentComponentConfig[this.columnList[i].value][j].name==selectedData[3]){
								//alert("position:"+this.columnList[i].value+"==index:"+j);
								position=this.columnList[i].value;
								updateIndex= j;
								break;
							}
						}
					}
					var uuid=this.themeTemplateConfig.contentComponentConfig[position][updateIndex].data.params.uuid;
					//alert(uuid);
					//var data={uuid:uuid,params:{curDate:selectedData[1],topNums:6}}
					var data={uuid:uuid,params:{token:this.user_token,page:1,pageSize:10,topN:10,date:selectedData[1],}}
					getComponentDatas(data).then(res => {
						if(res.code!=200){
							Message.warning(res.msg);
							return;
						}
						var tempData=[];
						if(this.themeTemplateConfig.contentComponentConfig[position][updateIndex].componentName.indexOf("echart")>=0){
							tempData=this.chageDataFormat2(uuid,1,res.data.data);
						}else{
							tempData=res.data.data;
						}
						
						this.themeTemplateConfig.contentComponentConfig[position][updateIndex].data.list=tempData;
						this.$forceUpdate();
					});
				}
			},
			//查询组件数据（新）
			SelectComponentDataNew(data){
				var selectedData=["","",true,"","",""];//索引0为为查询的组件,1为类型(more,year),2是否查看全部,3,为年查询，4为月查询,5为日查询.
				selectedData=data;
				if(selectedData[2]==true){//查看全部时
					var position="";
					var updateIndex=0;
					for(var i=0;i<this.columnList.length;i++){
						for(var j=0;j<this.themeTemplateConfig.contentComponentConfig[this.columnList[i].value].length;j++){
							if(this.themeTemplateConfig.contentComponentConfig[this.columnList[i].value][j].name==selectedData[0]){
								//alert("position:"+this.columnList[i].value+"==index:"+j);
								position=this.columnList[i].value;
								updateIndex= j;
								break;
							}
						}
					}
					var uuid=this.themeTemplateConfig.contentComponentConfig[position][updateIndex].moreUUID;
					if(selectedData[1]!="more"){
						if(selectedData[1]=="year"){
							uuid=this.themeTemplateConfig.contentComponentConfig[position][updateIndex].search.year.moreUUID;
						}else if(selectedData[1]=="month"){
							uuid=this.themeTemplateConfig.contentComponentConfig[position][updateIndex].search.month.moreUUID;
						}
					}
					if(!uuid){
						//Message.warning("更多接口参数还未设置!");
						return;
					}
					//alert(uuid);
					//var data={uuid:uuid,params:{curDate:selectedData[1],topNums:10}}
					var strDate=this.getCurrentDay();
					var data={uuid:uuid,params:{token:this.user_token,page:1,pageSize:10,topN:10,date:strDate,}};
					getComponentDatas(data).then(res => {
						if(res.code!=200){
							Message.warning(res.msg);
							return;
						}
						var tempData=[];
						tempData=res.data.data;
						//alert(JSON.stringify(tempData));
						this.getMoreDataColumList(res.data.meta.field);
						this.moreVisible=true;
						this.moreDataList=tempData;
						this.moreDialogData.head=this.moreColumnList;
						this.moreDialogData.headtitle=res.data.remark;
						this.moreDialogData.datalist=this.moreDataList;
						//this.themeTemplateConfig.contentComponentConfig[position][updateIndex].data.list=tempData;
					});
				}else{
					var position="";
					var updateIndex=0;
					for(var i=0;i<this.columnList.length;i++){
						for(var j=0;j<this.themeTemplateConfig.contentComponentConfig[this.columnList[i].value].length;j++){
							if(this.themeTemplateConfig.contentComponentConfig[this.columnList[i].value][j].name==selectedData[0]){
								//alert("position:"+this.columnList[i].value+"==index:"+j);
								position=this.columnList[i].value;
								updateIndex= j;
								break;
							}
						}
					}
					var uuid=this.themeTemplateConfig.contentComponentConfig[position][updateIndex].data.params.uuid;
					//var data={uuid:uuid,params:{curDate:selectedData[1],topNums:6}}
					var strDate="";
					if(selectedData[1]=="year"){
						uuid=this.themeTemplateConfig.contentComponentConfig[position][updateIndex].search.year.uuid;
					}else if(selectedData[1]=="month"){
						uuid=this.themeTemplateConfig.contentComponentConfig[position][updateIndex].search.month.uuid;
					}else if(selectedData[1]=="day"){
						uuid=this.themeTemplateConfig.contentComponentConfig[position][updateIndex].search.day.uuid;
					}
					if(!selectedData[3]){//年份时
						selectedData[3]=2024;
					}
					if(!selectedData[4]){//月份时
						selectedData[4]=1;
					}
					if(!selectedData[5]){//日时
						selectedData[5]=1;
					}
					strDate=selectedData[3]+"-"+this.formateTime(selectedData[4])+"-"+this.formateTime(selectedData[5]);
					if(!uuid){
						//Message.warning("更多接口参数还未设置!");
						return;
					}
					var data={uuid:uuid,params:{token:this.user_token,page:1,pageSize:10,topN:10,date:strDate,}}
					getComponentDatas(data).then(res => {
						if(res.code!=200){
							Message.warning(res.msg);
							return;
						}
						var tempData=[];
						if(this.themeTemplateConfig.contentComponentConfig[position][updateIndex].componentName.indexOf("echart")>=0){
							tempData=this.chageDataFormat2(uuid,1,res.data.data);
						}else{
							tempData=res.data.data;
						}
						
						this.themeTemplateConfig.contentComponentConfig[position][updateIndex].data.list=tempData;
						this.$forceUpdate();
					});
				}
			},		
			SelectComponentListData(data){
				var selectedData=[true,"","",data];//索引0为是否查看全部,1为月查询,2为日查询,3为查询的组件.
				var position="";
				var updateIndex=0;
				for(var i=0;i<this.columnList.length;i++){
					for(var j=0;j<this.themeTemplateConfig.contentComponentConfig[this.columnList[i].value].length;j++){
						if(this.themeTemplateConfig.contentComponentConfig[this.columnList[i].value][j].name==selectedData[3]){
							//alert("position:"+this.columnList[i].value+"==index:"+j);
							position=this.columnList[i].value;
							updateIndex= j;
							break;
						}
					}
				}
				var uuid=this.themeTemplateConfig.contentComponentConfig[position][updateIndex].moreUUID;
				if(!uuid){
					Message.warning("更多接口参数还未设置!");
					return;
				}
				//var data={uuid:uuid,params:{curDate:selectedData[1],topNums:10}}
				var data={uuid:uuid,params:{token:this.user_token,page:1,pageSize:10,topN:10,date:this.getCurrentDay(),}};
				getComponentDatas(data).then(res => {
					if(res.code!=200){
						Message.warning(res.msg);
						return;
					}
					var tempData=[];
					tempData=res.data.data;
					this.getMoreDataColumList(res.data.meta.field);
					this.moreVisible=true;
					this.moreDataList=tempData;
					this.moreDialogData.head=this.moreColumnList;
					this.moreDialogData.headtitle=res.data.remark;
					this.moreDialogData.datalist=this.moreDataList;
					//this.themeTemplateConfig.contentComponentConfig[position][updateIndex].data.list=tempData;
				});
			},							
			delBottomLink(index){
				this.themeTemplateConfig.bottomComponentConfig.linkList.splice(index);
				//alert(JSON.stringify(this.themeTemplateConfig.bottomComponentConfig.linkList));
			},
			//添加底部链接
			addBottomLink(){
				if(!this.themeTemplateConfig.bottomComponentConfig.linkList){
					this.themeTemplateConfig.bottomComponentConfig.linkList=[];
				}
				var temp={name:"",url:""};
				this.themeTemplateConfig.bottomComponentConfig.linkList.push(temp);
				//alert(JSON.stringify(this.themeTemplateConfig.bottomComponentConfig.linkList));
				this.$forceUpdate();
			},
			userLogin(){
				if(!this.loginForm.userName){
					Message.warning("请填写登录名!");
					return;
				}
				if(!this.loginForm.userPwd){
					Message.warning("请填写登录密码!");
					return;
				}
				var data={uuid:"login",params:{username:this.loginForm.userName,password:this.loginForm.userPwd}};
				getComponentDatas(data).then(res => {
					if(res.code!=200){
						Message.warning(res.msg);
						return;
					}
					//alert(JSON.stringify(res));
					if(res.data.data.value0){
						if(res.data.data.value0.indexOf("登录")<0){
							Message.success("登录成功!");
							localStorage.setItem("token_user",res.data.data.value0);
							window.location.reload();
						}else{
							Message.warning(res.data.data.value0);
						}
					}
				});
			},
			getApiList(){
				var data={projectId:1}
				getApiListData(data).then(res => {
					if(res.code!=200){
						Message.warning(res.msg);
						return;
					}
					var tempList=res.data.list;
					this.apiList=res.data.list;
					return;
					data={projectId:2}
					getApiListData(data).then(res => {
						if(res.code!=200){
							Message.warning(res.msg);
							return;
						}
						var tempList2=res.data.list;
						//this.apiList=tempList.concat(tempList2);
						//console.log("this.apiList===="+JSON.stringify(this.apiList));
					});
					//console.log("this.apiList===="+JSON.stringify(this.apiList));
				});
			},				
			getApiList_bak(){
				var data={projectId:1}
				getApiListData(data).then(res => {
					if(res.code!=200){
						Message.warning(res.msg);
						return;
					}
					this.apiList=res.data.list;
					//console.log("this.apiList===="+JSON.stringify(this.apiList));
				});
			},			
			chageDataFormat(type,data){
				var tempList=[];
				for(var i=0;i<data.length;i++){
					if(parseInt(this.params_uuid)==26||parseInt(this.params_uuid)==13||parseInt(this.params_uuid)==12){
						tempList.push({name:data[i].value0+"-"+data[i].value1,value:data[i].value2,value2:data[i].value3});
					}else{
						tempList.push({name:data[i].value0,value:data[i].value1});
					}
					
				}
				return tempList;
			},
			chageDataFormat2(uuid,type,data){
				var tempList=[];
				for(var i=0;i<data.length;i++){
					if(parseInt(uuid)==26||parseInt(uuid)==13||parseInt(uuid)==12){
						tempList.push({name:data[i].value0+"-"+data[i].value1,value:data[i].value2,value2:data[i].value3});
					}else{
						tempList.push({name:data[i].value0,value:data[i].value1});
					}
					
				}
				return tempList;
			},
			//获取接口数据的列名
			getDataColumList(data,position,index){
				console.log("columnData===="+JSON.stringify(data));
				var len=Object.keys(data).length;
				var name="";
				for(var i=0;i<len;i++){
					name=name+data["value"+i].name;
					if(i<len-1){
						name=name+",";
					}
				}
				this.themeTemplateConfig.contentComponentConfig[position][index].data.names=name;
			},
			//获取更多接口数据的列名
			getMoreDataColumList(data){
				this.moreColumnList=[];
				var len=Object.keys(data).length;
				var name="";
				for(var i=0;i<len;i++){
					if(data["value"+i]){
						this.moreColumnList.push(data["value"+i].name);
					}
				}
			},
			showTitleEdit(position,index){
				this.updatedComponentIndex=index;
				this.updatedComponentPosition=position;				
				//alert(JSON.stringify(this.themeTemplateConfig.contentComponentConfig[this.updatedComponentPosition][this.updatedComponentIndex]));
				this.titleEditS=true;
			},
			//显示头部编辑
			showTopEdit(){
				this.topEditS=true;
			},	
			//显示地图编辑
			showMapEdit(){
				this.mapEditS=true;
			},
			//验证定位,如果top或bottom大于0,为绝对定位
			checkPosition(item){
				var str="width:"+item.width+";";
				// if(item.type=="content"){
				// 	str+="z-index:1;";
				// }
				if(parseInt(item.top)>0||parseInt(item.bottom)>0){
					str=str+"position:absolute;";
				}
				if(parseInt(item.top)>0){
					str=str+"top:"+item.top+item.disUnit+";";
				}
				if(parseInt(item.bottom)>0){
					str=str+"bottom:"+item.bottom+item.disUnit+";";
				}
				if(parseInt(item.left)==-1){
					str=str+"right:"+item.right+item.disUnit+";";
				}				
				console.log("44===="+str);
				return str;
			},
			changeComponentData(){
				this.themeTemplateConfig.contentComponentConfig[this.updatedComponentPosition][this.updatedComponentIndex].data.yAxisNames=this.yAxisNames;
				this.themeTemplateConfig.contentComponentConfig[this.updatedComponentPosition][this.updatedComponentIndex].data.names=this.field_names;
				this.themeTemplateConfig.contentComponentConfig[this.updatedComponentPosition][this.updatedComponentIndex].data.params.uuid=this.params_uuid;
				var data={uuid:this.params_uuid,params:{token:this.user_token,page:1,pageSize:10,topN:10,date:this.getCurrentDay(),}}
				getComponentDatas(data).then(res => {
					if(res.code!=200){
						Message.warning(res.msg);
						return;
					}
					var tempData=[];
					if(this.themeTemplateConfig.contentComponentConfig[this.updatedComponentPosition][this.updatedComponentIndex].componentName.indexOf("echart")>=0){
						tempData=this.chageDataFormat(1,res.data.data);
					}else{
						tempData=res.data.data;
						if(this.themeTemplateConfig.contentComponentConfig[this.updatedComponentPosition][this.updatedComponentIndex].componentName.indexOf("textlist")>=0){
							this.getDataColumList(res.data.meta.field,this.updatedComponentPosition,this.updatedComponentIndex);
						}
					}
					
					this.themeTemplateConfig.contentComponentConfig[this.updatedComponentPosition][this.updatedComponentIndex].data.list=tempData;
					//alert(JSON.stringify(this.themeTemplateConfig.contentComponentConfig[this.updatedComponentPosition][this.updatedComponentIndex].data.list));
					console.log("componentData==="+JSON.stringify(tempData));
					this.isChangeComponent=false;
					this.dataEditS=false;
					this.$forceUpdate();
				});
			},
			openComponentDataEdit(position,index){
				this.dataEditS=true;
				this.updatedComponentPosition=position;
				this.updatedComponentIndex=index;
				this.params_uuid=this.themeTemplateConfig.contentComponentConfig[position][index].data.params.uuid;
				this.field_names=this.themeTemplateConfig.contentComponentConfig[position][index].data.names;
				this.yAxisNames=this.themeTemplateConfig.contentComponentConfig[position][index].data.yAxisNames;
				//alert(this.themeTemplateConfig.contentComponentConfig[position][index].data.list);
				if(this.themeTemplateConfig.contentComponentConfig[position][index].componentName.indexOf("echart")>=0){
					this.selectedComponentType="echart"
				}else if(this.themeTemplateConfig.contentComponentConfig[position][index].componentName.indexOf("textlist")>=0){
					this.selectedComponentType="textlist"
				}else if(this.themeTemplateConfig.contentComponentConfig[position][index].componentName.indexOf("piclist")>=0){
					this.selectedComponentType="piclist"
				}
			},
			openTitleEdit(position,index){
				this.dataEditS=true;
				this.updatedComponentPosition=position;
				this.updatedComponentIndex=index;
				this.componentApiUrl=this.themeTemplateConfig.contentComponentConfig[position][index].data.apiUrl;
				//alert(this.themeTemplateConfig.contentComponentConfig[position][index].data.list);
			},
			goView(){
				var url="/#/view?projectId="+this.projectId;
				window.open(url);
			},
			goTemplate(){
				var url="/#/template?projectId="+this.projectId;
				window.open(url);
			},
			//模板详情
			getProjectDetail(){
				var _this = this;
				var params={id:parseInt(this.projectId)}
				getProjectDetailData(params).then(res => {
					if(res.code!=200){
						Message.warning(res.msg);
						return;
					}
					if(res.data){
						if(res.data.row.config.backImageUrl){
							_this.themeTemplateConfig=res.data.row.config;
							if(!_this.themeTemplateConfig.mapComponentConfig){
								_this.themeTemplateConfig.mapComponentConfig=
								{//头部组件配置
									isShow:true,//是否显示
									backImageUrl:"",//背景图地址
									width:"100%",//宽度
									text:"标题",//文字
									componentName:"mapbg",
									data:{//数据
										apiUrl:"",//数据接口地址
										list:[],//数据列表
										params:{
											uuid:"",
											month:"",
											day:"",
										}
									}
								};
							}
							_this.getTemplateContentComponentList();
					// var temp={
                    //         "top": 0,
                    //         "data": {
                    //             "list": [],
                    //             "names": "",
                    //             "apiUrl": "",
                    //             "params": {
                    //                 "day": "",
                    //                 "uuid": "",
                    //                 "month": ""
                    //             }
                    //         },
                    //         "name": "center2",
                    //         "text": "显示文字",
                    //         "type": "content",
                    //         "width": "100%",
                    //         "bottom": 0,
                    //         "disUnit": "px",
                    //         "subText": "DATA DISPLAY",
                    //         "isHaveMore": true,
                    //         "backImageUrl": "",
                    //         "componentName": "maptips1",
                    //         "isHaveDaySearch": false,
                    //         "isHaveMonthSearch": false
                    //     };
					// 		_this.themeTemplateConfig.contentComponentConfig["center"].push(temp);
						}else{
							_this.getTemplateDetail(res.data.row.templateId);
						}
						_this.projectName=res.data.row.name;
						_this.templateId=res.data.row.templateId;
						_this.backImageUrl=_this.themeTemplateConfig.backImageUrl;
						
					}
				});
			},	
			saveProject(){
				if(this.projectId>0){
					this.editProject();
				}
			},
			editProject(){
				if(!this.projectName){
					Message.warning("项目名称不能为空!");
					return;
				}
				if(this.templateId==0||this.templateId==""){
					Message.warning("模板ID不能为空!");
					return;
				}				
				var _this = this;
				this.themeTemplateConfig.id=this.templateId;
				var data={
					id:parseInt(this.projectId),
					name:this.projectName,
					templateId:parseInt(this.templateId),
					remark:"",
					config:this.themeTemplateConfig,
				}
				editProjectData(data).then(res => {
					if(res.code!=200){
						Message.warning(res.msg);
						return;
					}
					console.log("add_res2222===="+JSON.stringify(res));
					if(res.data){
						Message.success("修改成功!");
						return;
						setTimeout(() => {
							this.$router.push({path:'/'});
						}, 2000)
						//this.$router.push({path:'/edit',query:{templateId:this.templateId,projectId:this.formData.title}});
					}
				});
			},
			//组件详情
			getComponentDetail(id){
				var _this = this;
				var params={id:id}
				getComponentDetailData(params).then(res => {
					if(res.code!=200){
						Message.warning(res.msg);
						return;
					}
					if(res.data){
						_this.themeTemplateConfig.contentComponentConfig[this.updatedComponentPosition][this.updatedComponentIndex].componentName=res.data.row.config.componentName;
						_this.themeTemplateConfig.contentComponentConfig[this.updatedComponentPosition][this.updatedComponentIndex].data.list=[];
						_this.themeTemplateConfig.contentComponentConfig[this.updatedComponentPosition][this.updatedComponentIndex].data.params.uuid="";
						_this.themeTemplateConfig.contentComponentConfig[this.updatedComponentPosition][this.updatedComponentIndex].data.names="";
						if(_this.updatedComponentName!=res.data.row.config.componentName){
							_this.params_uuid="";
							_this.field_names="";
							_this.yAxisNames="";
						}
						_this.isChangeComponent=true;
						_this.componentS=false;
						_this.$forceUpdate();	
					}
				});
			},
			//模板详情
			getTemplateDetail(tempId){
				var _this = this;
				var params={id:tempId}
				getTemplateDetailData(params).then(res => {
					if(res.code!=200){
						Message.warning(res.msg);
						return;
					}
					if(res.data){
						_this.themeTemplateConfig=res.data.row.config;
						_this.backImageUrl=_this.themeTemplateConfig.backImageUrl;
						_this.getTemplateContentComponentList();
						_this.themeTemplateConfig.bottomComponentConfig.linkList=[];
						if(!_this.themeTemplateConfig.mapComponentConfig){
							_this.themeTemplateConfig.mapComponentConfig=
							{//头部组件配置
								isShow:true,//是否显示
								backImageUrl:"",//背景图地址
								width:"100%",//宽度
								text:"标题",//文字
								componentName:"mapbg",
								data:{//数据
									apiUrl:"",//数据接口地址
									list:[],//数据列表
									params:{
										uuid:"",
										month:"",
										day:"",
									}
								}
							};
						}
					}
				});
			},			
			//组件列表
			getComponentList(){
				var _this = this;
				var params={page:this.componentPageIndex,pageSize:this.componentPageSize}
				getComponentListData(params).then(res => {
					if(res.code!=200){
						Message.warning(res.msg);
						return;
					}
					if(res.data){
						_this.componentLists=res.data.list;
						_this.componentTotal=res.data.total;
					}
				});
			},			
			//修改组件
			changeComponent(item){
				this.getComponentDetail(item.id);
			},
			showEdit(id,showType){
				if(showType==-1){
					this.isShow1=false;
				}else{
					this.isShow1=true;
				}
			},
			//显示组件列表
			showComponents(position,index,componentName){
				this.updatedComponentIndex=index;
				this.updatedComponentPosition=position;
				this.updatedComponentName=componentName;
				this.componentS = true;
			},
			componentClose(){
				this.componentS = false
			},
			model() {
				this.leftmove = "7%"
				this.componentclass = "componentclass componentclassSelected"
				this.componentclass1 = "componentclass1 componentclassSelect"
				this.componentclass2 = "componentclass1 componentclassSelect"
				this.componentclass3 = "componentclass1 componentclassSelect"
				this.componentclass4 = "componentclass1 componentclassSelect"
				this.componentclass5 = "componentclass1 componentclassSelect"
				this.componentclass6 = "componentclass1 componentclassSelect"
				this.componentclass7 = "componentclass1 componentclassSelect"
			},
			model1() {
				this.leftmove = "15%"
				this.componentclass = "componentclass componentclassSelect"
				this.componentclass1 = "componentclass1 componentclassSelected"
				this.componentclass2 = "componentclass1 componentclassSelect"
				this.componentclass3 = "componentclass1 componentclassSelect"
				this.componentclass4 = "componentclass1 componentclassSelect"
				this.componentclass5 = "componentclass1 componentclassSelect"
				this.componentclass6 = "componentclass1 componentclassSelect"
				this.componentclass7 = "componentclass1 componentclassSelect"
			},
			model2() {
				this.leftmove = "23%"
				this.componentclass = "componentclass componentclassSelect"
				this.componentclass1 = "componentclass1 componentclassSelect"
				this.componentclass2 = "componentclass1 componentclassSelected"
				this.componentclass3 = "componentclass1 componentclassSelect"
				this.componentclass4 = "componentclass1 componentclassSelect"
				this.componentclass5 = "componentclass1 componentclassSelect"
				this.componentclass6 = "componentclass1 componentclassSelect"
				this.componentclass7 = "componentclass1 componentclassSelect"
			},
			model3() {
				this.leftmove = "31%"
				this.componentclass = "componentclass componentclassSelect"
				this.componentclass1 = "componentclass1 componentclassSelect"
				this.componentclass2 = "componentclass1 componentclassSelect"
				this.componentclass3 = "componentclass1 componentclassSelected"
				this.componentclass4 = "componentclass1 componentclassSelect"
				this.componentclass5 = "componentclass1 componentclassSelect"
				this.componentclass6 = "componentclass1 componentclassSelect"
				this.componentclass7 = "componentclass1 componentclassSelect"
			},
			model4() {
				this.leftmove = "39%"
				this.componentclass = "componentclass componentclassSelect"
				this.componentclass1 = "componentclass1 componentclassSelect"
				this.componentclass2 = "componentclass1 componentclassSelect"
				this.componentclass3 = "componentclass1 componentclassSelect"
				this.componentclass4 = "componentclass1 componentclassSelected"
				this.componentclass5 = "componentclass1 componentclassSelect"
				this.componentclass6 = "componentclass1 componentclassSelect"
				this.componentclass7 = "componentclass1 componentclassSelect"
			},
			model5() {
				this.leftmove = "47%"
				this.componentclass = "componentclass componentclassSelect"
				this.componentclass1 = "componentclass1 componentclassSelect"
				this.componentclass2 = "componentclass1 componentclassSelect"
				this.componentclass3 = "componentclass1 componentclassSelect"
				this.componentclass4 = "componentclass1 componentclassSelect"
				this.componentclass5 = "componentclass1 componentclassSelected"
				this.componentclass6 = "componentclass1 componentclassSelect"
				this.componentclass7 = "componentclass1 componentclassSelect"
			},
			model6() {
				this.leftmove = "55%"
				this.componentclass = "componentclass componentclassSelect"
				this.componentclass1 = "componentclass1 componentclassSelect"
				this.componentclass2 = "componentclass1 componentclassSelect"
				this.componentclass3 = "componentclass1 componentclassSelect"
				this.componentclass4 = "componentclass1 componentclassSelect"
				this.componentclass5 = "componentclass1 componentclassSelect"
				this.componentclass6 = "componentclass1 componentclassSelected"
				this.componentclass7 = "componentclass1 componentclassSelect"
			},
			model7() {
				this.leftmove = "63%"
				this.componentclass = "componentclass componentclassSelect"
				this.componentclass1 = "componentclass1 componentclassSelect"
				this.componentclass2 = "componentclass1 componentclassSelect"
				this.componentclass3 = "componentclass1 componentclassSelect"
				this.componentclass4 = "componentclass1 componentclassSelect"
				this.componentclass5 = "componentclass1 componentclassSelect"
				this.componentclass6 = "componentclass1 componentclassSelect"
				this.componentclass7 = "componentclass1 componentclassSelected"
			},
			screen() {
				// 如果不允许进入全屏，发出不允许提示
				if (!screenfull.enabled) {
					return false
				}
				screenfull.toggle()
			},
			menulow() {
				if (this.menu == 'editMenu') {
					this.menu = 'editMenu MenuReturn'
					this.menulist = false
					this.low = 'rotateY(180deg)'
				} else if (this.menu == 'editMenu MenuReturn') {
					this.menu = 'editMenu'
					this.low = 'rotateY(0deg)'
					setTimeout(() => {
						this.menulist = true
					}, 900)

				}

				return
			},
			editout() {
				this.screen()
				this.$router.push('/index')
			}
		}
	}
</script>

<style scoped>
	.classLine {
		width: 56px;
		height: 2px;
		background: #0a80fa;
		position: absolute;
		bottom: 0;
		transition: all 1s ease;
	}

	.componentclass {
		font-size: 14px;
		margin: 0 0 0 7%;
		font-family: 'Hei';
		;
		width: 5%;
		text-align: center;
	}

	.componentclass1 {
		font-size: 14px;
		margin: 0 0 0 3%;
		font-family: 'Hei';
		width: 5%;
		text-align: center;
	}

	.componentclassSelected {
		color: #eee;
	}

	.componentclassSelect {
		color: #737373;
	}

	.componentS {
		position: absolute;
		width: 60%;
		height: 60vh;
		background: #181a1b;
		border: 1px solid #3a3b3c;
		top: 20vh;
		left: 20%;
		transition: all 1s ease;
		z-index:999999;
	}

	.componentS .componentTitle {
		width: calc(100%-2px);
		height: 45px;
		background: #121212;
		display: flex;
		justify-content: space-between;
		font-family: 'Hei';
		line-height: 45px;
	}
	.componentS .componentTitleName{
		font-size: 14px;color: #c0c0c0;margin-left: 30px;
	}
	.componentS .componentclose{
		width: 17px;margin-right: 30px;
	}
	.componentSMenu{
		width: calc(100%-2px);height: 50px;border-bottom: 1px solid rgba(58,58,58,.4);display: flex;position: relative;line-height: 50px;
	}
	.componentClassAll{
		width: calc(100%-2px);margin:0 0 0 7%;display: flex;
	}
	.componentClassMenuSelect{
		color: #737373;font-size: 14px;margin:20px 0 0 0;background: #0a80fa;padding: 5px 12px;color: #fff;border-radius:5px;font-family: 'Hei';
	}
	.componentClassMenu{
		color: #737373;font-size: 14px;margin:25px 0 0 4%;
	}
	.componentListAll{
		width: 86%;margin:25px auto;display: flex;flex-wrap: wrap;
	}
	.componentList{
		width: 23%;margin-right: 2%;margin-bottom: 20px;animation: list 1s linear 1; animation-fill-mode: forwards;opacity: 0;
	}
	.componentList:hover{
		border: 1px solid #3783f4;
		box-shadow: 0 0 15px #1963ea;
		width: calc(23% - 2px);
		margin-bottom: 18px;
		border-radius: 5px;
	}
	@keyframes list {
		from{
			opacity: 0;
		}
		to{
			opacity: 1;
		}
	}
	.componentPic{
		width: 100%;height: calc(100vw * 0.6 * 0.9 * 0.23 * 0.5625);background: #1f1e1f;border-top-left-radius: 5px;border-top-right-radius: 5px;
	}
	.componentPic img{
		width: 100%;border-top-left-radius: 5px;border-top-right-radius: 5px;height:100%
	}
	.componentPicName{
		width: 100%;height:40px;background: #2d2d33;border-bottom-left-radius: 5px;border-bottom-right-radius: 5px;line-height: 40px;color:#fff;font-size: 12px;text-indent: 1em;letter-spacing: 1px;font-family: 'Hei';
	}
	.scale-wrap {
		width: 1920px;
		height: 1080px;
		overflow: hidden;
		background: #131313;
		font-family: 'Hei';
	}

	.editFrame {
		width: 100%;
		height: 100%;
		position: relative;
	}

	.editMenu {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 60px;
		background: rgba(24, 26, 27, .4);
		backdrop-filter: blur(5px);
		border-top: 1px solid #484848;
		z-index: 999;
		display: flex;
		line-height: 60px;
		transition: all 1s ease;
	}

	.editMenu .return {
		width: 12px;
		margin-left: 1.2vw;
		transition: all 1s ease;
	}

	.editMenu .return button {
		background: transparent;
		border: none
	}

	.MenuReturn {
		width: 3% !important;
		opacity: .5;
		border: none;
		height: 60px;

		transition: all 1s ease;
	}

	.menulist {
		display: flex;
		width: 100%;
		transition: all 1s ease;
		opacity: 1;
	}

	.menuline1 {
		width: 1px;
		height: 30px;
		background: #313131;
		margin: 15px 0 0 1.5%
	}

	.menuline2 {
		width: 1px;
		height: 30px;
		background: #313131;
		margin: 15px 0 0 2%
	}

	.menulogo {
		width: 62px;
		margin: -1px 0 0 1.5%
	}

	.menuname {
		color: #cbcbcb;
		font-size: 16px;
		margin: 0 0 0 1.5%;
	}

	.menuediticon {
		width: 17px;
		margin: 0 0 0 0.5%;
		background: transparent;
		border: none;
	}

	.menuversion {
		font-size: 13px;
		color: #cbcbcb;
		border: 1px solid #313131;
		height: 20px;
		line-height: 20px;
		letter-spacing: -1px;
		padding: 0 7px;
		margin: 20px 0 0 1.5%
	}

	.modelediticon {
		width: 15px;
		margin: -1px 0 0 28%;
	}

	.iconname {
		color: #cbcbcb;
		font-size: 15px;
		margin: -1px 0 0 8px;
		font-weight: 550;
		background: transparent;
		border: none
	}

	.modelicon2 {
		width: 15px;
		margin: -1px 0 0 2%;
	}

	.modelicon3 {
		width: 15px;
		margin: -2px 0 0 1.5%;
	}

	.component {
		position: absolute;
		width: 437px;

		background: rgba(0, 0, 0, .5);
		display:none;
		z-index:99;
	}

	.component_date {
		position: absolute;
		width: 100%;
		z-index:98;
		/* padding-top:60px;
		top:-105px; */
	}	

	.component .componentFrame {
		width: 90%;
		display: flex;
		justify-content: space-between;
		margin: 22% 0 0 5%;
	}

	.component .componentMenu {
		width: 31%;
		height: 45px;
		background: #34383d;
		border-radius: 25px;
		text-align: center;
		line-height: 45px;
		transition: all 1s ease;
		font-size: 14px;
		display: flex;
		justify-content: center;
		border: none;
		color: #fff;
		font-family: 'Hei';
		cursor: pointer;
	}

	.component .componentMenu:hover {
		background: #3da6ff;
	}

	.component .componentIcon {
		width: 15px;
		margin: -1px 8px 0 0
	}

	.echart_component {
		width: 100%;
		z-index:500;
	}
	.echart_component .component {
		position: absolute;

		display:none;
		width: 100%;
		top:0px;
		left:0px;
		height:100%;
		background: rgba(0, 0, 0, .5);
		z-index:99;
	}	
	.echart_component:hover{
		cursor: pointer;
	}
	.echart_component:hover .component{
		display:block;
		z-index:999;
	}	
</style>
<style>
	.mark {
		background-size: 100% 100%;
		position: absolute;
		width: 1920px;
		height: 1080px;
		z-index: 401;
		pointer-events: none;
	}
	.tops{
		width:100%;
		display:block;
	}	
	.content {
		display: flex;
		height: calc(100% - 10px);
		margin-top: 10px;
		z-index: 1;
		position: relative;
		z-index: 402;
		.left {
			margin-left: 34px;
			width: 444px;
		}
		.center {
			width: 924px;
			position: relative;
			margin:0 20px;
		}
		.right {
			margin-right: 34px;
			width: 444px;
		}
	}
	.sb-select {
		height: 24px;
		width: 80px;
		z-index: 1;
		pointer-events: auto;
		&.month-select {
			background: url(@/assets/template_1/select-bg.png) top right no-repeat;
			background-size: 100% 100%;
		}
		&.day-select {
			margin-left: 8px;
			background:red;
			background: url(@/assets/template_1/select-bg1.png) no-repeat;
			background-size: 100% 100%;
			padding-right:0px;
		}
		.el-input--suffix {
			height: 24px;
		}
		.el-input__inner {
			font-size: 12px;
			border: 0;
			background: transparent;
			width: 80px;
			height: 24px;
			line-height: 24px;
			padding-right: 45px !important;
			color: #fff;
		}
		.el-input__icon {
			line-height: 24px;
			font-size: 10px;
		}
		.el-input__suffix {
		}
	}
	.componentContents{
		width:92%;
		margin:20px auto;
		overflow:hidden;
	}
	.componentContents .single{
		overflow:hidden;
		font-size:14px;
		width:100%;
		display:flex;
		margin-bottom:20px;
	}	
	.componentContents .s_single{
		width:48%;
		padding-left:2%;
		display:flex;
	}	
	.componentContents .s_single .l_txt2{
		width:28%;
		padding-right:2%;
		text-align:right;
		line-height:40px;
	}			
	.componentContents .single_btn{
		overflow:hidden;
		font-size:14px;
		width:100%;
		margin-bottom:20px;
		text-align:center;
	}	
	.componentContents .single_tip{
		overflow:hidden;
		font-size:14px;
		line-height:40px;
		width:100%;
		margin-bottom:20px;
		display:flex;
	}	
	.componentContents .single_tip .l_txt{
		width:15%;
		padding-right:2%;
		text-align:right;
		line-height:40px;
	}		
	.componentContents .single .l_txt{
		width:15%;
		padding-right:2%;
		text-align:right;
		line-height:40px;
	}
	.single_row{display:block;width:100%;z-index:2}

	/*	更多样式 */
	.flex-cc2 {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
	}
	.more-dialog {
		margin: 0 !important;
		height: 100%;
		background: transparent !important;
		backdrop-filter: blur(20px);
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.more-title {
		font-family: PangMenZhengDao;
		font-weight: 600;
		font-size: 54px;
		color: #FFFFFF;
		text-align: center;
		background: linear-gradient(0deg, #179CFE 0%, #FFFFFF 100%);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
	}
	.more-rectangle {
		width: 880px;
		height: 543px;
		text-align: center;
		background: url(../../assets/log-rectangle.png) no-repeat;
		background-size: 100% 100%;
	}
	.more-name {
		font-family: Source Han Sans CN;
		font-weight: 400;
		font-size: 39px;
		color: #FFFFFF;
		padding-top: 68px;
	}
	.lock-img {
		width: 22px;
		height: 25px;
		margin-left: 36px;
	}
	.more-content .el-input {
		margin-top: 36px;
		background: rgba(24,68,105,0.61);
		border: 1px solid rgba(0,0,0,0.3);
		border-radius: 3px;
		width: 557px !important;
		height: 68px;
		line-height: 68px;
		font-family: Source Han Sans CN;
		font-weight: 400;
		font-size: 21px;
		color: #AAACB9;
		display: block;
	}
	.more-content .el-input+.el-input {
		margin-top: 18px;
	}
	.more-content .el-input .el-input__inner {
		padding-left: 100px;
	}
	.more-button {
		margin: 42px auto 0 !important;
		width: 557px;
		height: 68px;
		font-family: Source Han Sans CN;
		font-weight: 400;
		font-size: 24px !important;
		color: #FFFFFF;
		background: linear-gradient(0deg, #128DFF 0%, #1ABCFF 100%) !important;
		border-radius: 3px !important;
		display: flex;
		align-items: center;
		justify-content: center;
	}	

	/*	登录样式 */
	.flex-cc {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
	}
	.log-dialog {
		margin: 0 !important;
		height: 100%;
		background: transparent !important;
		backdrop-filter: blur(20px);
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.log-title {
		font-family: PangMenZhengDao;
		font-weight: 600;
		font-size: 54px;
		color: #FFFFFF;
		text-align: center;
		background: linear-gradient(0deg, #179CFE 0%, #FFFFFF 100%);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
	}
	.log-rectangle {
		width: 880px;
		height: 543px;
		text-align: center;
		background: url(../../assets/log-rectangle.png) no-repeat;
		background-size: 100% 100%;
	}
	.log-name {
		font-family: Source Han Sans CN;
		font-weight: 400;
		font-size: 39px;
		color: #FFFFFF;
		padding-top: 68px;
	}
	.lock-img {
		width: 22px;
		height: 25px;
		margin-left: 36px;
	}
	.log-content .el-input {
		margin-top: 36px;
		background: rgba(24,68,105,0.61);
		border: 1px solid rgba(0,0,0,0.3);
		border-radius: 3px;
		width: 557px !important;
		height: 68px;
		line-height: 68px;
		font-family: Source Han Sans CN;
		font-weight: 400;
		font-size: 21px;
		color: #AAACB9;
		display: block;
	}
	.log-content .el-input+.el-input {
		margin-top: 18px;
	}
	.log-content .el-input .el-input__inner {
		padding-left: 100px;
	}
	.log-button {
		margin: 42px auto 0 !important;
		width: 557px;
		height: 68px;
		font-family: Source Han Sans CN;
		font-weight: 400;
		font-size: 24px !important;
		color: #FFFFFF;
		background: linear-gradient(0deg, #128DFF 0%, #1ABCFF 100%) !important;
		border-radius: 3px !important;
		display: flex;
		align-items: center;
		justify-content: center;
	}	
	.more_table{width:80%;margin:0 auto;display:block;}
    .page_bottom {
        margin-top: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
		width:100%;
        >span {
            font-family: Source Han Sans CN;
            font-weight: 400;
            font-size: 14px;
            color: #90D4FF;
            line-height: 14px;
        }
    }	
</style>
