<template>
    <div class="r3">
        <div class="name-box" v-if="chartname">
            <div class="icoL"><img src="@/assets/template_1/chart-left.png"></div>
            <div class="icoR"><img src="@/assets/template_1/chart-right.png"></div>
            <div class="yjName">{{ chartname }}</div>
        </div>
        <div ref="lchart" class="lchart"></div>
    </div>
</template>

<script>
export default {
    props: {
        dataList: {
            type: Array,
            default() {
				return [];
			},
        },
        names: {
            type: String,
            default() {
				return "";
			},
        },        
        lineOption: {
            type: Object,
            default: () => {}
        },
        chartname: {
            type: String,
            default: ''
        },
        yAxisNames: {
            type: String,
            default() {
				return "台";
			},
        }, 
        isChangeComponent: {
            type:Boolean,
            default() {
				return false;
			},
        },         
    },
    data() {
        return {
            defaultValue:[
                { value: 80,value2:20,value3:10,value4: 30, name: '1月' },
                { value: 70,value2:50,value3:60,value4: 50, name: '2月' },
                { value: 20,value2:80,value3:40,value4: 20, name: '3月' },
                { value: 30,value2:50,value3:50,value4: 60, name: '4月' },
                { value: 70,value2:20,value3:20,value4: 10, name: '5月' },
            ],
            defaultValue2:"台",
            defaultValue3:"2024年销售,2024年租赁,2023年销售,2023年租赁",
            yColumnList:['2024年销售','2024年租赁','2023年销售','2023年租赁'],
            isViewPage:false,//是否浏览页面
        }
    },
    watch: {
        dataList(newValue, oldValue) {
            //alert(this.isChangeComponent);
            if(this.dataList.length==0&&this.isChangeComponent==true){
                this.dataList=this.defaultValue; 
                this.drawLine();
            }
            if(this.dataList.length==0&&this.isChangeComponent==false){
                this.drawLine();
            }
            if(this.dataList!=this.defaultValue){
                console.log("555====");
                this.drawLine();
            }
            console.log("this.dataList==="+JSON.stringify(this.dataList));
            // if(newValue!=oldValue){
            //      this.drawPie();
            // }
        },
    },
    mounted() {
        this.checkIsViewPage();
        if(this.dataList.length==0&&this.isChangeComponent==true){
            this.dataList=this.defaultValue; 
        }
        if(!this.names){
            this.names=this.defaultValue3;
            this.getYColumnList(this.names);
        }else{
            this.getYColumnList(this.names);
        }    
        this.$nextTick(() => {
            this.drawLine()
        })
    },
    methods: {
        getYColumnList(name){
            if(name){
                this.yColumnList=name.split(',');
            }
        },        
        checkIsViewPage(){
            const url = window.location.href;
            if(url.indexOf("/view")>=0){
                this.isViewPage=true;
            }
        },           
        drawLine() {  
            var echarts = require('echarts')
            var mychart = echarts.init(this.$refs.lchart);      
            if(this.dataList.length==0){
                mychart.clear();
                return;
            }
            var nameList=[];
            var valueList1=[];
            var valueList2=[];
            var valueList3=[];
            var valueList4=[];
            for(var i=0;i<this.dataList.length;i++){
                nameList.push(this.dataList[i].name);
                valueList1.push(this.dataList[i].value);
                valueList2.push(this.dataList[i].value2);
                valueList3.push(this.dataList[i].value3);
                valueList4.push(this.dataList[i].value4);
            } 
            //alert(JSON.stringify(this.dataList));
            //alert(JSON.stringify(valueList1));
            //alert(JSON.stringify(valueList2));     
            const option = {
                color: ['#4E82EA', '#37E5FF','#b99849', '#5cdb8c','#4E82EA', '#37E5FF','#4E82EA', '#37E5FF'],
                tooltip: {
                    trigger: 'item',
                    axisPointer: {
                        type: 'cross'
                    },
                    padding: 16,
                    border: 0
                },
                grid: {
                    right: '2%',
                    left: '10',
                    bottom: '0',
                    containLabel: true
                },
                legend: {
                    show: true,
                    icon: 'rect',
                    itemWidth: 9,
                    itemHeight: 9,
                    right: 40,
                    top: 20,
                    textStyle: {
                        fontSize: 12,
                        color: '#C8CBD2',
                    },
                },
                xAxis: [{
                    type: 'category',
                    axisTick: {
                        show: false
                    },
                    axisLabel: {
                        interval: 0,
                        textStyle: {
                            color: '#babdc3' // 这里将 x 轴文本颜色设为红色
                        },
                        fontSize: 14
                    },
                    axisLine: {
                        lineStyle: { // X 轴颜色配置
                            color: '#4792e9'
                        }
                    },
                    splitLine: {
                        show: true,
                        lineStyle: {
                            type: 'dashed',
                            color: "rgba(231,241,251,.2)"
                        }
                    },
                    boundaryGap: true,
                    data: nameList
                }],
                yAxis: {
                    type: 'value',
                    name: this.yAxisNames,
                    nameTextStyle: {
                        fontSize: 14,
                        color: "#aaa",
                        nameLocation: "start",
                        padding: [0, 15,13, 0]
                    },
                    splitNumber: 4,
                    axisLabel: {
                        textStyle: {
                            color: '#babdc3' // 这里将 x 轴文本颜色设为红色
                        },
                        fontSize: 14
                    },
                    axisLine: {
                        show: false
                    },
                    splitLine: {
                        show: true,
                        lineStyle: {
                            type: 'dashed',
                            color: "rgba(231,241,251,.2)"
                        }
                    },
                },
                series: [
                    {
                        name: this.yColumnList[0],
                        type: 'line',
                        showSymbol: false,
                        smooth: true,
                        data: valueList1,
                        endLabel: {
                            show: true,
                            distance: 20,
                            formatter: '{a}',
                        },
                        lineStyle: {
                            width: 2
                        },
                    },
                    {
                        name: this.yColumnList[1],
                        type: 'line',
                        showSymbol: false,
                        smooth: true,
                        data: valueList2,
                        endLabel: {
                            show: true,
                            formatter: '{a}',
                            distance: 20
                        },
                        lineStyle: {
                            width: 2
                        },
                    },
                    {
                        name: this.yColumnList[2],
                        type: 'line',
                        showSymbol: false,
                        smooth: true,
                        data: valueList4,
                        endLabel: {
                            show: true,
                            formatter: '{a}',
                            distance: 20
                        },
                        lineStyle: {
                            width: 2
                        },
                    },
                    {
                        name: this.yColumnList[3],
                        type: 'line',
                        showSymbol: false,
                        smooth: true,
                        data: valueList3,
                        endLabel: {
                            show: true,
                            formatter: '{a}',
                            distance: 20
                        },
                        lineStyle: {
                            width: 2
                        },
                    }
                ]
            }
            if (this.lineOption) {
                mychart.setOption(this.lineOption)
            } else {
                option && mychart.setOption(option)
            }
        }
    }
}
</script>

<style scoped lang="scss">
.r3 {
    position: relative;
    min-height:230px;
    .head {
        width: 100%;
        height: 46px;
        position: relative;
        display: flex;
        align-items: center;
    }
    .title-img {
        width: 436px;
        height: 46px;
        left: 0;
        top: 3px;
        position: absolute;
    }
    .layerName {
        margin-left: 42px;
        z-index: 1;
        font-family: 'pmzd';
        font-weight: 400;
        font-size: 18px;
        color: #FFFFFF;
        line-height: 15px;
        text-shadow: 0px 0px 10px rgba(41, 81, 202, 0.8), 0px 0px 10px rgba(41, 81, 202, 0.8), 0px 0px 10px rgba(41, 81, 202, 0.8);
    }
    .layerEng {
        margin-left: 12px;
        z-index: 1;
        font-family: Source Han Sans CN;
        font-weight: 400;
        font-size: 12px;
        color: #8798AC;
        line-height: 15px;
    }
    .lchart {
        width: 444px;
        height: 240px;
        margin-left: 12px;
        position: relative;
        pointer-events: auto;
    }
}
.icoL {
    position: absolute;
    top: 10px;
    right: 110px;
    animation: icoAni 2s linear 1;
    animation-fill-mode: forwards;
}

.icoR {
    position: absolute;
    top: 10px;
    right: 110px;
    animation: icoAniR 2s linear 1;
    animation-fill-mode: forwards;
}

.icoL img {
    width: 30px
}

.icoR img {
    width: 30px
}

.yjName {
    font-size: 14px;
    color: #BABDC3;
    padding: 3px 10px 0 10px;
    position: absolute;
    top: 10px;
    right: 30px;
    animation: fontAni 2s linear 1s 1;
    opacity: 0;
    animation-fill-mode: forwards;
}

@keyframes icoAni {
    from {
        right: 80px
    }

    to {
        right: 136px
    }
}

@keyframes icoAniR {
    from {
        right: 80px
    }

    to {
        right: -30px
    }
}

@keyframes fontAni {
    from {
        opacity: 0;
    }

    to {
        opacity: 1
    }
}
</style>