import Vue from 'vue'
import VueRouter from 'vue-router'
import index from '../views/home/index.vue'
import edit from '../views/home/edit.vue'
import login from '../views/login/login.vue'
import view from '../views/home/view.vue'
import template from '../views/home/template.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: index
  },
  {
    path: '/index',
    name: 'home',
    component: index
  },
  {
    path: '/edit',
    name: 'edit',
    component: edit
  },
  {
    path: '/view',
    name: 'view',
    component: view
  },
  {
    path: '/template',
    name: 'template',
    component: template
  },  
  {
    path: '/login',
    name: 'login',
    component: login
  }
  
]

const router = new VueRouter({
  routes
})

export default router
