<template>
    <div class="l1">
        <div ref="lchart" class="lchart"></div>
    </div>
</template>

<script>
import * as echarts from 'echarts'

export default {
    props: {
        options: {
            type: Object,
            default: () => {}
        },
        dataList: {
            type: Array,
            default() {
				return [];
			},
        },
        names: {
            type: String,
            default() {
				return defaultValue2;
			},
        },
        yAxisNames: {
            type: String,
            default() {
				return "";
			},
        }, 
    },
    watch: {
        dataList(newValue, oldValue) {
            if(newValue.length==0){
                if(this.isViewPage==false){
                    this.dataList=this.defaultValue; 
                } 
            } 
            if(this.dataList!=this.defaultValue){
                this.CdrawLine2();
            }
            console.log("this.dataList==="+JSON.stringify(this.dataList));
            // if(newValue!=oldValue){
            //      this.drawPie();
            // }
        },
        // names(newValue, oldValue) {
        //     if(!newValue){
        //         this.names=this.defaultValue2; 
        //     }     
        //     if(newValue!=oldValue){
        //          this.drawPie();
        //     }
        //     //alert(this.names+"2");
        // }
    },
    data() {
        return {
            title: '费用列表',
            chart1: null,
            defaultValue:[
                { value: 80, name: '故障数量' },
                { value: 70, name: '正常设备占比' },
                { value: 20, name: '预警数量' },
            ],
            defaultValue2:"金额(万)",
            isViewPage:false,//是否浏览页面
        }
    },
    mounted() {
       this.checkIsViewPage();
        if(this.dataList.length==0){
            if(this.isViewPage==false){
                this.dataList=this.defaultValue; 
            }
        }
        if(!this.names){
            this.names=this.defaultValue2;
        }  
        if(!this.yAxisNames){
            this.yAxisNames=this.defaultValue2;
        }   
        this.CdrawLine2()
    },
    methods: {
        checkIsViewPage(){
            const url = window.location.href;
            if(url.indexOf("/view")>=0){
                this.isViewPage=true;
            }
        }, 
        CdrawLine2() {
            var echarts = require('echarts');
            var CmyChart1 = echarts.init(this.$refs.lchart)
            const list = this.dataList || [];
            console.log("test====="+JSON.stringify(list));
            var Coption1 = {
                tooltip: {
                    show: true,
                    padding: 6,
                    formatter: function(params) {
                        return params.name + '：' + params.value
                    }
                },
                legend: {
                    show: false,
                },
                grid: {
                    left: '10',
                    right: '20',
                    bottom: '15',
                    top: '10',
                    containLabel: true
                },
                yAxis: {
                    type: 'category',
                    boundaryGap: true,
                    data: list.map((item) => {
                        return item.name
                    }),
                    axisLabel: {
                        show: false,
                        interval: 0,
                        margin: 18,
                        rotate: 0,
                        textStyle: {
                            color: '#babdc3'
                        },
                        fontSize: 14
                    },
                    axisLine: {
                        lineStyle: {
                            color: '#4792e9'
                        }
                    },
                    axisTick: {
                        show: false
                    },
                    splitLine: {
                        show: false
                    }
                },
                xAxis: {
                    type: 'value',
                    nameTextStyle: {
                        fontSize: 14,
                        color: "#aaa",
                        nameLocation: "start",
                        padding: [0, 15,13, 0],
                        normal: {
                            rotate: 90
                        }
                    },
                    splitNumber: 4,
                    axisLabel: {
                        textStyle: {
                            color: '#babdc3' // 这里将 x 轴文本颜色设为红色
                        },
                        fontSize: 14
                    },
                    axisLine: {
                        show: false
                    },
                    splitLine: {
                        show: true,
                        lineStyle: {
                            type: 'dashed',
                            color: "rgba(231,241,251,.2)"
                        }
                    }
                },
                series: {
                    name: this.yAxisNames || '',
                    type: 'bar',
                    itemStyle: {
                        normal: {
                            color: new echarts.graphic.LinearGradient(1, 0, 0, 0, [{
                                offset: 0,
                                color: "#2b8adc" // 0% 处的颜色
                            },{
                                offset: 1,
                                color: "rgba(43,138,220,.4)" // 100% 处的颜色
                            }], false)
                        }
                    },
                    label: {
                        show: true,
                        textBorderColor: 'transparent',
                        formatter: '{b}'
                    },
                    data: list,
                    barWidth: '24',
                    barGap: '5%', //柱子之间间距
                    barCategoryGap: '60%',
                    showBackground: true,
                    backgroundStyle: {
                        color: 'rgba(180, 180, 180, 0.2)'
                    }
                }
            }
            Coption1 && CmyChart1.setOption(Coption1);
        },
    }
}
</script>

<style scoped lang="scss">
.l1 {
    .head {
        width: 100%;
        height: 46px;
        position: relative;
        display: flex;
        align-items: center;
    }
    .title-img {
        width: 436px;
        height: 46px;
        left: 0;
        top: 3px;
        position: absolute;
    }
    .layerName {
        margin-left: 42px;
        z-index: 1;
        font-family: 'pmzd';
        font-weight: 400;
        font-size: 18px;
        color: #FFFFFF;
        line-height: 15px;
        text-shadow: 0px 0px 10px rgba(41, 81, 202, 0.8), 0px 0px 10px rgba(41, 81, 202, 0.8), 0px 0px 10px rgba(41, 81, 202, 0.8);
    }
    .layerEng {
        margin-left: 12px;
        z-index: 1;
        font-family: Source Han Sans CN;
        font-weight: 400;
        font-size: 12px;
        color: #8798AC;
        line-height: 15px;
    }
    .more {
        margin: 0 24px 0 auto;
        z-index: 1;
        font-family: Source Han Sans CN;
        font-weight: 400;
        font-size: 14px;
        color: #51A0F4;
        line-height: 15px;
        cursor: pointer;
        pointer-events: auto;
    }
    .lchart {
        width: 444px;
        height: 260px;
        margin-left: 12px;
        pointer-events: auto;
    }
}
</style>