import { render, staticRenderFns } from "./echart_3.vue?vue&type=template&id=6fc39fb9&scoped=true"
import script from "./echart_3.vue?vue&type=script&lang=js"
export * from "./echart_3.vue?vue&type=script&lang=js"
import style0 from "./echart_3.vue?vue&type=style&index=0&id=6fc39fb9&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6fc39fb9",
  null
  
)

export default component.exports