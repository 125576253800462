<template>
    <div class="l3">
        <div class="content">
            <img src="@/assets/template_1/l3-icon.png" alt="" class="l3-icon">
            <div class="l3-left">
                <div class="content-top">{{ dataList.value0 }}%</div>
                <img src="@/assets/template_1/l3-left.png" alt="">
                <div class="line-box">
                    <div class="out-line"></div>
                    <div class="bg-line"></div>
                    <div class="out-line"></div>
                    <div class="hover-line" :style="{width:dataList.value0 + '%'}"></div>
                </div>
                <div v-if="columnList.length>0" class="content-bottom">
                    {{columnList[0].name}}
                </div>
            </div>
            <video data-v-0556c46b="" x5-video-player-type="h5" playsinline="" webkitplaysinline="true" muted="muted"
                autoplay="autoplay" loop="loop" src="@/assets/template_1/dz.webm" poster="" x5-playsinline=""
                webkit-playsinline="" controlslist="nodownload"
                style="width: 120px; object-fit: fill; opacity: 1; transform: none;"></video>
            <div class="l3-right">
                <div class="content-top">{{ dataList.value1 }}%</div>
                <img src="@/assets/template_1/l3-right.png" alt="">
                <div class="line-box">
                    <div class="out-line"></div>
                    <div class="bg-line"></div>
                    <div class="out-line"></div>
                    <div class="hover-line" :style="{width: +dataList.value1 + '%'}"></div>
                </div>
                <div v-if="columnList.length>1" class="content-bottom">
                    {{columnList[1].name}}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        dataList: {
            type: Object,
            default() {
				return {};
			},
        },
        names: {
            type: String,
            default() {
				return "";
			},
        },        
    },
    data() {
        return {
            leftvalue: 68,
            rightvalue: 33,
            defaultValue:{ value0: '68', value1: '33', },
            defaultValue2: [
                { name:"黑色占比" },
                { name:"彩色占比" },
            ],
            columnList:[],
        }
    },
    watch: {
        dataList(newValue, oldValue) {
            if(newValue.length==0){
                this.dataList=this.defaultValue; 
            }     
            if(this.dataList!=this.defaultValue){
  
            }
        },
        names(newValue, oldValue) {
            if(!newValue){
                this.columnList=this.defaultValue2; 
            }     
            if(newValue!=oldValue){
                //alert(this.names);
                this.getColumnList(newValue);
            }
        }        
    }, 
    mounted() {
        if(this.dataList.length==0){
            this.dataList=this.defaultValue;
        } 
        if(!this.names){
            this.columnList=this.defaultValue2;
        }      
        else{
            this.getColumnList(this.names);
        } 
        //alert(JSON.stringify(this.columnList));
    }, 
    methods: {
        getColumnList(values){
            var tempList=values.split(',');
            if(tempList.length>0){
                this.columnList=[];
            }
            for(var i=0;i<tempList.length;i++){
                if(tempList[i]){
                    this.columnList.push({name:tempList[i]});
                }
            }
        },
    },      
}
</script>

<style scoped lang="scss">
.l3 {
    padding-top:5px;
    margin-top:-2px;
    min-height:257px;
    .content {
        display: flex;
        align-items: flex-end;
        position: relative;
        margin-bottom: 36px;
        .l3-left {
            margin-right: -16px;
            width: 176px;
            height: 90px;
            position: relative;
            img {
                width: 176px;
                height: 90px;
                position: absolute;
                top: 0;
            }
        }
        .l3-right {
            text-align: right;
            margin-left: -16px;
            width: 176px;
            height: 90px;
            position: relative;
            img {
                width: 176px;
                height: 90px;
                position: absolute;
                top: 0;
                left: 0;
            }
            .content-top {
                margin: 17px 21px 0 0;
            }
            .content-bottom {
                margin: 0 16px 0 0;
            }
            .hover-line {
                right: unset;
                left: 0;
                background: linear-gradient(to left, #37E5FF, #0C1022);
            }
            .out-line {
                background: linear-gradient(to left, #295688, #0C1022);
            }
        }
        .l3-icon {
            position: absolute;
            left: 206px;
            top: 36px;
            width: 28px;
            height: 27.5px;
        }
    }
    .content-top {
        margin: 17px 0 0 21px;
        font-family: Source Han Sans CN;
        font-weight: 500;
        font-size: 18px;
        color: #F6FCFF;
        line-height: 15px;
        text-shadow: 0px 0px 10px rgba(41, 81, 202, 0.8), 0px 0px 10px rgba(41, 81, 202, 0.8), 0px 0px 10px rgba(41, 81, 202, 0.8);
        position: relative;
        z-index: 1;
    }
    .line-box {
        margin: 6px 13px 6px 18px;
        position: relative;
        .hover-line {
            top: 3px;
            right: 0;
            height: 10px;
            position: absolute;
            background: linear-gradient(to right, #378EFF, #0C1022);
        }
    }
    .bg-line {
        height: 10px;
        width: 145px;
        background: #0F355C;
    }
    .out-line {
        width: 145px;
        height: 2px;
        margin: 1px 0;
        background: linear-gradient(to right, #295688, #0C1022);
    }
    .content-bottom {
        margin: 0 0 0 16px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        font-size: 14px;
        color: #C4EAFF;
        line-height: 15px;
    }
}
</style>