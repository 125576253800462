<template>
    <div class="r1">
        <img src="@/assets/template_1/pie-bg.png" alt="" class="pie-img">
        <div ref="lchart" class="lchart"></div>
    </div>
</template>

<script>
import * as echarts from 'echarts'

export default {
    props: {
        dataList: {
            type: Array,
            default() {
				return [];
			},
        },
        names: {
            type: String,
            default() {
				return defaultValue2;
			},
        },
    },
    data() {
        return {
            defaultValue:[
                { value: 80, name: '故障数量' },
                { value: 70, name: '正常设备占比' },
                { value: 20, name: '预警数量' },
            ],
            defaultValue2:"金额(万)"
        }
    },
    watch: {
        dataList(newValue, oldValue) {
            if(newValue.length==0){
                if(this.isViewPage==false){
                    this.dataList=this.defaultValue; 
                } 
            } 
            if(this.dataList!=this.defaultValue){
                this.drawPie();
            }
            console.log("this.dataList==="+JSON.stringify(this.dataList));
            // if(newValue!=oldValue){
            //      this.drawPie();
            // }
        },
        // names(newValue, oldValue) {
        //     if(!newValue){
        //         this.names=this.defaultValue2; 
        //     }     
        //     if(newValue!=oldValue){
        //          this.drawPie();
        //     }
        //     //alert(this.names+"2");
        // }
    },
    mounted() {
        this.checkIsViewPage();
        if(this.dataList.length==0){
            if(this.isViewPage==false){
                this.dataList=this.defaultValue; 
            }
        }
        if(!this.names){
            this.names=this.defaultValue2;
        }   
        this.drawPie();
    },
    methods: {
        checkIsViewPage(){
            const url = window.location.href;
            if(url.indexOf("/view")>=0){
                this.isViewPage=true;
            }
        },        
        drawPie() {
            var echarts = require('echarts')
            var mychart = echarts.init(this.$refs.lchart)
            const _this = this
            var option = {
                tooltip: {
                    trigger: 'item'
                },
                legend: {
                    orient: "vertical",
                    icon: 'rect',
                    right: 100,
                    top: 80,
                    itemWidth: 9,
                    itemHeight: 9,
                    textStyle: {
                        color: '#C8DBFC',
                        rich: {
                            a: {
                                color: '#DCB054',
                                padding: [0, 0, 0, 9],
                            }
                        }
                    },
                    formatter: function(params) {
                        const res = _this.dataList.find(item => item.name === params)
                        const result = (params + '{a|' + res.value +'}')
                        return result
                    }
                },
                color: ['#62D6FB', '#1E4BC1', '#FFFFFF'],
                series: {
                    name: '代管机器状态',
                    type: 'pie',
                    center: [120, 'center'],
                    radius: ['55%', '70%'],
                    avoidLabelOverlap: false,
                    itemStyle: {
                        borderColor: 'transparent',
                        borderWidth: 2
                    },
                    label: {
                        show: false,
                    },
                    emphasis: {
                        scaleSize: 3,
                        label: {
                            show: false,
                        },
                    },
                    data: _this.dataList
                }
            }
            option && mychart.setOption(option)
        }
    }
}
</script>

<style scoped lang="scss">
.r1 {
    position: relative;
    min-height:230px;
    .head {
        width: 100%;
        height: 46px;
        position: relative;
        display: flex;
        align-items: center;
    }
    .title-img {
        width: 436px;
        height: 46px;
        left: 0;
        top: 3px;
        position: absolute;
    }
    .layerName {
        margin-left: 42px;
        z-index: 1;
        font-family: 'pmzd';
        font-weight: 400;
        font-size: 18px;
        color: #FFFFFF;
        line-height: 15px;
        text-shadow: 0px 0px 10px rgba(41, 81, 202, 0.8), 0px 0px 10px rgba(41, 81, 202, 0.8), 0px 0px 10px rgba(41, 81, 202, 0.8);
    }
    .layerEng {
        margin-left: 12px;
        z-index: 1;
        font-family: Source Han Sans CN;
        font-weight: 400;
        font-size: 12px;
        color: #8798AC;
        line-height: 15px;
    }
    .more {
        margin: 0 24px 0 auto;
        z-index: 1;
        font-family: Source Han Sans CN;
        font-weight: 400;
        font-size: 14px;
        color: #51A0F4;
        line-height: 15px;
        pointer-events: auto;
        cursor: pointer;
    }
    .lchart {
        width: 444px;
        height: 240px;
        margin-left: 12px;
        position: relative;
        pointer-events: auto;
    }
    .pie-img {
        width: 193.5px;
        height: 160.5px;
        position: absolute;
        left: 34px;
        top: 40px;
    }
}
</style>