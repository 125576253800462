<template>
    <el-dialog :visible.sync="visible"
               :modal-append-to-body="false"
               @close="close"
               custom-class="large-dialog"
               top="10vh"
               width="1580px"
               :destroy-on-close="true"
               class="parent-dialog"
               v-if="vdata">
        <div class="head">
            <img src="@/assets/dialog/head-bg.png" alt="">
            <div class="text">{{ vdata.headtitle }}</div>
        </div>
        <div class="list-content" v-if="vdata.type == 'list'">
            <div class="list-head">
                <div class="text" style="width:83px"></div>
                <div class="text" :style="{width: textnum + 'px'}" v-for="(item, i) in vdata.head" :key="i">
                    {{ item }}
                </div>
            </div>
            <div class="list">
                <div class="list-item" v-for="(item, i) in vdata.datalist" :key="i">
                    <div class="text" style="width:83px;">
                        <img src="@/assets/dialog/icon-rank.png" alt="" class="rank-icon">
                    </div>
                    <div v-if="item.value0||item.value0==0" class="text" :style="{width: textnum + 'px'}"><div>{{ item.value0 }}</div></div>
                    <div v-if="item.value1||item.value1==0" class="text" :style="{width: textnum + 'px'}"><div>{{ item.value1 }}</div></div>
                    <div v-if="item.value2||item.value2==0" class="text" :style="{width: textnum + 'px'}"><div>{{ item.value2 }}</div></div>
                    <div v-if="item.value3||item.value3==0" class="text" :style="{width: textnum + 'px'}"><div>{{ item.value3 }}</div></div>
                    <div v-if="item.value4||item.value4==0" class="text" :style="{width: textnum + 'px'}"><div>{{ item.value4 }}</div></div>
                    <div v-if="item.value5||item.value5==0" class="text" :style="{width: textnum + 'px'}"><div>{{ item.value5 }}</div></div>
                    <div v-if="item.value6||item.value6==0" class="text" :style="{width: textnum + 'px'}"><div>{{ item.value6 }}</div></div>
                    <div v-if="item.value7||item.value7==0" class="text" :style="{width: textnum + 'px'}"><div>{{ item.value7 }}</div></div>
                    <div v-if="item.value8||item.value8==0" class="text" :style="{width: textnum + 'px'}"><div>{{ item.value8 }}</div></div>
                    <div v-if="item.value9||item.value9==0" class="text" :style="{width: textnum + 'px'}"><div>{{ item.value9 }}</div></div>
                </div>
            </div>
            <div style="display:none;" class="bottom">
                <span>共{{ (total / 5).toFixed(0) }}页</span>
                <el-pagination
                    background
                    layout="prev, pager, next"
                    :total="total"
                    :pager-count="5"
                    @current-change="currentChange"
                    :small="true">
                </el-pagination>
            </div>
        </div>
        <div class="img-list" v-if="vdata.type == 'imglist'"></div>
    </el-dialog>
</template>

<script>

export default {
    props: {
        dialogVisible: {
            type: Boolean,
            default: false
        },
        vdata: {
            type: Object,
            default: () => {}
        }
    },
    data() {
        return {
            visible: false,
            // defaultdata: {
            //     head: [
            //         { name: '', value: '', isRank: true },
            //         { name: '机器编码', value: 'num' },
            //         { name: '用量型号', value: 'model' },
            //         { name: '黑色', value: 'value' },
            //         { name: '红色', value: 'num' },
            //         { name: '蓝色', value: 'model' },
            //         { name: '黄色', value: 'value' },
            //         { name: '配件', value: 'num' },
            //         { name: '使用部门', value: 'model' },
            //         { name: '报修次数', value: 'value' },
            //         { name: '综合评分', value: 'value' },
            //     ],
            //     datalist: [
            //         { num: 'GR123', model: '009', value: '000' },
            //         { num: 'HG234', model: '008', value: '000' },
            //         { num: 'GR123', model: '009', value: '000' },
            //         { num: 'HG234', model: '008', value: '000' },
            //         { num: 'GR123', model: '009', value: '000' },
            //         { num: 'GR123', model: '009', value: '000' },
            //         { num: 'GR123', model: '009', value: '000' },
            //         { num: 'HG234', model: '008', value: '000' },
            //         { num: 'GR123', model: '009', value: '000' },
            //         { num: 'HG234', model: '008', value: '000' },
            //     ],
            //     headtitle: '报修事件列表（全公司）'
            // },
            textnum: 127,
            total: 100
        }
    },
    watch: {
        dialogVisible(n) {
            this.visible = n;
            this.getWidth();
        },
        vdata(n) {
            this.$nextTick(() => {
                this.getWidth()
            })
        },
    },
    mounted() {
        //alert(JSON.stringify(this.vdata));
    },
    methods: {
        close() {
            this.$emit('largeclose')
        },
        getWidth() {
            const headnum = this.vdata.head.length;
            //alert(headnum);
            if (headnum) {
                this.textnum = 1356 /headnum
            }
        },
        currentChange() {

        }
    }
}
</script>

<style lang="scss">
.parent-dialog {
    background: transparent!important;
    backdrop-filter: blur(20px);
}
.large-dialog {
    height: 797.5px;
    background: url(@/assets/dialog/large-bg.png) no-repeat !important;
    background-size: 100% 100% !important;
    .el-dialog__close {
        font-size: 27px;
        color: #C2E6FE !important;
    }
    .el-dialog__headerbtn {
        top: 60px;
        right: 100px;
    }
    .head {
        width: 100%;
        height: 70px;
        margin-top: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
        .text {
            font-family: 'pmzd';
            font-weight: 400;
            font-size: 23px;
            color: #FFFFFF;
            line-height: 23px;
            position: relative;
            z-index: 1;
        }
        img {
           width: 605px;
           position: absolute;
        }
    }
    .list-content {
        width: 1356px;
        margin: 12px auto;
    }
    .list-head {
        display: flex;
        margin: 0 0 22px;
        .text {
            font-family: Source Han Sans CN;
            font-weight: 400;
            font-size: 16px;
            color: #51A0F4;
            line-height: 16px;
            text-align: center;
        }
    }
    .list-item {
        padding: 10px 0;
        font-family: Source Han Sans CN;
        font-weight: 400;
        font-size: 16px;
        color: #B3C7E9;
        line-height: 16px;
        display: flex;
        background: rgba(61, 98, 151, .2);
        margin-bottom: 4px;
        &:nth-child(2n) {
            background: rgba(61, 98, 151, .4);
        }
        .text {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
    .bottom {
        margin-top: 22px;
        display: flex;
        align-items: center;
        justify-content: center;
        >span {
            font-family: Source Han Sans CN;
            font-weight: 400;
            font-size: 14px;
            color: #90D4FF;
            line-height: 14px;
        }
    }
    .el-pagination  {
        display: flex;
        justify-content: center;
        .btn-prev, .btn-next {
            background: transparent !important;
            color: #A6DAFF !important;
            border: 1px solid #1e4265;
            display: flex;
            align-items: center;
            justify-content: center;
            &:hover {
                color: #fff !important;
            }
        }
        .el-pager {
            li {
                color:  #A5B1BC !important;
                background: transparent !important;
                border: 1px solid #1e4265;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                &.active, &:hover {
                    color: #fff !important;
                    border-color: #1b5ba2;
                    background: #134379 !important;
                }
            }
        }
    }
    .state1 {
        color: #00FD4A;
        display: flex;
        align-items: center;
        .point {
            background: #00FD4A;
        }
    }
    .state2 {
        color: #00CBFC;
        display: flex;
        align-items: center;
        .point {
            background: #00CBFC;
        }
    }
    .state3 {
        color: #FFE54A;
        display: flex;
        align-items: center;
        .point {
            background: #FFE54A;
        }
    }
    .state4 {
        color: #FF6C65;
        display: flex;
        align-items: center;
        .point {
            background: #FF6C65;
        }
    }
}
</style>