<template>
    <div class="head">
        <div class="layerName">{{ title }}</div>
        <div class="layerEng" v-if="english">/ {{ english }}</div>
        <div class="more" style="display:none;">查看更多 ></div>
        <div style="width:120px;display:none" class="search-list">
            <el-date-picker @change="SelectMonths" class="month-select sb-select" style="width:120px" :value-format="dateFormat" v-model="value1" :type="dateType" placeholder="选择日期">
            </el-date-picker>
            <!-- <el-select @change="SelectMonths" v-model="value1" placeholder="选择月" class="month-select sb-select" v-if="selectMonth">
                <el-option
                    v-for="item in monthList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
            </el-select>
            <el-select @change="SelectDays" v-model="value2" placeholder="选择日" class="day-select sb-select" v-if="selectDay">
                <el-option
                    v-for="item in dayList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
            </el-select> -->
        </div>
        <img src="@/assets/template_1/title.png" alt="" class="title-img">
    </div>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            default: '请输入标题'
        },
        effectedComponent: {
            type: String,
            default: ''
        },        
        english: {
            type: String,
            default: ''
        },
        tomore: {
            type: Boolean,
            default: false
        },
        selectYear: {
            type: Boolean,
            default: false
        },
        selectMonth: {
            type: Boolean,
            default: false
        },
        selectDay: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            monthList: [
                { label: '请选择', value: "" },
                { label: '一月份', value: 1 },
                { label: '二月', value: 2 },
                { label: '三月', value: 3 },
                { label: '四月', value: 4 },
                { label: '五月', value: 5 },
            ],
            dayList: [
                { label: '请选择', value: "" },
                { label: '1日', value: 1 },
                { label: '2日', value: 2 },
                { label: '3日', value: 3 },
                { label: '4日', value: 4 },
                { label: '31日', value: 5 },
            ],
            value1: "",
            value2: "",
            dateFormat:"yyyy-MM-dd",
            dateType:"date",
        }
    },
    mounted(){
        if(this.selectYear==true&&this.selectMonth==false&&this.selectDay==false){
            this.dateFormat="yyyy";
            this.dateType="year";
        }else if(this.selectYear==true&&this.selectMonth==true&&this.selectDay==false){
            this.dateFormat="yyyy-MM";
            this.dateType="month";
        }else{
            this.dateFormat="yyyy-MM-dd";
            this.dateType="date";
        }
        console.log("selectYear==="+this.selectYear+";selectMonth:"+this.selectMonth);
        //alert(this.dateFormat);
        //alert(this.dateFormat);
    },
    methods: {
        toMore(){
            var selectedData=[true,"","",this.effectedComponent];
            this.$emit('SearchDatas', selectedData)
        },
        SelectMonths(){
            var selectedData=[false,this.value1,this.value2,this.effectedComponent];
            this.$emit('SearchDatas', selectedData)
        },
        SelectDays(){
            var selectedData=[false,this.value1,this.value2,this.effectedComponent];
            this.$emit('SearchDatas', selectedData)
        },
    }
}
</script>

<style scoped lang="scss">
.head {
    width: 100%;
    height: 46px;
    position: relative;
    display: flex;
    align-items: center;
}
.title-img {
    width: 100%;
    height: 46px;
    left: 0;
    top: 3px;
    position: absolute;
}
.layerName {
    margin-left: 42px;
    z-index: 1;
    font-family: 'pmzd';
    font-weight: 400;
    font-size: 18px;
    color: #FFFFFF;
    line-height: 15px;
    text-shadow: 0px 0px 10px rgba(41, 81, 202, 0.8), 0px 0px 10px rgba(41, 81, 202, 0.8), 0px 0px 10px rgba(41, 81, 202, 0.8);
}
.layerEng {
    margin-left: 12px;
    z-index: 1;
    font-family: Source Han Sans CN;
    font-weight: 400;
    font-size: 12px;
    color: #8798AC;
    line-height: 15px;
}
.more {
    margin: 0 24px 0 auto;
    z-index: 1;
    font-family: Source Han Sans CN;
    font-weight: 400;
    font-size: 14px;
    color: #51A0F4;
    line-height: 15px;
    cursor: pointer;
    pointer-events: auto;
}
.search-list {
    margin: 0 16px 0 auto;
    display: flex;
    align-items: center;
    z-index: 1;
}
.selectitem {
    width: 70px; 
    height: 24px;
    background: url(@/assets/template_1/select-bg1.png) no-repeat;
    background-size: 100% 100%;
    text-align: center;
    font-family: Source Han Sans CN;
    font-weight: 400;
    font-size: 12px;
    color: #FFFFFF;
    line-height: 24px;
    cursor: pointer;
    pointer-events: auto;
    &.active {
        background: url(@/assets/template_1/select-bg.png) no-repeat;
        background-size: 100% 100%;
    }
    &.select-item {
        width: 59px;
        margin-left: 3px;
    }
}
</style>
<style>
.sb-select .el-input__inner{
    width:100px !important;
    text-align:right;
}
</style>
