<template>
    <div id="mapDiv" class="mapDiv"></div>
</template>

<script>
import icon1 from '@/assets/template_1/legend-poi1.png'
import icon2 from '@/assets/template_1/legend-poi2.png'
import icon3 from '@/assets/template_1/legend-poi3.png'

export default {
    props: {
        dataList: {
            type: Array,
            default() {
				return [];
			},
        },
	},
    watch: {
        dataList(newValue, oldValue) {    
            if(this.dataList!=this.defaultValue){
                this.mapstart();
            }
        },
    }, 		
	data() {
		return {
			pointList: [
				// { j: 86, w: 39, icon: icon1 },
				// { j: 94, w: 35, icon: icon1 },
				// { j: 102, w: 30, icon: icon2 },
				// { j: 112, w: 37, icon: icon3 },
				// { j: 115.5, w: 28, icon: icon3 },
			]
		}
	},	
    mounted() {
	this.$nextTick(() => {
        this.mapstart()
      },3500);		 
    },
    methods: {
		mapstart() {
			console.log("pointList"+JSON.stringify(this.pointList));
			if(this.dataList.length>0){
				this.pointList=[];
				for(var i=0;i<this.dataList.length;i++){
					var point=this.dataList[i].value2;
					var icons=icon1;
					if(this.dataList[i].values1=="预警"){
						icons=icon2;
					}else if(this.dataList[i].values1=="故障"){
						icons=icon3;
					}
					var temp={j:point.split(',')[0],w:point.split(',')[1],icon:icons}
					this.pointList.push(temp);
				}
			}else{
				this.pointList=[];
			}
			if(this.pointList.length==0){
				return;
			}
			var map;
			var zoom = 13;
			var lay;
			var onlyMapLay;
			var config;
			var imageURL;
			var icon;
			var marker;
			var infoWin1;
			var sContent;
			var lng;
			var lat;
			var latlng;
			var label;
			var line;
			var localsearch;
			var countries = [];
			var countries1 = [];
			var countriesOverlay = new T.D3Overlay(init, redraw);
			var countriesOverlay1 = new T.D3Overlay(init1, redraw1);
			var countriesOverlay2 = new T.D3Overlay(init2, redraw2);
			var countriesOverlay3 = new T.D3Overlay(init3, redraw3);

			var map = new T.Map("mapDiv");
			map.centerAndZoom(new T.LngLat(this.pointList[0].j, this.pointList[0].w), 14);
			//初始化地图对象
			map.setStyle('black');
			var mColor = "#0b295e";
			//设置主题色
			let example = document.getElementById("mapDiv");
			example.style.backgroundColor = mColor;
			d3.json("https://geo.datav.aliyun.com/areas_v3/bound/100000_full.json", function(data) {
				countries = data.features;
				map.addOverLay(countriesOverlay)
				countriesOverlay.bringToBack();
				map.addOverLay(countriesOverlay1)
				countriesOverlay.bringToBack();
			});
			
			d3.json("https://geo.datav.aliyun.com/areas_v3/bound/100000.json", function(data) {
				countries1 = data.features;
				map.addOverLay(countriesOverlay2)
				countriesOverlay.bringToBack();
				map.addOverLay(countriesOverlay3)
				countriesOverlay.bringToBack();
			});
			this.pointList.forEach((v) => {
				
				// 创建图片对象
				icon = new T.Icon({
					iconUrl: v.icon,
					iconSize: new T.Point(39.5, 48.5),
					// iconAnchor: new T.Point(20, 50),
				});
				// 向地图上添加自定义标注
				marker = new T.Marker(
					new T.LngLat(v.j, v.w), {
						icon: icon,
					}
				)
				map.addOverLay(marker);
			})
			function init(sel, transform) {
				var upd = sel.selectAll('path.geojson').data(countries);
				upd.enter()
					.append('path')
					.attr("class", "geojson")
					.attr('stroke', '#2961dd')
					.attr('fill', function(d, i) {
						return d3.rgb(41, 97, 221);
					})
					
					.attr('fill-opacity', '.02')
					.attr('stroke-opacity', '.2')
					.attr("stroke-width", "1px");
			}

			function redraw(sel, transform) {
				sel.selectAll('path.geojson').each(
					function(d, i) {
						d3.select(this).attr('d', transform.pathFromGeojson)
							.on("mouseover", function() {
								console.log('这是点击了', );
							})
					}
				)
			}

			function init1(sel, transform) {
				var upd = sel.selectAll('path.geojson1').data(countries);
				upd.enter()
					.append('path')
					.attr("class", "geojson1")
					.attr('stroke', '#2961dd')
					.attr('fill', function(d, i) {
						return d3.rgb(41, 97, 221);
					})
					
					.attr('fill-opacity', '.02')
					.attr('stroke-opacity', '.2')
					.attr("stroke-width", "1px");
			}

			function redraw1(sel, transform) {
				sel.selectAll('path.geojson1').each(
					function(d, i) {
						d3.select(this).attr('d', transform.pathFromGeojson)
					}
				)
			}
			
			function init2(sel, transform) {
				var upd1 = sel.selectAll('path.geojson1').data(countries1);
				upd1.enter()
					.append('path')
					.attr("class", "geojson1")
					.attr('stroke', '#2961dd')
					.attr('fill', function(d, i) {
						return d3.rgb(41, 97, 221);
					})
					
					.attr('fill-opacity', '.01')
					.attr('stroke-opacity', '1')
					.attr("stroke-width", "3px");
			}
			
			function redraw2(sel, transform) {
				sel.selectAll('path.geojson1').each(
					function(d, i) {
						d3.select(this).attr('d', transform.pathFromGeojson)
					}
				)
			}
			function init3(sel, transform) {
				var upd2 = sel.selectAll('path.geojson1').data(countries1);
				upd2.enter()
					.append('path')
					.attr("class", "geojson1")
					.attr('stroke', '#2961dd')
					.attr('fill', function(d, i) {
						return d3.rgb(41, 97, 221);
					})
					
					.attr('fill-opacity', '.01')
					.attr('stroke-opacity', '1')
					.attr("stroke-width", "3px");
			}
			
			function redraw3(sel, transform) {
				sel.selectAll('path.geojson1').each(
					function(d, i) {
						d3.select(this).attr('d', transform.pathFromGeojson)
					}
				)
			}
		},
    }
}
</script>

<style scoped lang="scss">
.mapDiv {
	width: 1920px;
	height: 1080px;
	position: absolute;
}
</style>