<template>
    <div class="l1">
        <div ref="lchart" class="lchart"></div>
    </div>
</template>

<script>
import * as echarts from 'echarts'

export default {
    props: {
        config: {
            type: Object,
            default: () => {}
        },
        dataList: {
            type: Array,
            default() {
				return defaultValue;
			},
        },
        names: {
            type: String,
            default() {
				return "";
			},
        },
        yAxisNames: {
            type: String,
            default() {
				return "";
			},
        },        
    },
    data() {
        return {
            chart1: null,
            defaultValue:[
                { name: '一期', value: 21 }, // name 横坐标,value: 纵坐标
                { name: '二期', value: 14 },
                { name: '三期', value: 12 },
                { name: '四期', value: 9 },
                { name: '五期', value: 9.5 },
                { name: '六期', value: 7 },
            ],
            defaultValue2:"金额(万)",
            isViewPage:false,//是否浏览页面
        }
    },
    watch: {     
        dataList(newValue, oldValue) {
            if(newValue.length==0){
                if(this.isViewPage==false){
                    this.dataList=this.defaultValue; 
                }       
            }     
            if(this.dataList!=this.defaultValue){
                this.drawPie();
            }
            console.log("this.dataList==="+JSON.stringify(this.dataList));
            // if(newValue!=oldValue){
            //      this.drawPie();
            // }
        },
    },    
    mounted() {     
       this.checkIsViewPage();
        if(this.dataList.length==0){
            if(this.isViewPage==false){
                this.dataList=this.defaultValue; 
            }
        }
        if(!this.names){
            this.names=this.defaultValue2;
        }  
        if(!this.yAxisNames){
            this.yAxisNames=this.defaultValue2;
        }               
        //alert(this.names+"1");    
        this.drawPie()
    },
    methods: {
        checkIsViewPage(){
            const url = window.location.href;
            if(url.indexOf("/view")>=0){
                this.isViewPage=true;
            }
        },           
        drawPie() {
            var echarts = require('echarts');
            var CmyChart1 = echarts.init(this.$refs.lchart)
            if (this.options) {
                var Coption1 = this.options
                Coption1 && CmyChart1.setOption(Coption1);
                this.chart1 = setInterval(function() {
                    CmyChart1.clear();
                    Coption1 && CmyChart1.setOption(Coption1);
                }, 10000)
            } else {
                const list = this.dataList || []
                var Coption1 = {
                    tooltip: {
                        show: true
                    },
                    legend: {
                        show: false,
                    },
                    grid: {
                        left: '10',
                        right: '55',
                        bottom: '15',
                        top: '65',
                        containLabel: true
                    },
                    xAxis: {
                        type: 'category',
                        boundaryGap: true,
                        data: list.map((item) => {
                            return item.name
                        }),
                        axisLabel: {
                            interval: 0,
                            margin: 18,
                            rotate: 0,
                            textStyle: {
                                color: '#babdc3'
                            },
                            fontSize: 14
                        },
                        axisLine: {
                            lineStyle: {
                                color: '#4792e9'
                            }
                        },
                        axisTick: {
                            show: false
                        },
                        splitLine: {
                            show: false
                        }
                    },
                    yAxis: {
                        type: 'value',
                        name: this.yAxisNames,
                        nameTextStyle: {
                            fontSize: 14,
                            color: "#aaa",
                            nameLocation: "start",
                            padding: [0, 15,13, 0]
                        },
                        splitNumber: 4,
                        axisLabel: {
                            textStyle: {
                                color: '#babdc3' // 这里将 x 轴文本颜色设为红色
                            },
                            fontSize: 13
                        },
                        axisLine: {
                            show: false
                        },
                        splitLine: {
                            show: true,
                            lineStyle: {
                                type: 'dashed',
                                color: "rgba(231,241,251,.2)"
                            }
                        }
                    },
                    series: {
                        name: this.names,
                        type: 'bar',
                        itemStyle: {
                            normal: {
                                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                                    offset: 0,
                                    color: "#2b8adc" // 0% 处的颜色
                                },{
                                    offset: 1,
                                    color: "rgba(43,138,220,.4)" // 100% 处的颜色
                                }], false)
                            }
                        },
                        data: list,
                        barWidth: '16',
                        barGap: '5%', //柱子之间间距
                        barCategoryGap: '60%',
                        showBackground: true,
                        backgroundStyle: {
                            color: 'rgba(180, 180, 180, 0.2)'
                        }
                    }
                }
                Coption1 && CmyChart1.setOption(Coption1);
                this.chart1 = setInterval(function() {
                    CmyChart1.clear();
                    Coption1 && CmyChart1.setOption(Coption1);
                }, 10000)
            }
            
        },
    }
}
</script>

<style scoped lang="scss">
.l1 {
    min-height:230px;
    .head {
        width: 100%;
        height: 46px;
        position: relative;
        display: flex;
        align-items: center;
    }
    .title-img {
        width: 436px;
        height: 46px;
        left: 0;
        top: 3px;
        position: absolute;
    }
    .layerName {
        margin-left: 42px;
        z-index: 1;
        font-family: 'pmzd';
        font-weight: 400;
        font-size: 18px;
        color: #FFFFFF;
        line-height: 15px;
        text-shadow: 0px 0px 10px rgba(41, 81, 202, 0.8), 0px 0px 10px rgba(41, 81, 202, 0.8), 0px 0px 10px rgba(41, 81, 202, 0.8);
    }
    .layerEng {
        margin-left: 12px;
        z-index: 1;
        font-family: Source Han Sans CN;
        font-weight: 400;
        font-size: 12px;
        color: #8798AC;
        line-height: 15px;
    }
    .more {
        margin: 0 24px 0 auto;
        z-index: 1;
        font-family: Source Han Sans CN;
        font-weight: 400;
        font-size: 14px;
        color: #51A0F4;
        line-height: 15px;
        cursor: pointer;
        pointer-events: auto;
    }
    .lchart {
        width: 460px;
        height: 260px;
        margin-left: 12px;
        pointer-events: auto;
    }
}
</style>