<template>
	<div class="homeFrame">
		<div class="header">
			<div class="logoHead">
				<div class="logo">
					<img src="../../assets/logoWhite.jpg">
				</div>
				<div class="logoName">数据与可视化搭建平台</div>
			</div>
			<div class="menuFrame">
				<div class="menu">案例赏析</div>
				<div class="menu">版本对比</div>
				<div class="menu">微定制服务</div>
				<div class="menu">企业私享定制</div>
				<div class="headline"></div>
				<div class="photo"></div>
				<div class="menu">{{userInfor.nickname}}</div>
				<div style="cursor:pointer" @click="loginOut" class="menu">退出登录</div>
			</div>
		</div>
		<div class="contentFrame">
			<div class="contentLeft">
				<div class="create">
					<button @click="opencteate">+ 创建可视化项目</button>
				</div>
				<div class="leftMenu">
					<div class="myCase myCaseS textS"> <!-- 选中加上myCaseS textS -->
						<span class="textspan">我的项目</span>
					</div>
				</div>
				<div class="leftMenu">
					<div class="myDiy"> <!-- 选中加上myDiyS textS -->
						<span class="textspan">定制项目</span>
					</div>
				</div>
				<div class="leftMenu">
					<div class="myWaster"> <!-- 选中加上wasterS textS -->
						<span class="textspan">回收站</span>
					</div>
				</div>
				<div class="leftline1"></div>
				<div style="width: 90%;">
					<img src="../../assets/leftAd.jpg" style="width: 100%;">
				</div>
				<div class="leftMenu">
					<div class="DataCenter"> <!-- 选中加上wasterS textS -->
						<button>
							<span class="textspan1">数据管理中心入口</span>
							<span class="textmore">></span>
						</button>
					</div>
				</div>
				<div class="leftline2"></div>
				<div class="about">福州今领信息科技有限公司 版权所有</div>
				<div class="code">BETA 0.1.1</div>
			</div>
			<div class="contentRight">
				<div class="homeAd"><img src="../../assets/homeAdbg.jpg" style="width:100%"></div>
				<div class="listFrame">
					<div v-for="(item,index) in projectList" class="list" @mouseover="hoverlist(index)" @mouseout="hoverlistOut(index)">
						<div class="listPic">
							<img src="../../assets/pic.jpg" :onerror="defaultImg" style="width: 100%;">
						</div>
						<div class="listInfo">
							<div class="listname">{{item.name}}</div>
							<div class="listVersion">
								<div class="listico1"></div>
								<div class="versionname">演示版</div>
							</div>
						</div>
						<!-- <div class="listversionName">
							<img src="../../assets/icon_geren.png" style="width:56px">
						</div> -->
						<div class="listhover" v-show="item.listhover">
							<div class="listmenuIcon">
								<div @click="preview()"><img src="../../assets/icon5.png" title="数据中心" alt="数据中心"></div>
								<div><img src="../../assets/icon6.png" title="复制项目" alt="复制项目"></div>
								<div><img src="../../assets/icon7.png" title="源代码下载" alt="源代码下载"></div>
								<div><img src="../../assets/icon8.png" title="大屏分享" alt="大屏分享"></div>
								<div @click="deleteProject(item)"><img src="../../assets/icon9.png" title="删除" alt="删除"></div>
							</div>
							<div class="listManage">
								<div style="cursor:pointer" @click="goView(item.id)" class="listpreview">预览</div>
								<div style="cursor:pointer" @click="goEdit(item.id)" class="listEdit">编辑</div>
							</div>
							<div class="listnameedit">
								<input type="text" :value="item.name" disabled>
							</div>

						</div>
					</div>

					<!-- <div class="list">
						<div class="listPic">
							<img src="../../assets/pic.jpg" :onerror="defaultImg" style="width: 100%;">
						</div>
						<div class="listInfo">
							<div class="listname">标题名称</div>
							<div class="listVersion">
								<div class="listico1"></div>
								<div class="versionname">
									<dd>365天可用</dd>
								</div>
							</div>
						</div>
						<div class="listversionName">
							<img src="../../assets/icon_qiye.png" style="width:56px">
						</div>
						<div class="listhover" v-show="listhover0">
							<div class="listmenuIcon">
								<div @click="preview()"><img src="../../assets/icon5.png" title="数据中心" alt="数据中心"></div>
								<div><img src="../../assets/icon6.png"></div>
								<div><img src="../../assets/icon7.png"></div>
								<div><img src="../../assets/icon8.png"></div>
								<div><img src="../../assets/icon9.png"></div>
							</div>
							<div class="listManage">
								<div class="listpreview">预览</div>
								<div class="listEdit">编辑</div>
							</div>
							<div class="listnameedit">
								<input type="text" value="视频监控平台" disabled>
							</div>

						</div>
					</div>

					<div class="list">
						<div class="listPic">
							<img src="../../assets/pic.jpg" :onerror="defaultImg" style="width: 100%;">
						</div>
						<div class="listInfo">
							<div class="listname">标题名称</div>
							<div class="listVersion">
								<div class="listico2"></div>
								<div class="versionname">可试用7天</div>
							</div>
						</div>
						<div class="listversionName">
							<img src="../../assets/icon_geren.png" style="width:56px">
						</div>
						<div class="listhover" v-show="listhover0">
							<div class="listmenuIcon">
								<div @click="preview()"><img src="../../assets/icon5.png" title="数据中心" alt="数据中心"></div>
								<div><img src="../../assets/icon6.png"></div>
								<div><img src="../../assets/icon7.png"></div>
								<div><img src="../../assets/icon8.png"></div>
								<div><img src="../../assets/icon9.png"></div>
							</div>
							<div class="listManage">
								<div class="listpreview">预览</div>
								<div class="listEdit">编辑</div>
							</div>
							<div class="listnameedit">
								<input type="text" value="视频监控平台" disabled>
							</div>

						</div>
					</div>

					<div class="list">
						<div class="listPic">
							<img src="../../assets/pic.jpg" :onerror="defaultImg" style="width: 100%;">
						</div>
						<div class="listInfo">
							<div class="listname">标题名称</div>
							<div class="listVersion">
								<div class="listico1"></div>
								<div class="versionname">30天后到期</div>
							</div>
						</div>
						<div class="listversionName">
							<img src="../../assets/icon_qiye.png" style="width:56px">
						</div>
						<div class="listhover" v-show="listhover0">
							<div class="listmenuIcon">
								<div @click="preview()"><img src="../../assets/icon5.png" title="数据中心" alt="数据中心"></div>
								<div><img src="../../assets/icon6.png"></div>
								<div><img src="../../assets/icon7.png"></div>
								<div><img src="../../assets/icon8.png"></div>
								<div><img src="../../assets/icon9.png"></div>
							</div>
							<div class="listManage">
								<div class="listpreview">预览</div>
								<div class="listEdit">编辑</div>
							</div>
							<div class="listnameedit">
								<input type="text" value="视频监控平台" disabled>
							</div>

						</div>
					</div>
					<div class="list">
						<div class="listPic">
							<img src="../../assets/pic.jpg" :onerror="defaultImg" style="width: 100%;">
						</div>
						<div class="listInfo">
							<div class="listname">标题名称</div>
							<div class="listVersion">
								<div class="listico1"></div>
								<div class="versionname">365天可用</div>
							</div>
						</div>
						<div class="listversionName">
							<img src="../../assets/icon_dingzhi.png" style="width:56px">
						</div>
						<div class="listhover" v-show="listhover0">
							<div class="listmenuIcon">
								<div @click="preview()"><img src="../../assets/icon5.png" title="数据中心" alt="数据中心"></div>
								<div><img src="../../assets/icon6.png"></div>
								<div><img src="../../assets/icon7.png"></div>
								<div><img src="../../assets/icon8.png"></div>
								<div><img src="../../assets/icon9.png"></div>
							</div>
							<div class="listManage">
								<div class="listpreview">预览</div>
								<div class="listEdit">编辑</div>
							</div>
							<div class="listnameedit">
								<input type="text" value="视频监控平台" disabled>
							</div>
						</div>
					</div> -->
				</div>
			</div>
		</div>
		<div class="createWin" v-show="isCreate">
			<div class="createNew">
				<div class="createNewName">创建新项目</div>
				<div class="createClose" @click="closecreate">
					<img src="../../assets/close.png" style="width: 100%;">
				</div>
			</div>
			<div class="createFrame">
				<div class="createLeft">
					<div class="createDemo">
						<img v-if="!formData.image" src="../../assets/createbg.png">
						<img v-if="formData.image" :src="formData.image">
					</div>
					<div class="demoTitle">通用大屏示例</div>
					<div class="demoSubtitle">包含通用模板，通用组件，适合于各行业基础的数据分析与展示</div>
					<div class="demoLine"></div>
					<div class="caseName">项目名称：</div>
					<div class="caseTitleInput">
						<input v-model="formData.title" type="text" autofocus="autofocus"/>
					</div>
					<div class="createbutton">
						<button class="createNewTitle" @click="createNow">创建项目</button>
						<button class="createover" @click="closecreate">取消</button>
					</div>
				</div>
				<div class="createRight">
					<div class="creatModelMenu">
						<div :class="modelclass" @click="model()">通用模板</div>
						<div :class="modelclass1" @click="modelVip()">高级模板</div>
						<div :class="modelclass2" @click="modelDiy()">定制模板</div>
						<div class="modelLine" :style="{left:leftmove}"></div>
					</div>
					<div class="modelContent">
						<div v-for="(item,index) in templateList" :class="selectedTemplateId==item.id?'model_cur':'model'">
							<div @click="selectTemplate(item)" class="modelPic">
								<img :src="item.image" :onerror="defaultImg" style="width: 100%;">
							</div>
							<div class="modelTitle">
								<div>{{item.name}}</div>
							</div>
						</div>
					</div>
					
				</div>
			</div>

		</div>
	</div>
</template>

<script>
	import screenfull from 'screenfull';
	import { Message } from 'element-ui';
	import router from '@/router';
	import { addProjectData,deleteProjectData,getProjectListData,getTemplateListData} from "@/api/index";
	export default {
		components: {

		},
		data() {
			return {
				defaultImg: 'this.src = "' + require('../../assets/imgerror.png') + '"', //默认图片的地址
				listhover: false,
				isCreate: false,
				leftmove:'4%',
				modelclass:'modelclass modelclassSelected',
				modelclass1:'modelclass1 modelclassSelect',
				modelclass2:'modelclass1 modelclassSelect',
				fullscreen: false,
				userInfor:{},//用户信息
				templateList:[],//模板列表
				projectList:[],//项目列表
				selectedTemplateId:0,//选择的模板ID
				formData:{
					title:"",
					image:"",
				}
			}
		},
		mounted() {
			var token=localStorage.getItem("token");			
			if(!token){
				Message.warning("您还未登录！");
				router.push({path:'/login'});
				return;
			}
			this.userInfor=localStorage.getItem("userInfor");
			if(!this.userInfor){
				Message.warning("登录信息失效！");
				router.push({path:'/login'});
				return;
			}
			if(localStorage.getItem("HomeState")=="0"){
				window.location.reload();
			}
			localStorage.setItem("HomeState","");
			console.log("userInfor===="+this.userInfor);
			this.userInfor=JSON.parse(this.userInfor);
			this.getProjectList();
			this.getTemplateList();
		},
		methods: {
			deleteProject(item){
				if(item.id<=47){
					Message.warning("该项目正在使用中,暂时不能删除!");
					return;
				}
				this.$confirm('是否删除该组件?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				})
				.then(async () => {
					var temp={id:item.id}
					const res = await deleteProjectData(temp);
					if(res.status==-1){
						this.$message.warning(res.msg);
						return;
					}
					this.$message({
						type: 'success',
						message: '删除成功!'
					})
					this.getProjectList();
				})
				.catch(() => {
					this.$message({
					type: 'info',
					message: '已取消删除'
					})
				})
			},			
			goView(id){
				this.$router.push({path:'/view',query:{projectId:id}});
			},
			goEdit(id){
				this.$router.push({path:'/edit',query:{projectId:id}});
			},
			getProjectList(){
				var _this = this;
				var params={page:1,pageSize:100}
				getProjectListData(params).then(res => {
					if(res.code!=200){
						Message.warning(res.msg);
						return;
					}
					if(res.data){
						_this.projectList=res.data.list;
						for(var i=0;i<this.projectList.length;i++){
							_this.projectList[i].listhover=false;
						}
					}
				});
			},			
			selectTemplate(item){
				this.selectedTemplateId=item.id;
				this.formData.image=item.image;
			},
			getTemplateList(){
				var _this = this;
				var params={page:1,pageSize:100}
				getTemplateListData(params).then(res => {
					if(res.code!=200){
						Message.warning(res.msg);
						return;
					}
					if(res.data){
						_this.templateList=res.data.list;
					}
				});
			},
			//退出登录
			loginOut(){
				Message.success("退出登录成功！");
				localStorage.setItem("HomeState","")
				localStorage.setItem("userInfor","");
				localStorage.setItem("token","");	
				router.push({path:'/login'});
			},
			hoverlist(index) {
				// 鼠标滑过时的逻辑
				console.log('鼠标滑过');
				this.projectList[index].listhover=true;
				this.$forceUpdate();
			},
			hoverlistOut(index) {
				// 鼠标滑出时的逻辑
				console.log('鼠标滑出')
				this.projectList[index].listhover=false;
				this.$forceUpdate();
			},
			preview() {
				alert("test")
			},
			opencteate() {
				this.isCreate = true
			},
			closecreate() {
				this.isCreate = false
			},
			model(){
				this.leftmove = "4%"
				this.modelclass = "modelclass modelclassSelected"
				this.modelclass1 = "modelclass1 modelclassSelect"
				this.modelclass2 = "modelclass1 modelclassSelect"
			},
			modelVip(){
				this.leftmove = document.documentElement.clientWidth*0.4277*0.1+56+"px"
				this.modelclass = "modelclass modelclassSelect"
				this.modelclass1 = "modelclass1 modelclassSelected"
				this.modelclass2 = "modelclass1 modelclassSelect"
			},
			modelDiy(){
				this.leftmove = document.documentElement.clientWidth*0.4277*0.16+112+"px"
				this.modelclass = "modelclass modelclassSelect"
				this.modelclass1 = "modelclass1 modelclassSelect"
				this.modelclass2 = "modelclass1 modelclassSelected"
			},
			screen () {
			  if (!screenfull.enabled) {
			    return false
			  }
			  screenfull.toggle()
			},
			createNow(){
				if(!this.formData.title){
					Message.warning("项目名称不能为空！");
					return;
				}
				if(this.selectedTemplateId==0){
					Message.warning("请选择右边的模板！");
					return;
				}			
				var _this = this;
				var data={
					name:_this.formData.title,
					templateId:parseInt(_this.selectedTemplateId),
					remark:"",
				}
				addProjectData(data).then(res => {
					if(res.code!=200){
						Message.warning(res.msg);
						return;
					}
					console.log("add_res===="+JSON.stringify(res));
					if(res.data){
						this.screen();
						//this.$router.push({path:'/'});
						this.$router.push({path:'/edit',query:{projectId:res.data.id}});
					}
				});
			}
		},
	}
</script>
<style scoped>
	.homeFrame {
		width: 100%;
		height: 100%;
		background: #181a1b;
		font-family: "Hei";
	}

	.header {
		width: 100%;
		height: 7vh;
		display: flex;
		justify-content: space-between;
		background: #121212;
	}

	.header .logoHead {
		display: flex;
		height: 70px;
		line-height: 70px;
	}

	.header .logo {
		width: 62px;
		margin: 0 0 0 25px
	}

	.header .logo img {
		width: 100%
	}

	.header .logoName {
		color: #e2e2e2;
		font-size: 12px;
		color: #e2e2e2;
		padding: 3px 0 0 12px
	}

	.header .menuFrame {
		display: flex;
		height: 70px;
		line-height: 70px;
		font-size: 16px;
		color: #a0a0a0;
	}

	.header .menu {
		padding: 0 25px;
	}

	.header .headline {
		width: 1px;
		height: 30px;
		background: #313131;
		margin: 20px 10px 0 10px
	}

	.header .photo {
		width: 35px;
		height: 35px;
		border-radius: 30px;
		background: #363f45;
		margin: 17px -10px 0 20px
	}

	.contentFrame {
		width: 100%;
		display: flex;
	}

	.contentLeft {
		width: 20%;
		max-width: 340px;
		padding: 0 0 0 25px;
		background: #121212;

	}

	.contentLeft .create {
		width: 90%;
		height: 60px;
		border-radius: 5px;
		background: linear-gradient(to left, #62b0f8, #2592f7);
		line-height: 60px;
		text-align: center;
		margin: 0 0 25px 0
	}

	.contentLeft .create button {
		background: none;
		border: none;
		font-size: 17px;
		color: #fff;
		font-family: 'Hei';

	}

	.contentLeft .leftMenu {
		width: 90%;
		height: 40px;
		line-height: 40px;
		font-size: 14px;
		line-height: 40px;
		border-radius: 4px;
		margin: 15px 0;
	}

	.contentLeft .myCase {
		width: 100%;
		height: 40px;
		background: url(../../assets/icon_1_0.png) no-repeat 15px center;
		background-size: 16px;
		padding: 2px 0 0 0px;
		color: #a0a0a0;
	}

	.contentLeft .myCase:hover {
		color: #fff;
		background: url(../../assets/icon_1.png) no-repeat 15px center #2b2d32 !important;
		background-size: 16px !important;
		border-radius: 4px;
	}

	.contentLeft .myDiy {
		width: 100%;
		height: 40px;
		background: url(../../assets/icon_2_0.png) no-repeat 15px center;
		background-size: 16px;
		padding: 2px 0 0 0px;
		color: #a0a0a0;
	}

	.contentLeft .myDiy:hover {
		color: #fff;
		background: url(../../assets/icon_2_1.png) no-repeat 15px center #2b2d32 !important;
		background-size: 16px !important;
		border-radius: 4px;
	}

	.contentLeft .myWaster {
		width: 100%;
		height: 40px;
		background: url(../../assets/icon_3_0.png) no-repeat 15px center;
		background-size: 16px;
		padding: 2px 0 0 0px;
		color: #a0a0a0;
	}

	.contentLeft .myWaster:hover {
		color: #fff;
		background: url(../../assets/icon_3_1.png) no-repeat 15px center #2b2d32 !important;
		background-size: 16px !important;
		border-radius: 4px;
	}

	.contentLeft .DataCenter {
		width: 100%;
		height: 40px;
		background: url(../../assets/icon_4.png) no-repeat 15px center;
		background-size: 16px;
		padding: 1px 0 0 0px;

	}

	.contentLeft .DataCenter button {
		background: none;
		border: none;
		color: #a0a0a0;
		font-family: 'Hei';
		width: 100%;
	}

	.contentLeft .textspan {
		padding-left: 43px;
	}

	.contentLeft .textspan1 {
		padding-left: 35px;
	}

	.contentLeft .textmore {
		padding-left: 45%;
		color: #fff
	}

	.contentLeft .leftline1 {
		width: 90%;
		height: 1px;
		background: #2a2a2a;
		margin: 25px 0
	}

	.contentLeft .leftline2 {
		width: 90%;
		height: 1px;
		background: #2a2a2a;
		margin: 15px 0
	}

	.textS {
		color: #fff !important;
	}

	.myCaseS {
		background: url(../../assets/icon_1.png) no-repeat 15px center #2b2d32 !important;
		background-size: 16px !important;
		border-radius: 4px;
	}

	.myDiyS {
		background: url(../../assets/icon_2_1.png) no-repeat 15px center #2b2d32 !important;
		background-size: 16px !important;
		border-radius: 4px;
	}

	.wasterS {
		background: url(../../assets/icon_3_1.png) no-repeat 15px center #2b2d32 !important;
		background-size: 16px !important;
		border-radius: 4px;
	}

	.contentRight {
		width: 80%;
		height: 93vh;
		background: #181a1b;
	}

	.contentRight .listPic {
		width: 100%;
		background: url(../../assets/logoblack.jpg) no-repeat #1f1e1f center center;
		background-size: 137px;
		border-top-left-radius: 6px;
		border-top-right-radius: 6px;
		height: calc(100vw*0.8*0.97*0.23*0.5625);
	}

	.contentRight .homeAd {
		width: 95%;
		margin: 4vh auto 0 auto;
	}

	.contentRight .listFrame {
		width: 97%;
		justify-content: left;
		display: flex;
		flex-wrap: wrap;
		margin: 3vh auto;
	}

	.contentRight .list {
		width: 23%;
		text-align: center;
		margin: 0 1% 30px 1%;
		position: relative;
	}

	.contentRight .listversionName {
		position: absolute;
		top: 15px;
		right: 15px
	}


	.contentRight .listInfo {
		width: 100%;
		height: 50px;
		background: #27282c;
		border-bottom-left-radius: 6px;
		border-bottom-right-radius: 6px;
		display: flex;
		justify-content: space-between;
		line-height: 50px;

	}

	.contentRight .listname {
		color: #fff;
		font-size: 14px;
		margin: 0 0 0 5%
	}

	.contentRight .listVersion {
		display: flex;
		margin: 0 5% 0 0
	}

	.contentRight .listico1 {
		width: 4px;
		height: 4px;
		background: #01fe36;
		border-radius: 50%;
		margin: 22px 10px 0 0
	}
	.contentRight .listico2 {
		width: 4px;
		height: 4px;
		background: #ffa800;
		border-radius: 50%;
		margin: 22px 10px 0 0
	}

	.contentRight .versionname {
		font-size: 13px;
		color: #b3b3b3
	}

	.contentRight .listhover {
		width: 100%;
		height: 100%;
		background: rgba(0, 0, 0, .6);
		position: absolute;
		top: 0;
		left: 0;
		border-radius: 5px;
		border: 1px solid #3783f4;
		box-shadow: 0 0 15px #1963ea;
		backdrop-filter: blur(2px);
		animation: listhover 500ms linear 1;
		animation-fill-mode: forwards;
		opacity: 0;
	}

	@keyframes listhover {
		from {
			opacity: 0;
		}

		to {
			opacity: 1;
		}
	}

	.contentRight .listmenuIcon {
		display: flex;
		width: 65%;
		justify-content: space-between;
		margin: 20% auto 0 auto;
	}

	.contentRight .listmenuIcon img {
		width: 18px;
		cursor: pointer;
	}

	.contentRight .listManage {
		display: flex;
		margin: 20px auto 0 auto;
		width: 170px;
		justify-content: space-between;
	}

	.contentRight .listpreview {
		width: 80px;
		height: 37px;
		background: #28292a;
		border-radius: 8px;
		text-align: center;
		line-height: 37px;
		color: #fff;
		font-family: 'Hei';
		font-size: 14px;
	}

	.contentRight .listEdit {
		width: 80px;
		height: 37px;
		background: #3b89ff;
		border-radius: 8px;
		text-align: center;
		line-height: 37px;
		color: #fff;
		font-size: 14px;
	}

	.contentRight .listnameedit {
		width: 95%;
		position: absolute;
		bottom: 17px;
		left: 5%
	}

	.contentRight .listnameedit input {
		background: none;
		border: none;
		color: #fff;
		width: 100%;
		font-size: 14px;
		outline: none;
	}

	.about {
		color: #a0a0a0;
		font-size: 14px;
		font-weight: 400;
		font-family: 黑体;
		margin: 26vh 0 0 0;
	}

	.code {
		color: #a0a0a0;
		font-size: 14px;
		font-weight: 400;
		font-family: 黑体;
		margin: 1vh 0 0 0;
	}
	.createWin{
		position: absolute;width:65%;height:66vh;background: #181919;top:17vh;left:17.5%;box-shadow: 0 0 25px #000;transition: all 1s ease;animation: win 400ms linear 1;opacity: 0;animation-fill-mode: forwards;
	}
	@keyframes win {
		from{
			opacity: 0;
		}
		to{
			opacity: 1;
		}
	}
	.createWin .createNew{
		width: 100%;height:5vh;background: #121314;line-height: 5vh;color: #a8a8a8;font-size: 16px;display: flex;justify-content: space-between;
	}
	.createWin .createNewName{
		padding-left: 40px
	}
	.createWin .createClose{
		width: 20px;padding-right: 40px;
	}
	.createWin .createFrame{
		display: flex;width: 100%;height: 60vh
	}
	.createWin .createLeft{
		width: 30%;height:60vh;border-right:1px solid #1f2121
	}
	.createWin .createDemo{
		width:90%;height: calc(100vw*0.65*0.3*0.9*0.5625);
		/* background:url(../../assets/createbg.png) no-repeat center center #242627;
		margin:2vh 0 0 5%;border-radius:5px;background-size:30% */
		display: flex;
		align-items:center;
	}
	.createWin .createDemo img{
		display:block;
		width:auto;
		height:90%;
		margin:0 auto;
	}
	.createWin .demoTitle{
		color: #b8b8b8;font-size:14px;margin:2vh 0 0 5%
	}
	.createWin .demoSubtitle{
		color: #6b6c6d;margin:1vh 0 0 5%;width:90%;font-size: 13px;
	}
	.createWin .demoLine{
		width:100%;height:1px;background: #1f2121;margin:3vh 0
	}
	.createWin .caseName{
		color: #a8a8a8;font-size: 14px;margin:0 0 0 5%
	}
	.createWin .caseTitleInput{
		width: 86%;margin:1vh 0 0 5%;
	}
	.createWin .caseTitleInput input{
		width:100%;height:40px;background: #1e1f23;border:1px solid #323534;border-radius:3px;outline: none;color:#a8a8a8;padding-left: 1em;
	}
	.createWin .createbutton{
		display:flex;margin:3vh 0 0 5%;
	}
	.createWin .createNewTitle{
		background: #0a80fa;border:none;color:#fff;font-size: 14px;text-align: center;padding:10px 5%;border-radius: 3px;
	}
	.createWin .createover{
		background: #292a2e;border:2px solid #323534;color:#fff;font-size: 14px;text-align: center;padding:10px 5%;border-radius: 3px;margin:0 0 0 15px
	}
	.createWin .createRight{
		width: 70%;
	}
	.createWin .creatModelMenu{
		width: 100%;height:5.5vh;border-bottom: 1px solid #1f2121;display: flex;line-height: 5.5vh;position: relative;
	}
	.createWin .modelclass{
		font-size: 14px;margin:0  0 0 4%;
	}
	.createWin .modelclass1{
		font-size: 14px;margin:0  0 0 5.5%;
	}
	.createWin .modelclassSelected{
		color:#eee;
	}
	.createWin .modelclassSelect{
		color:#737373;
	}
	.createWin .modelLine{
		width: 56px;height:2px;background: #0a80fa;position: absolute;bottom:0;transition: all 1s ease;
	}
	.createWin .modelContent{
		width: 94%;display: flex;flex-wrap: wrap;justify-content: left;margin:3vh 0 0 4%
	}
	.createWin .model{
		width:30%;
		height:calc(100vw*0.65*0.7*0.3*0.94*0.5625 + 4vh + 2px);
		margin-right: 3%;
		margin-bottom: 20px;
		cursor: pointer;
	}
	.createWin .model:hover{
		width:calc(30% - 2px);
		height:calc(100vw*0.65*0.7*0.3*0.94*0.5625 + 4vh);
		border: 1px solid #3783f4;
		box-shadow: 0 0 15px #1963ea;
		border-radius: 5px;
	}
	.createWin .model_cur{
		width:calc(30% - 2px);
		height:calc(100vw*0.65*0.7*0.3*0.94*0.5625 + 4vh);
		border: 1px solid #3783f4;
		box-shadow: 0 0 15px #1963ea;
		border-radius: 5px;
		margin-right: 3%;
		cursor: pointer;
	}	
	.createWin .modelPic{
		width: 100%;
		background: url(../../assets/logoblack.jpg) no-repeat #1f1e1f center center;
		background-size: 137px;
		border-top-left-radius: 6px;
		border-top-right-radius: 6px;
		height: calc(100vw*0.65*0.7*0.3*0.94*0.5625);
	}
	.createWin .modelPic img{
		border-top-left-radius: 6px;
		border-top-right-radius: 6px;
	}
	.createWin .modelTitle{
		width: 100%;height:4vh;background:#27292c;border-bottom-left-radius: 6px;
		border-bottom-right-radius: 6px;display: flex;justify-content: space-between;line-height: 4vh;
	}
	.createWin .modelTitle div{
		color: #ecebec;font-size: 12px;padding-left: 15px;
	}
	.menuFrame div{transition: 0.5s;}
	.menuFrame div:hover{font-weight:bold;cursor:pointer;}
</style>
