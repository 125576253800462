<template>
    <div class="c1">
        <div class="c1-item" v-for="item in mapList" :key="item.name">
            <img :src="item.img" alt="">
            <div class="text">{{ item.name }}</div>
            <div class="val">{{ item.val }}</div>
        </div>
    </div>
</template>

<script>
import bg1 from '@/assets/template_1/c1-bg1.png'
import bg2 from '@/assets/template_1/c1-bg2.png'
import bg3 from '@/assets/template_1/c1-bg3.png'
import { 
    getComponentDatas,
} from "@/api/index";

export default {
    props: {
        dataList: {
            type: Array,
            default() {
				return defaultValue;
			},
        },
    },     
    data() {
        return {
            mapList: [
                // { name: '满意', img: bg1, val: '000' },
                // { name: '一般', img: bg2, val: '000' },
                // { name: '不满意', img: bg3, val: '000' },
            ],
            defaultValue: [
                {
                    "value0": "正常",
                    "value1": 0
                },
                {
                    "value0": "预警",
                    "value1": 0
                },
                {
                    "value0": "故障",
                    "value1": 0
                }
            ],
        }
    },
    watch: {
        dataList(newValue, oldValue) {
            if(newValue.length==0){
                this.dataList=this.defaultValue; 
                this.getDataList();
            }     
            if(this.dataList!=this.defaultValue){
                this.getDataList()
            }
        },
    },    
    mounted() {
        if(this.dataList.length==0){
            this.dataList=this.defaultValue;
        }
        this.getDataList();
    },
    methods: {
        getDataList(){
            var tempList=this.dataList;
            if(tempList.length>0){
                this.mapList=[];
            }
            for(var i=0;i<tempList.length;i++){
                var icons=bg1;
                if(tempList[i].value0=="不满意"||tempList[i].value0=="故障"){
                    icons=bg3;
                }else if(tempList[i].value0=="一般"||tempList[i].value0=="预警"){
                    icons=bg2;
                }
                this.mapList.push({ name: tempList[i].value0, img: icons, val: tempList[i].value1 });
            }
        },
    }
}
</script>

<style scoped lang="scss">
.c1 {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 80px;
}
.c1-item {
    position: relative;
    width: 170px;
    height: 53px;
    display: flex;
    align-items: center;
    img {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
    }
    .text {
        font-family: 'pmzd';
        font-weight: 400;
        font-size: 18px;
        color: #FFFFFF;
        line-height: 21px;
        font-style: italic;
        margin-left: 26px;
        z-index: 1;
    }
    .val {
        margin-left: auto;
        font-family: 'pmzd';
        font-weight: 400;
        font-size: 24px;
        color: #FFFFFF;
        line-height: 21px;
        z-index: 1;
    }
}
</style>