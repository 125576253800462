<template>
    <div class="leg" :style="{bottom: bottomNum + 'px'}">
        <div :class="`leg-item index${i}`" v-for="(item, i) in legendList" :key="i">
            <img :src="item.img" alt="">
            <div class="text">{{ item.name }}</div>
        </div>
    </div>
</template>

<script>
import icon1 from '@/assets/template_1/legend-poi1.png'
import icon2 from '@/assets/template_1/legend-poi2.png'
import icon3 from '@/assets/template_1/legend-poi3.png'

export default {
    props: {
        bottomNum: {
            type: [String, Number],
            default: '540'
        },
        legList: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            legendList: [
                { name: '正常', img: icon1 },
                { name: '预警', img: icon2 },
                { name: '故障', img: icon3 },
            ]
        }
    },
    mounted() {
        if (this.legList && this.legList.length) {
            this.legendList  =  this.legList
        }
    }
}
</script>

<style scoped lang="scss">
.leg {
    width: calc(100% - 72px);
    margin: 0 36px;
    position: absolute;
    overflow: hidden;
    display: flex;
    .leg-item {
        width: 110px;
        height: 38.5px;
        display: flex;
        align-items: center;
        &.index0 {
            background: url(@/assets/template_1/legend1.png) no-repeat;
            background-size: 100% 100%;
        }
        &.index1 {
            background: url(@/assets/template_1/legend2.png) no-repeat;
            background-size: 100% 100%;
        }
        &.index2 {
            background: url(@/assets/template_1/legend3.png) no-repeat;
            background-size: 100% 100%;
        }
        &.index3 {
            background: url(@/assets/template_1/legend4.png) no-repeat;
            background-size: 100% 100%;
        }
        img {
            width: 20px;
            height: 24.5px;
            margin: 6px 7px 0 16px;
        }
        .text {
            font-family: Source Han Sans CN;
            font-weight: 400;
            font-size: 14px;
            color: #FFFFFF;
            line-height: 24.5px;
        }
    }
}
</style>
