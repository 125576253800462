<template>
	<div id="main" :style="{zoom:zoomAuto, height:Hframe}">  
			<div class="login_div" style="ovrerflow:hidden;">
				<div class="bdy">
					<div class="big_title">
						可视化平台
					</div>
					<div class="t_tit2">
						欢迎登录
					</div><!--/t_tit2-->
					<div class="single">
						<img src="~@/assets/mobile.png">
						<input v-model="loginForm.account" type="text" placeholder="请输入账号">
					</div><!--/single-->
					<div class="single">
						<img src="~@/assets/Lock.png">
						<input v-model="loginForm.password" type="password" placeholder="请输入密码">
					</div><!--/single-->
					<div @click="user_login" class="btns">
						登录
					</div><!--/btns-->	
				</div><!--/bdy-->	  		  
			</div>
			<div class="r_earth">
			</div><!--/r_earth-->
	</div>
</template>

<script>
	import { userLogin} from "@/api/index";
	import { Message } from "element-ui";
	import router from '@/router';
	export default {
		data() {
			return {
				loginForm:{
					username:"",
					password:"",
				},
				siteTitle:'',
			}
		},
		computed: {
			zoomAuto: function() {
				return document.documentElement.clientWidth / 1920; //自定义高度需求
			},
			Hframe: function() {
				console.log(window.screen.height);
				return  window.screen.height / (document.documentElement.clientWidth / 1920) + "px"; //自定义高度需求
			}
		},
		components: {
		},
		mounted() {
			this.getConfig();
		},
		methods: {
			goOtherPage(){
				window.location.href='http://121.4.173.197:8232/gc/#/';
			},
			user_login(){
				// if(localStorage.getItem("token")){
				// 	Message.success("登录成功！");
				// 	router.push({name:'index'});
				// 	return;
				// }				
				if(!this.loginForm.account){
					Message.warning("请输入账号！");
					return;
				}
				if(!this.loginForm.password){
					Message.warning("请输入密码！");
					return;
				}	
				var data={
					username:this.loginForm.account,
					password:this.loginForm.password
				};
				var _this = this;
				userLogin(data).then(res => {
					if(res.code!=200){
						Message.warning(res.msg);
						return;
					}
					if(res.data){
						var user_infor = res.data.userInfo;
						Message.success("登录成功！");
						localStorage.setItem("HomeState","0")
						localStorage.setItem("userInfor",JSON.stringify(user_infor));
						localStorage.setItem("token",user_infor.token);		
						router.push({path:'/'});
					}
					
				});
			},
		},
		beforeDestroy() {

		},

	}
</script>

<style scoped>
#main{width:1920px;height:1080px;background:url(../../assets/login_bg.jpg) top center no-repeat;background-size:100%;}
.main_bg{width:1920px;height:1080px;}
.login_div{
	float:left;
	width:620px;
	height:762px;
	padding-left:30px;
	margin:7% 0 0 5%;
	background:url(../../assets/login_bg.png) top center no-repeat;
	background-size:100%;
}
.login_div .bdy{width:80%;margin:50px auto;overflow:hidden;}
.login_div .bdy .t_tit{overflow:hidden;font-size:30px;color:#0edde7;text-align:center;letter-spacing: 5px;margin-top:50px;}
.login_div .bdy .t_tit2{overflow:hidden;font-size:24px;color:#fff;text-align:center;letter-spacing: 2px;margin-top:40px;margin-bottom:40px;}
.login_div .bdy .single{overflow:hidden;width:65%;margin:0 auto;height:50px;border:solid 1px #466f98;background:#182f3f;margin-bottom:25px;}
.login_div .bdy .single img{display:block;height:20px;margin:15px 0 0 5%;float:left;}
.login_div .bdy .single input{float:left;margin:0 0 0 5%;height:50px;background:none;width:70%;border:none;padding:0;outline:none;color:#fff;font-size:16px;}
.login_div .bdy .single input::-webkit-input-placeholder {color: #fff;}
.login_div .bdy .btns{width:65%;height:50px;line-height:50px;color:#fff;font-size:16px;text-align:center;margin:40px auto;overflow:hidden;background:#127be6;border-radius:5px;cursor:pointer;}
.r_earth{float:right;width:900px;margin:40px 140px 0 0;}
.big_title{font-family:ziticqtezhanti;font-size:42px;color:#fafefd;line-height:50px;letter-spacing:1px;background: linear-gradient(0deg, #BDE0FF 0%, #FEFFFF 99.5849609375%);
-webkit-background-clip: text;-webkit-text-fill-color: transparent;text-align:center;}
.tab_ts{overflow:hidden;text-align:center;margin-bottom:30px;}
.tab_ts a{cursor:pointer;color:#8d9aa1;text-align:center;line-height:42px;font-size:16px;margin-right:5px;width:142px;height:62px;display: inline-block;background:url(../../assets/login_tab_t_bg.png) top center no-repeat;}
.tab_ts a:hover,.tab_ts a.cur{color:#fff;background:url(../../assets/login_tab_t_bg_hover.png) top center no-repeat;}
</style>
