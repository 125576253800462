<template>
    <div class="r3">
        <div class="contents">
            <div class="r3-item" v-for="item in dataList" :key="item">
                <img :src="del" alt="" v-for="(del, i) in iconlist" :key="i" :class="`icon${i}`">
                <img :src="item.value0" alt="" class="hover-img">
            </div>
        </div>
    </div>
</template>

<script>
import icon1 from '@/assets/template_1/corner1.png'
import icon2 from '@/assets/template_1/corner2.png'
import icon3 from '@/assets/template_1/corner3.png'
import icon4 from '@/assets/template_1/corner4.png'
import demo1 from '@/assets/template_1/demo1.jpg'
import demo2 from '@/assets/template_1/demo2.jpg'
import demo3 from '@/assets/template_1/demo3.jpg'
import demo4 from '@/assets/template_1/demo4.jpg'

export default {
    props: {
        dataList: {
            type: Array,
            default() {
				return [];
			},
        },
    },    
    components: {
    },
    data() {
        return {
            iconlist: [
                icon1, icon2, icon3, icon4
            ],
            defaultValue:[
                {value0:demo1},
                {value0:demo2},
                {value0:demo3},
                {value0:demo4},
            ],
        }
    },
    watch: {
        dataList(newValue, oldValue) {
            if(newValue.length==0){
                this.dataList=this.defaultValue; 
            }         
        }
    },
    mounted() {
        if(this.dataList.length==0){
            this.dataList=this.defaultValue; 
        }
    },	    
}
</script>

<style scoped lang="scss">
.r3 {
    .contents {
        display: flex;
        flex-wrap: wrap;
        padding-left: 12px;
        width: calc(100% - 12px);
    }
    .r3-item {
        position: relative;
        width: 202px;
        height: 117px;
        background: #000000;
        margin: 12px 12px 0 0;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
            width: 22px;
            height: 22px;
        }
        .hover-img {
            width: 189px;
            height: 104px;
            display:block;
        }
        .icon0 {
            position: absolute;
            left: -7px;
            top: -7px;
            animation: flicker 1s infinite;
            animation-direction: alternate;
            z-index:1;
        }
        .icon1 {
            position: absolute;
            right: -7px;
            top: -7px;
            animation: flicker 1s infinite;
            animation-direction: alternate;
            z-index:1;
        }
        .icon2 {
            position: absolute;
            left: -7px;
            bottom: -7px;
            animation: flicker 1s infinite;
            animation-direction: alternate;
            z-index:1;
        }
        .icon3 {
            position: absolute;
            right: -7px;
            bottom: -7px;
            animation: flicker 1s infinite;
            animation-direction: alternate;
            z-index:1;
        }
    }
    @keyframes flicker {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
}
</style>