import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import * as echarts from 'echarts'
// 将自动注册所有组件为全局组件
import dataV from '@jiaminghi/data-view'
import './css/index.css'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import scroll from 'vue-seamless-scroll'
Vue.use(scroll)
  

Vue.use(dataV)
Vue.use(ElementUI)
Vue.prototype.$echarts = echarts
Vue.config.productionTip = false

new Vue({
  router,
  el: '#app',
  store,
  render: h => h(App)
}).$mount('#app')
