<template>
	<div id="app" >
		<router-view />
	</div>
</template>

<script>
	export default {
		name: 'App',
		components: {

		},
		data() {
			return {
				
			}
		},
		
	};
</script>
<style scoped>
	body {
		overflow: hidden;
		
	}

	#app {
		-webkit-font-smoothing: "antialiased";
		-moz-osx-font-smoothing: "grayscale";
		/* text-align: center; */
		color: #fff;
		height: 100vh;
		width:100%;
		background: #131313;
	}

	@font-face {
		font-family: "Hei";
		src: url("./assets/SourceHanSansCN-Medium.ttf");
	}
	@font-face {
		font-family: "pmzd";
		src: url("./assets/font/pmzd.ttf");
	}	
	::-webkit-scrollbar {
		width: 8px;
		height: 16px;
		background-color: transparent;
	}

	/*定义滚动条轨道内阴影+圆角*/

	::-webkit-scrollbar-track {
		-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
		border-radius: 10px;
		background-color: rgba(2, 28, 51, 0.7);
	}

	/*定义滑块内阴影+圆角*/

	::-webkit-scrollbar-thumb {
		border-radius: 10px;
		-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
		background-image: linear-gradient(#3de0cf, #3de0cf);
	}
</style>