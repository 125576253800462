<template>
    <div class="l2">
        <div class="l2-content">
            <div class="list-head">
                <div class="list-icon"></div>
                <div style="width:50px;" class="text">排行</div>
                <div :style="'width:'+singleWidth+'px'" v-for="item in columnList" class="text">{{item.name}}</div>
            </div>
            <div class="list-content">
                <div v-if="i<6" class="list-item" v-for="(item, i) in dataList" :key="i">
                    <div class="list-icon">
                        <img src="@/assets/template_1/list-icon.png" alt="">
                    </div>
                    <div style="width:50px;" class="text" v-if="rankMap[i]">
                       <img :src="rankMap[i]" alt="">
                    </div>
                    <div style="width:50px;" class="text" v-else>
                        {{ i + 1 }}
                    </div>
                    <div :title="item.value0" :style="'width:'+singleWidth+'px'" v-if="columnList.length>0" class="text">{{ checkWordLen(item.value0) }}</div>
                    <div :title="item.value1" :style="'width:'+singleWidth+'px'" v-if="columnList.length>1" class="text">{{ checkWordLen(item.value1) }}</div>
                    <div :title="item.value2" :style="'width:'+singleWidth+'px'" v-if="columnList.length>2" class="text">{{ checkWordLen(item.value2) }}</div>
                    <div :title="item.value3" :style="'width:'+singleWidth+'px'" v-if="columnList.length>3" class="text">{{ checkWordLen(item.value3) }}</div>
                    <div :title="item.value4" :style="'width:'+singleWidth+'px'" v-if="columnList.length>4" class="text">{{ checkWordLen(item.value4) }}</div>
                </div>
            </div>
            <div @click="toMore" class="tomore">查看更多 ></div>
        </div>
    </div>
</template>

<script>
import rank1 from '@/assets/template_1/rank1.png'
import rank2 from '@/assets/template_1/rank2.png'
import rank3 from '@/assets/template_1/rank3.png'

export default {
    props: {
        dataList: {
            type: Array,
            default() {
				return [];
			},
        },
        names: {
            type: String,
            default() {
				return "";
			},
        },
        effectedComponent: {
            type: String,
            default: ''
        },         
    },       
    data() {
        return {
            rank1,
            rank2,
            rank3,
            rankMap: {
                0: rank1,
                1: rank2,
                2: rank3,
            },
            title: '用量排名',
            defaultValue: [
                { value0: 'GR123', value1: '009',value2: '001', },
                { value0: 'HG234', value1: '008',value2: '004', },
                { value0: 'GR123', value1: '009',value2: '007', },
                { value0: 'HG234', value1: '008',value2: '003', },
                { value0: 'GR123', value1: '009',value2: '008', },
            ],
            defaultValue2: [
                { name:"机器编号" },
                { name:"机器型号" },
                { name:"用量数值" },
            ],
            columnList:[],
            totalWidth:269,//总宽度
            singleWidth:103,//单个宽度
        }
    },
    watch: {
        dataList(newValue, oldValue) {
            if(newValue.length==0&&!this.names){
                this.dataList=this.defaultValue; 
            }     
            if(this.dataList!=this.defaultValue){
  
            }
        },
        names(newValue, oldValue) {
            if(!newValue){
                this.columnList=this.defaultValue2; 
            }     
            if(newValue!=oldValue){
                //alert(this.names);
                this.getColumnList(newValue);
            }
        }        
    }, 
    mounted() {
        if(this.dataList.length==0&&!this.names){
            this.dataList=this.defaultValue;
        } 
        if(!this.names){
            this.columnList=this.defaultValue2;
        }      
        else{
            this.getColumnList(this.names);
        } 
    }, 
    methods: {
        toMore(){
            var selectedData=this.effectedComponent;
            this.$emit('SearchListDatas', selectedData)
        },        
        checkWordLen(str){
            var len=8;
            if(str.length>len){
                str=str.substr(0,len)+"...";
            }
            return str;
        },        
        getColumnList(values){
            var tempList=values.split(',');
            if(tempList.length>0){
                this.columnList=[];
            }
            for(var i=0;i<tempList.length;i++){
                if(tempList[i]){
                    this.columnList.push({name:tempList[i]});
                }
            }
            this.singleWidth=this.totalWidth/(this.columnList.length-1);
        },
    },         

}
</script>

<style scoped lang="scss">
.l2 {
    padding-top:5px;
    margin-top:-2px;    
    min-height:257px;
    .text {
        font-family: Source Han Sans CN;
        font-weight: 400;
        font-size: 14px;
        line-height: 15px;
        text-align: center;
        img {
            width: 23px;
            height: 19px;
        }
    }
    .list-head {
        display: flex;
        align-items: center;
        margin: 16px 0;
        .text {
            color: #51A0F4;
        }
    }
    .list-item {
        display: flex;
        align-items: center;
        padding: 12px 0;
        background: rgba(59, 120, 177, .05);
        .text {
            color: #C6D2D8;
        }
        &:nth-child(2n + 1) {
            background: rgba(59, 120, 177, .16);
        }
    }
    .list-icon {
        width: 52px;
        height: 13px;
        position: relative;
        img {
            width: 17px;
            height: 13px;
            position: absolute;
            right: 15%;
        }
    }
}
.tomore {
    width: 100%;
    text-align: center;
    margin: 16px 0;
    font-family: Source Han Sans CN;
    font-weight: 400;
    font-size: 14px;
    color: #51A0F4;
    line-height: 15px;
    cursor: pointer;
}
</style>